import React, {useState} from "react";

import {useEntityObserver} from "src/features/entity/entity-hooks";
import {ResponsiveTimeRange, useColorScale} from "src/packages/extended-nivo-calendar";
import dateFormat from "dateformat";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLLieferungGetter, getGEMAGVLXMLLieferungStatsGetter} from "src/features/entity";
import {addDays, addMonths} from "date-fns";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton, ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import {formatReadableDuration, parseDate} from "src/packages/date-utils";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import SimpleDuration from "src/components/entities/gemagvlxml/SimpleDuration";
import {BoxLegendSvg} from "@nivo/legends";
import {Container, SvgWrapper} from "@nivo/core";
import {navigate} from "gatsby";
import {SkeletonList} from "src/components/welcome/components";

export function useCategoryColorScaleFunction() {
  const tooMuchScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 1,
    colors: [
      "#00aa00",
      "#009100",
      "#006100",
    ],
  });

  const wayTooMuchScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 1,
    colors: [
      "#ff0000",
      "#cc0000",
    ],
  });

  const lessThanRequiredScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 1,
    colors: [
      "#f8f6cc",
      "#fcf682",
      "#fff500",
    ],
  });

  const lessThanTypicalScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 1,
    colors: [
      "#dcfdae",
      "#d6fba7",
      "#d0f99f",
      // "#eef6ff",
      // "#e1f0ff",
      // "#cce2ff",
    ],
  });

  const plausibleColorScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 1,
    colors: [
      "#dcfdae",
      "#d6fba7",
      "#d0f99f",
      "#caf798",
      "#c4f591",
      "#bef48a",
      "#b7f283",
      "#b1f07c",
      "#aaee76",
      "#a2ec6f",
      "#9bea68",
      "#93e861",
      "#8be65a",
      "#83e553",
      "#7ae34c",
      "#70e144",
      "#66df3c",
      "#5bdd34",
      "#4edb2c",
      "#3fd921",
      "#2bd715",
      "#00d500",
    ],
  });

  const emptyColorScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: 0,
    colors: [
      "#eeeeee",
    ],
  });

  const categoryToScaleFunction = {
    empty: emptyColorScaleFn,
    lessThanRequired: lessThanRequiredScaleFn,
    lessThanTypical: lessThanTypicalScaleFn,
    wayTooMuch: wayTooMuchScaleFn,
    tooMuch: tooMuchScaleFn,
    plausible: plausibleColorScaleFn,
  };

  return ({category, relativeIntervalPosition}) => categoryToScaleFunction[category](relativeIntervalPosition);
}

const analyzeCoverage = ({value, requiredMusikDauer, typicalMusikDauer, dayLength, gemaMaxDayLength}) => {
  let relativeIntervalPosition, priority, category;

  if (value <= 0) {
    priority = 3;
    category = 'empty';
    relativeIntervalPosition = 0;
  } else if (value < requiredMusikDauer) {
    priority = 4;
    category = 'lessThanRequired';
    relativeIntervalPosition = value / requiredMusikDauer;
  } else if (value < typicalMusikDauer) {
    // Note that it holds typicalMusikDauer > requiredMusikDauer at this point.
    priority = 2;
    category = 'lessThanTypical';
    relativeIntervalPosition = (value - requiredMusikDauer) / (typicalMusikDauer - requiredMusikDauer);
  } else if (value > gemaMaxDayLength) {
    priority = 5;
    category = 'wayTooMuch';
    relativeIntervalPosition = (value - gemaMaxDayLength) / 3600;
  } else if (value > dayLength) {
    // Note that it holds gemaMaxDayLength > dayLength at this point.
    priority = 1;
    category = 'tooMuch';
    relativeIntervalPosition = (value - dayLength) / (gemaMaxDayLength - dayLength);
  } else {
    priority = 0;
    category = 'plausible';
    relativeIntervalPosition = (value - typicalMusikDauer) / (gemaMaxDayLength - typicalMusikDauer);

    if (typicalMusikDauer > requiredMusikDauer) {
      relativeIntervalPosition = relativeIntervalPosition / 2 + 0.5;
    }
  }

  return {
    value,
    requiredMusikDauer,
    typicalMusikDauer,
    priority,
    category,
    relativeIntervalPosition,
  };
};

export function useProcessedCoverageData({coverageDict, stationsCount=1}) {
  const gemaMaxDayLength = 25 * 3600 * stationsCount;
  return Object.entries(coverageDict || {}).map(([day, data]) => ({
    day,
    value: {
      standardCoverageStats: analyzeCoverage({
        value: data?.standard_musik_dauer,
        requiredMusikDauer: data?.required_standard_musik_dauer,
        typicalMusikDauer: data?.typical_standard_musik_dauer,
        dayLength: data?.interval_length * stationsCount,
        gemaMaxDayLength: data?.interval_length <= 90000 ? gemaMaxDayLength : data?.interval_length / 86400 * gemaMaxDayLength,
      }),
      jingleCoverageStats: analyzeCoverage({
        value: data?.jingle_musik_dauer,
        requiredMusikDauer: data?.required_jingle_musik_dauer,
        typicalMusikDauer: data?.typical_jingle_musik_dauer,
        dayLength: data?.interval_length * stationsCount,
        gemaMaxDayLength: data?.interval_length <= 90000 ? gemaMaxDayLength : data?.interval_length / 86400 * gemaMaxDayLength,
      }),
      // value: data,
      intervalLength: data?.interval_length,
      isHoliday: data?.is_holiday,
      byHour: Object.fromEntries(
        Object.entries(data?.by_hour || {}).map(([hour, hourDataList]) =>
          [
            hour,
            hourDataList?.map(({standard_musik_dauer, jingle_musik_dauer, ...props}) => ({
              ...props,
              intervalLength: 3600,
              isHoliday: data?.is_holiday,
              standardCoverageStats: analyzeCoverage({
                value: standard_musik_dauer,
                requiredMusikDauer: data?.required_standard_musik_dauer / data?.interval_length * 3600,
                typicalMusikDauer: data?.typical_standard_musik_dauer / data?.interval_length * 3600,
                dayLength: 3600 * stationsCount,
                gemaMaxDayLength: gemaMaxDayLength / data?.interval_length * 3600,
              }),
              jingleCoverageStats: analyzeCoverage({
                value: jingle_musik_dauer,
                requiredMusikDauer: data?.required_jingle_musik_dauer / data?.interval_length * 3600,
                typicalMusikDauer: data?.typical_jingle_musik_dauer / data?.interval_length * 3600,
                dayLength: 3600 * stationsCount,
                gemaMaxDayLength: gemaMaxDayLength / data?.interval_length * 3600,
              }),
            })),
          ]
        )
      ),
    },
  }));
}

function StatsListItem(
  {
    enabled,
    selected,
    value,
    primary,
    secondary,
    onClick,
  }
) {
  return (
    <ListItem
      disabled={!enabled}
      selected={selected}
      button={!!(enabled && onClick)}
      onClick={onClick}
    >
      <ListItemAvatar>
        <CircularProgressWithLabel
          variant="determinate"
          value={value}
          showZero
        />
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}

function StatsOverview(
  {
    stats,
    standardSelected,
    jingleSelected,
    onStandardClick,
    onJingleClick,
    isLoading,
  }
) {
  const formattedStandardDuration = formatReadableDuration(stats?.coverage?.standard_musik_dauer);
  const formattedJingleDuration = formatReadableDuration(stats?.coverage?.jingle_musik_dauer);

  const onClickMakesSense = (
    standardSelected || jingleSelected || (
      !!stats?.coverage?.standard_musik_dauer && !!stats?.coverage?.jingle_musik_dauer
    )
  )

  return (
    <Box sx={{width: '100%', maxWidth: 260, pr: 4, backgroundColor: 'inherit'}}>
      {isLoading ? (<SkeletonList items={2} showSecondary/>) : (
        <List component="nav">
          <StatsListItem
            enabled={!!stats?.coverage?.standard_musik_dauer || standardSelected}
            selected={standardSelected}
            onClick={onClickMakesSense ? onStandardClick : undefined}
            value={stats?.coverage?.standard_musik_dauer_percentage || 0}
            primary="Musik"
            secondary={formattedStandardDuration}
          />
          <StatsListItem
            enabled={!!stats?.coverage?.jingle_musik_dauer || jingleSelected}
            selected={jingleSelected}
            onClick={onClickMakesSense ? onJingleClick : undefined}
            value={stats?.coverage?.jingle_musik_dauer_percentage || 0}
            primary="Jingles"
            secondary={formattedJingleDuration}
          />
        </List>
      )}
    </Box>
  );
}

export const HeatmapTooltipListItem = function({name, coverageStats, intervalLength}) {
  const categoryColorScaleFn = useCategoryColorScaleFunction();
  return (
    <ListItem>
      <ListItemAvatar>
        <CircularProgressWithLabel
          textColor="inherit"
          variant="determinate"
          value={coverageStats?.value / intervalLength}
          showZero
          sx={{color: categoryColorScaleFn(coverageStats)}}
        />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={(
          <span style={{color: 'white'}}>
          {coverageStats?.category === 'lessThanRequired' || coverageStats?.category === 'lessThanTypical' ? (
            'nur '
          ) : null}
          <SimpleDuration value={coverageStats?.value}/>
          {coverageStats?.category === 'lessThanRequired' ? (
            <>
              <br/>
              (benötigt: <SimpleDuration value={coverageStats?.requiredMusikDauer}/>)
            </>
          ) : coverageStats?.category === 'lessThanTypical' ? (
            <>
              <br/>
              (typisch: <SimpleDuration value={coverageStats?.typicalMusikDauer}/>)
            </>
          ) : null}
        </span>
        )}
      />
    </ListItem>
  );
}

export const HeatmapTooltipContent = React.memo((({date, dayValue, monthValue, hour, hourValues, showMusic, showJingles, stationsCount=1}) => {
  const isHoliday = dayValue?.isHoliday;
  const isDSTChange = dayValue?.intervalLength && dayValue?.intervalLength != 86400;

  const formattedDate = dayValue ? dateFormat(date, "dddd, d. mmmm yyyy") : monthValue ? dateFormat(date, "mmmm yyyy") : null;

  const value = dayValue || monthValue;

  return (
    <React.Fragment>
      {formattedDate}
      {isHoliday || isDSTChange ? (
        <>
          <br/>
          (
          {isHoliday ? "Feiertag" : null}
          {(isHoliday && isDSTChange) ? " · " : null}
          {isDSTChange ? "Zeitumstellung" : null}
          )
        </>
      ) : null}
      {hourValues ? (
        <>
          {hourValues.map((hourValue, i) => (
            <React.Fragment key={i}>
              <Typography sx={{mt: 2}}>
                {hour} Uhr bis {hour + 1} Uhr
                {hourValues?.length > 1 && (
                  <>
                    {' '}
                    {(i === 0) ? "(Sommerzeit)" : "(Winterzeit)"}
                  </>
                )}
              </Typography>
              <List>
                {showMusic ? (
                  <HeatmapTooltipListItem
                    name="Musik"
                    coverageStats={hourValue?.standardCoverageStats}
                    intervalLength={hourValue?.intervalLength * stationsCount}
                  />
                ) : null}
                {showJingles ? (
                  <HeatmapTooltipListItem
                    name="Jingles"
                    coverageStats={hourValue?.jingleCoverageStats}
                    intervalLength={hourValue?.intervalLength * stationsCount}
                  />
                ) : null}
              </List>
            </React.Fragment>
          ))}
        </>
      ) : (
        <List>
          {showMusic ? (
            <HeatmapTooltipListItem
              name="Musik"
              coverageStats={value?.standardCoverageStats}
              intervalLength={value?.intervalLength * stationsCount}
            />
          ) : null}
          {showJingles ? (
            <HeatmapTooltipListItem
              name="Jingles"
              coverageStats={value?.jingleCoverageStats}
              intervalLength={value?.intervalLength * stationsCount}
            />
          ) : null}
        </List>
      )}
    </React.Fragment>
  );
}));

const HeatmapTooltip = React.memo(((props) => {
  return (
    <div>
      <Tooltip
        title={(<HeatmapTooltipContent {...props}/>)}
        open
        disableInteractive
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <div/>
      </Tooltip>
    </div>
  );
}));

function useTimeRanges({datum_von, datum_bis, maxMonths}) {
  const timeRanges = [];

  if (datum_von !== datum_bis) {
    let startDate = parseDate(datum_von);
    let endDate = parseDate(datum_bis);

    // if (startDate.getDate() !== 1) {
    //   startDate.setDate(1);
    // }

    // if (endDate.getDate() !== 1 && addDays(endDate, 1).getDate() !== 1) {
    //   endDate = addDays(addMonths(endDate, 1).setDate(1), -1);
    // }

    let currentDate = startDate;
    while (currentDate <= endDate) {
      const nextMonth = addMonths(currentDate, 1).setDate(1);
      let lastCoveredDayOfMonth = addDays(nextMonth, -1);
      if (lastCoveredDayOfMonth > endDate) {
        lastCoveredDayOfMonth = endDate;
      }
      // timeRanges.push([currentDate, lastCoveredDayOfMonth]);
      // if (lastCoveredDayOfMonth.getDay() - currentDate.getDate() + 1 < 0) {
      //   console.log(lastCoveredDayOfMonth.getDay() - currentDate.getDate() + 1);
      //   console.log({currentDate, lastCoveredDayOfMonth});
      // }
      timeRanges.push(
        [
          ...Array(lastCoveredDayOfMonth.getDate() - currentDate.getDate() + 1)
        ].map((elem, i) => addDays(currentDate, i))
      );
      currentDate = addDays(lastCoveredDayOfMonth, 1);

      if (maxMonths && timeRanges.length >= maxMonths) {
        break;
      }
    }
  } else if (datum_von && datum_bis) {
    // const startDate = parseDate(datum_von).setDate(1);
    // const endDate = addDays(addMonths(startDate, 1).setDate(1), -1);
    const startDate = parseDate(datum_von);
    timeRanges.push([startDate]);
  }

  return timeRanges;
}

function GEMAGVLXMLStatsLegend({isLoading, showHours, setShowHours}) {
  const legend = (
    <>
      <Container>
        <SvgWrapper width={200} height={240} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
          <BoxLegendSvg
            containerWidth={200}
            containerHeight={240}
            anchor='right'
            direction='column'
            justify={false}
            itemCount={1}
            itemWidth={42}
            itemHeight={36}
            itemsSpacing={14}
            itemDirection='right-to-left'
            itemTextColor='rgba(0, 0, 0, 0.6)'
            // translateX={-60}
            // translateY={-60}
            symbolSize={20}
            theme={{background: '#ffffff'}}
            data={[
              {id: 1, label: 'zu hohe Sendedauer', color: '#ff0000'},
              {id: 2, label: 'plausible Sendedauer', color: '#00d500'},
              {id: 3, label: 'geringe Sendedauer', color: '#fff500'},
              {id: 4, label: 'keine Ausstrahlungen', color: '#eeeeee'},
            ]}
          />
        </SvgWrapper>
      </Container>
    </>
  );

  return isLoading ? (<Skeleton>{legend}</Skeleton>) : legend;
}

function GEMAGVLXMLStatsMonthsOverview({coveredMonths, isLoading, data, colorScaleFn, tooltipFn, onDayClick}) {
  return (
    <>
      {coveredMonths.map((dates, i) => (
        <div key={i} style={{height: '260px', width: '240px', marginLeft: 10}}>
          <Typography align="center">
            {isLoading ? (
              <Skeleton variant="text" sx={{mx: 'auto'}}><span>{dateFormat(dates[0], "mmmm yyyy")}</span></Skeleton>
            ) : (
              dateFormat(dates[0], "mmmm yyyy")
            )}
          </Typography>
          <div style={{height: '240px'}}>
            {isLoading ? (
              <Skeleton width={230} height={55*7} sx={{transformOrigin: '0 0'}}/>
            ) : (
              <ResponsiveTimeRange
                data={data}
                from={dateFormat(addDays(addDays(dates[0], 0).setDate(1), -1), "yyyy-mm-dd")}
                to={dateFormat(addDays(addMonths(dates[0], 1).setDate(1), -1), "yyyy-mm-dd")}
                emptyColor="#f8f8f8"
                colorScale={colorScaleFn}
                dayRadius={20}
                daySpacing={5}
                margin={{top: 0, right: 0, bottom: 0, left: 0}}
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                weekdayTicks={i === 0 ? [0, 1, 2, 3, 4, 5, 6] : []}
                weekdayLegendOffset={i === 0 ? 25 : 0}
                monthLegend={() => null}
                onClick={onDayClick && ((e) => {
                  const day = e?.day;
                  if (!day || e?.value === undefined) {
                    return;
                  }
                  onDayClick(day);
                })}
                tooltip={tooltipFn}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}


function GEMAGVLXMLStatsHoursOverview({isLoading, coveredMonths, data, stats, columnWidth, cellSpacing, width, colorScaleFn, tooltipContentFn}) {
  // console.log({data, stats, coveredMonths});

  return (
    <table cellSpacing={cellSpacing} cellPadding={0} style={{fontSize: '10pt', tableLayout: 'fixed', minWidth: width, maxWidth: width, marginTop: -2}}>
      <thead>
      <tr>
        <th></th>

        {coveredMonths?.map((dates, monthIdx) => (
          [
            (
              <th key={monthIdx} colSpan={dates?.length + 2} style={{whiteSpace: 'nowrap'}}>
                <Typography align="center">
                  {isLoading ? (
                    <Skeleton variant="text" sx={{mx: 'auto'}}><span>{dateFormat(dates[0], "mmmm yyyy")}</span></Skeleton>
                  ) : (
                    dateFormat(dates[0], "mmmm yyyy")
                  )}
                </Typography>
              </th>
            ),
          ]
        ))}
      </tr>
      <tr>
        <th></th>

        {coveredMonths?.map((dates, monthIdx) => (
          [
            (
              <th key={`${monthIdx}/start`} style={{width: dates?.length <= 8 ? '50%' : 1}}></th>
            ),
            ...(isLoading ? [(
              <th key={`${monthIdx}/loading`} colSpan={dates?.length} style={{minWidth: columnWidth * dates?.length, maxWidth: columnWidth * dates?.length}}>
                <Skeleton/>
              </th>
            )] : dates?.map((date, dayIdx) => (
              <Tooltip
                key={`${monthIdx}/${dayIdx}`}
                title={tooltipContentFn({date, dayValue: data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value})}
                followCursor
              >
                <th
                  style={{
                    minWidth: columnWidth,
                    maxWidth: columnWidth,
                    marginLeft: dayIdx === 0 ? 50 : 0,
                    color: stats?.coverage?.by_date?.[dateFormat(date, "yyyy-mm-dd")]?.is_holiday ? '#cc0000' : undefined,
                    backgroundColor: colorScaleFn(data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value),
                  }}
                >
                  {dateFormat(date, "d")}
                </th>
              </Tooltip>
            ))),
            (
              <th key={`${monthIdx}/end`} style={{width: dates?.length <= 8 ? '50%' : 1}}></th>
            ),
          ]
        ))}
      </tr>
      <tr>
        <td></td>

        {coveredMonths?.map((dates, monthIdx) => (
          [
            (
              <td key={monthIdx} colSpan={dates?.length + 2} style={{height: '5px'}}>
              </td>
            ),
          ]
        ))}
      </tr>
    </thead>
    <tbody>
      {[...Array(24)].map((el, hour) => (
        <tr key={hour}>
          <th style={{
            whiteSpace: 'nowrap',
            minWidth: 60,
            maxWidth: 60,
            paddingRight: 10,
            textAlign: 'right',
            fontWeight: 'normal'
          }}>
            {isLoading ? (
              <Skeleton type="text">
                <span>{hour} Uhr</span>
              </Skeleton>
            ) : (
              <>
                {hour} Uhr
              </>
            )}
          </th>

          {coveredMonths?.map((dates, monthIdx) => (
              [
                (
                  <td key={`${monthIdx}/start`}></td>
                ),
                ...(isLoading ? [(
                  <td key={`${monthIdx}/loading`} colSpan={dates?.length}>
                    <Skeleton/>
                  </td>
                )] : dates?.map((date, dayIdx) => {
                  const row = (
                    <td
                      key={`${monthIdx}/${dayIdx}/row`}
                      style={{
                        // background: stats?.coverage?.by_date?.[dateFormat(date, "yyyy-mm-dd")]?.by_hour?.[hour] ? 'lightgray' : undefined,
                        background: data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour] ? colorScaleFn(data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour]?.[0]) : undefined,
                      }}
                    >
                      {data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour]?.length > 1 ? (
                        <Box sx={{display: 'flex', flexDirection: 'column', height: 20}}>
                          <Box style={{flex: 1}}>
                          </Box>
                          <Box style={{flex: 0, height: 2, background: '#ffffff'}}>
                            &nbsp;
                          </Box>
                          <Box style={{
                            background: data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour] ? colorScaleFn(data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour]?.[1]) : undefined,
                            flex: 1,
                          }}>
                          </Box>
                        </Box>
                      ) : null}
                    </td>
                  );

                  if (data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour]) {
                    return (
                      <Tooltip
                        key={`${monthIdx}/${dayIdx}/rowTooltip`}
                        title={tooltipContentFn({
                          date,
                          dayValue: data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value,
                          hour,
                          hourValues: data?.filter(({day}) => day === dateFormat(date, "yyyy-mm-dd"))?.[0]?.value?.byHour?.[hour],
                        })}
                        followCursor
                      >
                        {row}
                      </Tooltip>
                    );
                  } else {
                    return row;
                  }
                })),
                (
                  <td key={`${monthIdx}/end`}></td>
                ),
              ]
          ))}
        </tr>
      ))}
    </tbody>
      {/*{coveredMonths?.map((dates, i) => (*/}
      {/*  <Box key={i}>*/}
      {/*    {dateFormat(dates[0], "mmmm yyyy")}*/}
      {/*    <ul>*/}
      {/*    {dates?.map((date, i) => (*/}
      {/*      <li key={i}>*/}
      {/*        {dateFormat(date, "d")}*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*    </ul>*/}
      {/*  </Box>*/}
      {/*))}*/}
    </table>
  );
}

export function GEMAGVLXMLStats({datum_von, datum_bis, allowHours, stats, stationsCount, children, forceChildren, onDayClick}) {
  const timeRanges = useTimeRanges({datum_von, datum_bis, maxMonths: 13});

  const [selectMusic, setSelectMusic] = useState(false);
  const toggleMusic = () => setSelectMusic(!selectMusic);

  const [selectJingles, setSelectJingles] = useState(false);
  const toggleJingles = () => setSelectJingles(!selectJingles);

  const showAll = (selectMusic && selectJingles) || (!selectMusic && !selectJingles);

  const showMusic = (!!stats?.coverage?.standard_musik_dauer) && (selectMusic || showAll);
  const showJingles = (!!stats?.coverage?.jingle_musik_dauer) && (selectJingles || showAll);

  const [shouldShowHours, setShowHours] = useState(false);
  const showHours = shouldShowHours && allowHours;

  const data = useProcessedCoverageData({coverageDict: stats?.coverage?.by_date, stationsCount});

  const isLoading = !stats;

  const tooltipFn = React.useCallback(({date, originalValue, ...props}) => {
    return (
      <HeatmapTooltip
        date={date}
        dayValue={originalValue}
        showMusic={showMusic}
        showJingles={showJingles}
        stationsCount={stationsCount}
        {...props}
      />
    );
  }, [stats, showMusic, showJingles]);

  const tooltipContentFn = React.useCallback(({date, dayValue, hour, hourValues, ...props}) => {
    return (
      <HeatmapTooltipContent
        date={date}
        dayValue={dayValue}
        hour={hour}
        hourValues={hourValues}
        showMusic={showMusic}
        showJingles={showJingles}
        stationsCount={stationsCount}
        {...props}
      />
    );
  }, [stats, showMusic, showJingles]);

  const categoryColorScaleFn = useCategoryColorScaleFunction();

  const colorScaleFn = React.useCallback((valueObj) => {
    const standardCoverageStats = valueObj?.standardCoverageStats;
    const jingleCoverageStats = valueObj?.jingleCoverageStats;

    let category, relativeIntervalPosition;
    if (showMusic && showJingles && (standardCoverageStats?.priority === jingleCoverageStats?.priority)) {
      category = standardCoverageStats?.category;
      relativeIntervalPosition = (standardCoverageStats?.relativeIntervalPosition + jingleCoverageStats?.relativeIntervalPosition) / 2;
    } else if (showJingles && ((jingleCoverageStats?.priority > standardCoverageStats?.priority && jingleCoverageStats?.category !== 'empty') || !showMusic)) {
      category = jingleCoverageStats?.category;
      relativeIntervalPosition = jingleCoverageStats?.relativeIntervalPosition;
    } else {
      category = standardCoverageStats?.category;
      relativeIntervalPosition = standardCoverageStats?.relativeIntervalPosition;
    }

    return categoryColorScaleFn({category, relativeIntervalPosition});
  });

  const columnWidth = 16;
  const cellSpacing = 2;
  const width = showHours ? ((columnWidth + cellSpacing) * data?.length + 5 * timeRanges?.length + 200) : (timeRanges?.length * 240);

  return (!datum_von && !datum_bis || datum_bis < datum_von) ? children : (
    <div style={{position: 'relative'}}>
      {forceChildren && children ? (
        <div style={{marginBottom: 20}}>
          {children}
        </div>
      ) : null}
      {/*<Box sx={{height: 40, background: 'red'}}>*/}
      {/*</Box>*/}
      <div style={{width: '100%', height: showHours ? '580px' : '260px', position: 'relative'}}>
        <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          width: '100%',
          height: showHours ? '600px' : '280px',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}>
          <div style={{width: width + 240 + 280, height: showHours ? '580px' : '260px', overflow: 'hidden'}}>
            <Box display="flex" flexDirection="row">
              <StatsOverview
                stats={stats}
                standardSelected={selectMusic}
                jingleSelected={selectJingles}
                onStandardClick={toggleMusic}
                onJingleClick={toggleJingles}
                isLoading={isLoading}
              />
              {showHours ? (
                <GEMAGVLXMLStatsHoursOverview
                  coveredMonths={timeRanges}
                  isLoading={isLoading}
                  data={data}
                  stats={stats}
                  colorScaleFn={colorScaleFn}
                  tooltipContentFn={tooltipContentFn}
                  columnWidth={columnWidth}
                  cellSpacing={cellSpacing}
                  width={width}
                />
              ) : (
                <GEMAGVLXMLStatsMonthsOverview
                  coveredMonths={timeRanges}
                  isLoading={isLoading}
                  data={data}
                  colorScaleFn={colorScaleFn}
                  tooltipFn={tooltipFn}
                  onDayClick={onDayClick}
                />
              )}
              <div style={{height: '260px', width: '240px', marginLeft: 10}}>
                <div style={{height: '240px', paddingTop: 40}}>
                  <GEMAGVLXMLStatsLegend isLoading={isLoading} showHours={showHours} setShowHours={setShowHours}/>
                </div>
              </div>
            </Box>
          </div>
        </div>
        {allowHours && (
          <ToggleButtonGroup
            value={showHours ? "hours" : "days"}
            exclusive
            size="small"
            onChange={(evt, newValue) => {
              if (newValue) {
                setShowHours(newValue === "hours");
              }
            }}
            sx={{position: 'absolute', right: 0, top: 0, background: '#ffffff'}}
          >
            <ToggleButton value="days" aria-label="show days">
              Tage
            </ToggleButton>
            <ToggleButton value="hours" aria-label="show hours">
              Stunden
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
    </div>
  );
}

export default function GEMAGVLXMLReportStats({id, children, forceChildren}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id});
  useEntityObserver({type: 'gemagvlxml_lieferung_stats', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    datum_von,
    datum_bis,
    stations,
    is_collective_report: isCollectiveReport,
  } = getGEMAGVLXMLLieferung(id);
  const stationsCount = stations?.length || 1;
  const allowHours = !isCollectiveReport;

  const getGEMAGVLXMLLieferungStats = useSelector(getGEMAGVLXMLLieferungStatsGetter);
  const {stats} = getGEMAGVLXMLLieferungStats(id);

  return (
    <GEMAGVLXMLStats
      datum_von={datum_von}
      datum_bis={datum_bis}
      stats={stats}
      allowHours={allowHours}
      stationsCount={stationsCount}
      onDayClick={(day) => navigate(`/dashboard/reports/${id}/entries/?date=${day}`)}
      children={children}
      forceChildren={forceChildren}
    />
  );
}
