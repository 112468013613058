import {Chip, Tooltip} from "@mui/material";
import {Clear, Link as LinkIcon} from "@mui/icons-material";
import React from "react";
import {useEntityApi, useOrgMusicWork, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";

export default function GVLLinkIndicator({
  id,
  allowUnlink,
  label = "Referenzdaten zugeordnet"
}) {
  const {
    linked_gvl_produkt: gvlProductId
  } = useOrgMusicWork({
    id,
    observe: false
  });

  const {id: organizationId} = useSelectedOrganization();

  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  const unlink = async () => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organizationId}/org_music_works/${id}/unlink_gvl_produkt/`,
      {gvlProductId},
    );
  };

  return (
    <Tooltip
      title="Für diese Musikproduktion stehen Referenzdaten zur Verfügung, die von der GVL bereitgestellt wurden. Etwaige fehlende Metadaten werden automatisch ergänzt."
    >
      <Chip
        icon={<LinkIcon/>}
        label={label}
        variant="outlined"
        onDelete={allowUnlink ? (
          () => confirm("Referenzdaten-Verknüpfung löschen?") && unlink()
        ) : undefined}
        deleteIcon={allowUnlink ? (<Clear/>) : undefined}
      />
    </Tooltip>
  );
}
