import {Chip} from "@mui/material";
import React from "react";

export const GEMA_PERSON_ROLES = {
  K: "Komponist",
  B: "Bearbeiter",
  SB: "Subbearbeiter",
  T: "Texter",
  ST: "Subtextdichter",
  TS: "Spezialtextdichter",
  V: "Verlag",
  OV: "Originalverlag",
  SV: "Subverlag",
  IN: "Interpret",
  KT: "Komponist/Textdichter",
  SNV: "Sonstiger Name Verlag",
};

export function GEMAWorkPersonRolle({role}) {
  let description = GEMA_PERSON_ROLES[role];
  if (description === undefined) {
    return role;
  }

  return (
    description
  );
}

export default function GEMAWerkPerson({
  gemaWorkPerson,
  showRole
}) {
  const {
    GEMAroleCode,
    type,
    firstName,
    name,
  } = gemaWorkPerson || {};

  const roleBadge = showRole ? (
    <Chip label={<GEMAWorkPersonRolle role={GEMAroleCode}/>} size="small" component="span"/>
  ) : null;

  return (
    <>
      {firstName} {name}
      {roleBadge ? (
        <>
          {' '}
          {roleBadge}
        </>
      ) : null}
    </>
  );
}
