import React, {useState} from "react";
import _ from "lodash";
import {Box} from '@mui/material';
import axios from "axios";
import {useLazyEffect} from "src/packages/lazy-effect";
import {FormTextFieldSuggestions} from "src/packages/react-hook-form-mui-form-fields";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {useOrganization} from "src/features/entity/entity-hooks";

/* Check if string is valid UUID */
function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

function formatOrganization(id) {
  if (!checkIfValidUUID(id)) {
    id = undefined;
  }

  const organization = useOrganization({id});

  if (!id) {
    return '';
  }

  if (!organization?.id) {
    return `${id}`;// [UNBEKANNT]`;
  }

  return `${organization?.company_name}`;
}

const ReceiverOrganizationSuggestionsField = ({
  name,
  onFocus,
  autoCompleteProps={},
  ...props
}) => {
  // const {
  //   watch,
  //   setValue,
  // } = useFormContext();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const [options, setOptions] = useState(null);

  const [inputValue, setInputValue] = React.useState('');

  const formattedOrganization = formatOrganization(inputValue);

  useLazyEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let options = null;
      try {
        setOptions(options);
        const result = await axios.get(`/api/sendemeldung/organizations/${selectedOrganizationId}/suggested_receiver_organizations/`);
        options = result.data?.results || [];
      } catch (e) {
        console.error(e);
        options = [];
      }
      if (isSubscribed) {
        setOptions([{id: "", company_name: "(keine Auswahl)"}, ...options]);
      }
    }

    fetchData().catch(console.error);

    return () => isSubscribed = false;
  }, [name, selectedOrganizationId], 300);

  return (
    <FormTextFieldSuggestions
      name={name}
      suggestions={options}
      autocompleteProcessOnChangeData={(data) => {
        if (!data) {
          return null;
        }

        return data?.id;
      }}
      defaultValue=''
      freeSolo={false}
      autocompleteProps={{
        ...autoCompleteProps,
        blurOnSelect: true,
        inputValue: formattedOrganization || inputValue,
        onInputChange: (event, newInputValue) => {
          setInputValue(newInputValue);
        },
        isOptionEqualToValue: (option, value) => option?.id === value,
        getOptionLabel: (option) => (_.isString(option) || _.isInteger(option)) ? `${option}` : `${option?.company_name}`,
        renderOption: (props, option, {selected}) => (
          <li {...props}>
            {option?.id === '' ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box sx={{pl: 1}}>
                  <i>(keine Auswahl)</i>
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box sx={{pl: 1}}>
                  {option?.company_name}<br/>
                  {/*<Typography*/}
                  {/*  variant="subtitle2"*/}
                  {/*  color="lightgray"*/}
                  {/*  sx={{*/}
                  {/*    whiteSpace: 'nowrap',*/}
                  {/*    textOverflow: 'ellipsis'*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {option?.company_name}*/}
                  {/*</Typography>*/}
                </Box>
              </Box>
            )}
          </li>
        ),
      }}
      {...props}
    />
  );
};

export default ReceiverOrganizationSuggestionsField;
