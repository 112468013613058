import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {DISABLE_2FA_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {CONTACT} from "src/api/api-schemas";
import { Alert } from '@mui/material';

function Disable2FAFormDialogContent({}) {
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert variant="outlined" severity="info">
            Sie sind im Begriff, den zweiten Faktor für Ihr Benutzerkonto zu löschen. Nach Durchführung dieser Aktion
            benötigen Sie lediglich Ihren Benutzernamen und Ihr Passwort, um sich in Sendemeldung.de einzuloggen.
            <p>
              Aus Sicherheitsgründen müssen Sie diese Aktion bestätigen, indem Sie Ihr aktuelles Passwort eingeben.
            </p>
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <YupField name="password"/>
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function Disable2FAForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(CONTACT);

  const saveContact = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/contacts/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'disable-2fa'}
      title={"Zwei-Faktor-Authentifizierung deaktivieren"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveContact}
      schema={DISABLE_2FA_SCHEMA}
      saveCaption={"Zwei-Faktor-Authentifizierung deaktivieren"}
      cancelCaption={"Abbrechen"}
      {...props}
    >
      <Disable2FAFormDialogContent
        data={data}
      />
    </SimpleYupFormDialog>
  );
}
