import {Chip} from "@mui/material";
import React from "react";

export const GVL_PERSON_ROLES = {
  K: "Komponist",
  I: "Interpret",
};

export function GVLProductPersonRolle({role}) {
  let description = GVL_PERSON_ROLES[role];
  if (description === undefined) {
    return null;
  }

  return (
    description
  );
}

export default function GVLProductPerson({
  gvlProductPerson,
  showRole
}) {
  const {
    rolle,
    vorname,
    vorname_zusatz,
    nachname,
    nachname_zusatz,
  } = gvlProductPerson || {};

  const roleBadge = showRole ? (
    <Chip label={<GVLProductPersonRolle role={rolle}/>} size="small" component="span"/>
  ) : null;

  return (
    <>
      {vorname} {vorname_zusatz} {nachname} {nachname_zusatz}
      {roleBadge ? (
        <>
          {' '}
          {roleBadge}
        </>
      ) : null}
    </>
  );
}
