import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {ORGANIZATION_DETAILS_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {ORGANIZATION} from "src/api/api-schemas";

export default function OrganizationDetailsForm({data, ...props}) {
  const entityApi = useEntityApi(ORGANIZATION);

  const {id, company_name, address, display_name} = data || {};

  const saveOrganization = async (validatedData) => {
    const {id} = validatedData;
    await entityApi.patch(
      `/api/sendemeldung/organizations/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      title={"Stammdaten bearbeiten"}
      open={!!data}
      data={{id, company_name, address, display_name}}
      submit={saveOrganization}
      schema={ORGANIZATION_DETAILS_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="company_name"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="address"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="display_name"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
