import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function UploadedFileFilter({...props}) {
  return (
    <FancyFilterField
      options={uploadedFileOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Uploads suchen..."
      {...props}
    />
  );
}

const uploadedFileOptions = [
  {
    category: "Status",
    id: 'status',
    label: 'Status',
    choice: 'success',
    chipLabel: ({id, choice}) => ({
      '200': "verarbeitet",
      '500': "Fehler",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '200': "verarbeitet",
      '500': "Fehler",
    }[choice]),
    choices: ['200', '500'],
  },
];
