import {useSelector} from "react-redux";
import {getGEMAWorkGetter} from "src/features/entity";
import {Box, Paper, Typography} from "@mui/material";
import React from "react";
import GEMAWerkTitle from "src/components/entities/refdata/GEMAWerkTitle";
import GEMAWerkPerson from "src/components/entities/refdata/GEMAWerkPerson";

export default function GEMAWerkSummary({
  id,
}) {
  // useEntityObserver({
  //   type: 'gema_work',
  //   id
  // });

  const {
    workNumber,
    workVersionNumber,
    titles,
    interestedParties,
    otherParties,
  } = useSelector(getGEMAWorkGetter)(id);

  return <Paper elevation={3}>
    <Box m={1}>
      <Typography gutterBottom>
        GEMA-Werk {workNumber}<span style={{color: 'lightgray'}}>-{workVersionNumber}</span>
      </Typography>
      {titles?.map((el, i) => i === 0 ? (
        <Typography key={i} fontWeight="bold">
          <GEMAWerkTitle gemaWorkTitle={el}/>
        </Typography>
      ) : null)}
      {otherParties?.map((el, i) => (
        <Typography key={i}>
          <GEMAWerkPerson gemaWorkPerson={el} showRole/>
        </Typography>
      ))}
      {interestedParties?.map((el, i) => (
        <Typography key={i}>
          <GEMAWerkPerson gemaWorkPerson={el} showRole/>
        </Typography>
      ))}
    </Box>
  </Paper>;
}
