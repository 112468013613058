import {MUSIC_PERSON_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useOrgMusicWork, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {MUSIK_PERSON} from "src/api/api-schemas";
import {useFormContext} from "react-hook-form";
import PersonSuggestionsField from "src/components/entities/musicwork/components/PersonSuggestionsField";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

function MusicPersonFormDialogContent({}) {
  const {watch} = useFormContext();

  const musik_person_rolle_knz = watch('musik_person_rolle_knz');
  let rolle = undefined;
  switch (musik_person_rolle_knz) {
    case "K":
      rolle = "K";
      break;
    case "INT":
      rolle = "I";
      break;
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField name="musik_person_rolle_knz"/>
        </Grid>
        <Grid item xs={6}>
          <YupField
            name="name"
            Component={PersonSuggestionsField}
          />
        </Grid>
        <Grid item xs={6}>
          <YupField
            name="vorname"
            Component={PersonSuggestionsField}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function MusicPersonForm({
  data,
  onClose,
  ...props
}) {
  const {org_music_work: orgMusicWorkId} = data;
  const {database} = useOrgMusicWork({id: orgMusicWorkId});
  data = {
    ...data,
    database,
  };
  const {id: organizationId} = useSelectedOrganization();

  const entityApi = useEntityApi(MUSIK_PERSON);

  const save = async (validatedData) => {
    const {id} = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organizationId}/musik_personen/${id}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organizationId}/musik_personen/`,
        validatedData,
        {
          createEntities: true,
          organization: organizationId
        },
      );
    }

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      id={'edit-music-person'}
      entityType="musik_person"
      baseUrl={`/api/sendemeldung/organizations/${organizationId}/musik_personen/`}
      title={data?.id ? "Person bearbeiten" : "Person zu Musikproduktion hinzufügen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={save}
      schema={MUSIC_PERSON_SCHEMA}
      allowDelete
      deleteConfirmation="Person wirklich löschen?"
      deleteCaption="Person löschen"
      fullWidth
      {...props}
    >
      <MusicPersonFormDialogContent/>
    </EntityFormDialog>
  );
}
