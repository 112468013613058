import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import {AlertTitle, List} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import NotificationFilter from "src/components/entities/notification/NotificationFilter";
import NotificationRow from "src/components/entities/notification/NotificationRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 800,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function NotificationList({organizationId}) {
  const classes = useStyles();

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'notifications',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/notifications/',
    entityType: 'notification',
    defaultOrdering: ['-timestamp'],
  });

  return (
    <>
      <PageToolbar
        title="Benachrichtigungen"
        isAtTop
        searchField={(
          <NotificationFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Benachrichtigungen vorhanden</AlertTitle>
            Es liegen derzeit keine aktuellen Benachrichtigungen vor.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Benachrichtigungen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Benachrichtigungen gefunden</AlertTitle>
            Es wurden keine Benachrichtigungen gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        <List className={classes.root}>
          {renderIds?.map((id, i) => (
            <NotificationRow
              key={id || i}
              id={id}
              organizationId={organizationId}
            />
          ))}
        </List>
      )}
    </>
  );
}
