import React, {useEffect} from "react";
import {DashboardLayout} from "src/components/layout";
import Alert from '@mui/material/Alert';
import MusicWorkTable from "src/components/entities/musicwork/MusicWorkTable";
import CircularProgress from "@mui/material/CircularProgress";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {getOrganizationGetter} from "src/features/entity";
import {navigate} from "gatsby";

export default function DatabasePage({
  id,
  listingId = 'org_music_works'
}) {
  useRequirePermissions({perm_read_music: true});

  const organizationId = useSelector(getSelectedOrganizationId);

  const {databases} = useSelector(getOrganizationGetter)(organizationId);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (databases?.length > 0 && !databases.includes(id)) {
        navigate(`/dashboard/database/${databases[0]}/`);
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [id, databases]);

  return (
    <DashboardLayout
      titlePrefix="Musikdatenbank"
      selectedPage={`database/${id}`}
    >
      <MusicWorkTable
        databaseId={id}
        organizationId={organizationId}
        listingId={listingId}
        noEntriesContent={(
          <Alert variant="filled" severity="info">
            Ihre Musikdatenbank enthält noch keine Einträge.
          </Alert>
        )}
        loadingContent={(
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Musikdatenbank wird geladen...
          </Alert>
        )}
        isAtTop
        title="Musik"
      >
        <Alert variant="filled" severity="info">
          Diese Ansicht enthält keine Einträge.
        </Alert>
      </MusicWorkTable>
    </DashboardLayout>
  );
}
