import {Chip, Grid, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {Add, Block, Check, Edit, HelpOutline, HourglassEmpty} from "@mui/icons-material";
import React from "react";
import {getStationGetter} from "src/features/entity";
import {useSelector} from "react-redux";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import { Alert, Skeleton } from '@mui/material';
import GEMAGVL4Station from "src/components/entities/programme/GEMAGVL4Station";
import makeStyles from '@mui/styles/makeStyles';
import dateFormat from "dateformat";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

const useStyles = makeStyles((theme) => ({
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 48,
    color: theme.palette.action.active,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    //flexBasis: '100.0%',
    //flexShrink: 0,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function ProgrammeRow({stationId, editStation}) {
  const {
    id,
    name,
    gemagvl_sender_prg_id,
    gemagvl4_stations,
    isDeleted,
    is_active,
    is_finished,
    is_draft,
    reports_count,
    can_delete,
    latest_report_date,
  } = useSelector(getStationGetter)(stationId);

  useEntityObserver({type: 'station', id: stationId});

  const classes = useStyles();

  if (isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Alert variant="filled" color="error" severity="success">
            Dieses Programm wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  const icon = (is_active || is_finished) ? (
    <Check color="action" fontSize="large"/>
  ) : reports_count > 0 ? (
    <HourglassEmpty color="action" fontSize="large"/>
  ) : can_delete ? (
    <Block color="action" fontSize="large"/>
  ) : (
    <HelpOutline color="action" fontSize="large"/>
  );

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {name ? (
          name
        ) : (
          <OnlyIfPermissions perm_write_stations>
            <Chip
              icon={<Add/>}
              label="Programmname ergänzen"
              variant="outlined"
              clickable
              color="primary"
              onClick={() => editStation(stationId)}
            />
          </OnlyIfPermissions>
        )}
      </TableCell>
      <TableCell>
        {!gemagvl_sender_prg_id ? (
          <>
            <OnlyIfPermissions perm_write_stations>
              <Chip
                icon={<Add/>}
                label="Programmkennzeichen ergänzen"
                variant="outlined"
                clickable
                color="primary"
                onClick={() => editStation(stationId)}
              />
            </OnlyIfPermissions>
          </>
        ) : (
          <>
            {gemagvl_sender_prg_id ? (
              <p style={{marginTop: 0}}>
                {gemagvl_sender_prg_id}
                {' '}
                <Chip label="GEMAGVL-XML" size="small" component="span"/>
              </p>
            ) : (
              <OnlyIfPermissions perm_write_stations>
                <Chip
                  icon={<Add/>}
                  label="Angaben für GEMAGVL-XML ergänzen"
                  variant="outlined"
                  clickable
                  color="primary"
                  onClick={() => editStation(stationId)}
                />
              </OnlyIfPermissions>
            )}

            {gemagvl4_stations?.map((gemagvl4StationId, i) => (
              <GEMAGVL4Station key={gemagvl4StationId || i} id={gemagvl4StationId}/>
            ))}
          </>
        )}
      </TableCell>
      <TableCell>
        <Grid container style={{alignItems: 'center'}}>
          <div className={classes.icon}>
            {!id ? (
              <Skeleton variant="circular">
                {icon}
              </Skeleton>
            ) : (
              icon
            )}
          </div>
          <div className={classes.heading2}>
            <Typography variant="inherit" className={classes.heading2}>
              {is_active ? (
                latest_report_date ? (
                  <>
                    Gemeldet bis {dateFormat(latest_report_date, "dd.mm.yyyy")}.
                  </>
                ) : (
                  "Aktiv."
                )
              ) : is_finished ? (
                "Meldungen abgeschlossen."
              ) : reports_count > 0 ? (
                "Meldungen ausstehend."
              ) : can_delete ? (
                "Nicht verwendet."
              ) : null}
            </Typography>
          </div>
        </Grid>
      </TableCell>
      {/*<TableCell>*/}
      {/*  {report_jingles === true ? (*/}
      {/*    <div className={classes.icon}>*/}
      {/*      <VolumeUp color="action" fontSize="large"/>*/}
      {/*    </div>*/}
      {/*  ) : report_jingles === false && false ? (*/}
      {/*    <div className={classes.icon}>*/}
      {/*      <Block color="action" fontSize="large"/>*/}
      {/*    </div>*/}
      {/*  ) : null}*/}
      {/*</TableCell>*/}
      <TableCell align="right">
        <OnlyIfPermissions perm_write_stations>
          <IconButton aria-label="edit" onClick={() => editStation(stationId)} size="large">
            <Edit/>
          </IconButton>
        </OnlyIfPermissions>
      </TableCell>
    </TableRow>
  );
}
