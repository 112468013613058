import React, {useMemo} from "react";
import {DashboardLayout} from "src/components/layout";
import {Grid,} from "@mui/material";
import {useSelector} from "react-redux";
import {getPendingFileInternalIds} from "src/features/dashboard";
import _ from 'lodash';
import Dropzone from "src/components/dashboard/dropzone-redux";
import UploadedFileRow from "src/components/entities/upload/UploadedFileRow";
import UploadingFileRow from "src/components/entities/upload/UploadingFileRow";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import UploadedFileFilter from "src/components/entities/upload/components/UploadedFileFilter";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function ImportPage() {
  useRequirePermissions({perm_read_reports: true});

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'uploaded_files',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/uploaded_files/',
    entityType: 'uploaded_file',
    reverseCreatedIds: true,
  });

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  const uploadingFileIds = useSelector(getPendingFileInternalIds);

  return (
    <DashboardLayout
      titlePrefix="GEMAGVL4-Import"
      selectedPage="import"
    >
      <OnlyIfPermissions perm_write_reports>
        <Dropzone>
          <p>bitte GEMAGVL4-Sendemeldung(en) hier hineinziehen</p>
          <p>oder</p>
        </Dropzone>
        <br/>
      </OnlyIfPermissions>
      <PageToolbar
        title="Hochgeladene Dateien"
        searchField={(
          <UploadedFileFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      <Grid container mt={-1}>
        <Grid item xs={12}>
          {uploadingFileIds?.map((internalId, i) => (
            <UploadingFileRow
              key={internalId || i}
              internalId={internalId}
            />
          ))}

          {renderIds.length === 0 ? (
            noDataExists ? (
              <Alert variant="filled" severity="info">
                Es wurden noch keine Vorgänge erstellt.
              </Alert>
            ) : isLoading ? (
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                Vorgänge werden geladen...
              </Alert>
            ) : (
              <Alert variant="filled" severity="info">
                Diese Ansicht enthält keine Einträge.
              </Alert>
            )
          ) : (
            renderIds?.map((id, i) => (
              <UploadedFileRow
                key={id || i}
                id={id}
                expanded={expanded === id}
                onChange={handleChange(id)}
                onlyRoot
              />
            ))
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
