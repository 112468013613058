import React, {useState} from "react";
import _ from "lodash";
import {Box, Typography} from '@mui/material';
import {useFormContext} from "react-hook-form";
import axios from "axios";
import {useLazyEffect} from "src/packages/lazy-effect";
import LabelCode from "src/components/entities/musicwork/components/LabelCode";
import {FormTextFieldSuggestions} from "src/packages/react-hook-form-mui-form-fields";

const LabelSuggestionsField = ({
  name,
  onFocus,
  ...props
}) => {
  const {
    watch,
    setValue,
  } = useFormContext();

  const label = watch('label');
  const labcode = watch('label_code');

  const [options, setOptions] = useState(null);

  const [focussedOnce, setFocussedOnce] = useState(false);

  useLazyEffect(async () => {
    if (!focussedOnce) {
      return;
    }
    try {
      let qs;
      if (name === 'label') {
        qs = labcode ? `label_code=${encodeURIComponent(labcode)}` : `q=${encodeURIComponent(label)}`;
      } else {
        qs = label ? `label=${encodeURIComponent(label)}` : `q=${encodeURIComponent(labcode)}`;
      }

      if (labcode || label) {
        setOptions(null);
        const result = await axios.get(`/api/sendemeldung/refdata/labels/?${qs}`);
        setOptions(result.data?.results || []);
      } else {
        setOptions([]);
      }
    } catch (e) {
      console.error(e);
      setOptions([]);
    }
  }, [labcode, label, name, focussedOnce], 300);

  return (
    <FormTextFieldSuggestions
      name={name}
      suggestions={options}
      autocompleteProcessOnChangeData={(data) => {
        if (!data) {
          return null;
        }

        if (name === 'label' && !labcode) {
          setValue('label_code', data['label_code']);
        } else if (name === 'label_code' && !label) {
          setValue('label', data['label']);
        }

        return data[name];
      }}
      autocompleteProps={{
        isOptionEqualToValue: (option, value) => option[name] === value,
        getOptionLabel: (option) => (_.isString(option) || _.isInteger(option)) ? `${option}` : `${option[name]}`,
        renderOption: (props, option, {selected}) => (
          <li {...props}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <LabelCode value={option?.label_code} size="small"/>
              <Box sx={{pl: 1}}>
                {option?.label}<br/>
                <Typography
                  variant="subtitle2"
                  color="lightgray"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {option?.company_name}
                </Typography>
              </Box>
            </Box>
          </li>
        ),
      }}
      onFocus={(e) => {
        setFocussedOnce(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      {...props}
    />
  );
};

export default LabelSuggestionsField;
