import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Chip, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getSelectedOrganization} from "src/features/dashboard";
import {useSelector} from "react-redux";
import {Add, AddLocation, Business, Check, HourglassEmpty} from "@mui/icons-material";
import Alert from '@mui/material/Alert';
import OrganizationDetailsForm from "src/components/entities/user/OrganizationDetailsForm";
import LieferantIDFormDialog from "src/components/entities/user/LieferantIDFormDialog";
import {useHasPermissions, useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {SettingAccordion} from "src/components/layout/components/SettingAccordion";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 32,
    color: theme.palette.action.active,
  },
  nextToIcon: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const ORGANIZATION_TYPES = {
  HF: "Hörfunk-/Radiosender",
  VWG: "Verwertungsgesellschaft",
  PRV: "Plattformbetreiber",
}

export default function OrganizationPage() {
  useRequirePermissions({perm_read_details: true});

  const organization = useSelector(getSelectedOrganization);
  const {
    customer_id,
    company_name,
    address,
    type,
    gemagvlxml_lieferant_id,
    gemagvl4_encoding_preferences,
    gemagvl4_timezone,
    jingle_musik_herkunft_knz,
    is_gemagvl_approved: isApproved,
    requires_test: requiresTest,
    has_published_reports: hasPublished,
    gvl_customer_id: gvlCustomerId,
    gema_customer_id: gemaCustomerId,
  } = organization;
  const displayAddress = address?.replace(/\r?\n/g, ', ');

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [editOrganization, setEditOrganization] = useState(null);
  const {autoFocusField: editOrganizationAutoFocusField, ...editOrganizationData} = editOrganization || {};

  const [editLieferantID, setEditLieferantID] = useState(null);

  const hasWritePermission = useHasPermissions({perm_write_details: true});

  return (
    <DashboardLayout
      titlePrefix="Stammdaten"
      selectedPage="organization"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h6">
          Stammdaten Ihres Unternehmens
        </Typography>
      </Box>

      <Box sx={{width: '100%'}}>
        <SettingAccordion
          id="details"
          expanded={expanded === 'details'}
          onChange={handleChange('details')}
          onEdit={hasWritePermission ? (() => setEditOrganization({...organization, autoFocusField: 'company_name'})) : null}
          title="Firmenname"
          description={(
            <>
              {company_name ? (
                <>
                  {company_name}
                </>
              ) : (
                <OnlyIfPermissions perm_write_details>
                  <Chip
                    icon={<Business/>}
                    label="Firmenname ergänzen"
                    variant="outlined"
                    clickable
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditOrganization(organization);
                    }}
                  />
                </OnlyIfPermissions>
              )}
            </>
          )}
        >
        </SettingAccordion>

        <SettingAccordion
          id="address"
          expanded={expanded === 'address'}
          onChange={handleChange('address')}
          onEdit={hasWritePermission ? (() => setEditOrganization({...organization, autoFocusField: 'address'})) : null}
          title="Adresse"
          description={(
            <>
              {displayAddress ? displayAddress : (
                <OnlyIfPermissions perm_write_details>
                  <Chip
                    icon={<AddLocation/>}
                    label="Adresse ergänzen"
                    variant="outlined"
                    clickable
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditOrganization(organization);
                    }}
                  />
                </OnlyIfPermissions>
              )}
            </>
          )}
        >
        </SettingAccordion>

        {customer_id ? (
          <SettingAccordion
            id="customer_id"
            expanded={expanded === 'customer_id'}
            onChange={handleChange('customer_id')}
            title="Kundennummer"
            description={customer_id}
          >
            <Typography>
              Ihre Kundenummer lautet <strong>{customer_id}</strong>. Bitte geben Sie diese bei Rückfragen an.
            </Typography>
          </SettingAccordion>
        ) : null}

        <br/>

        {type ? (
          <SettingAccordion
            id="type"
            title="Typ"
            description={ORGANIZATION_TYPES[type] || type}
          >
            <Typography>
              Ihr Unternehmen ist als <strong>{ORGANIZATION_TYPES[type] || type}</strong> erfasst.
            </Typography>
          </SettingAccordion>
        ) : null}

        {type !== 'HF' ? null : (
          isApproved ? (
            <SettingAccordion
              id="gemagvl_link"
              expanded={expanded === 'gemagvl_link'}
              onChange={handleChange('gemagvl_link')}
              title="GEMA / GVL"
              description={(
                <>
                  <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <div className={classes.icon}>
                      <Check/>
                    </div>
                    <div className={classes.nextToIcon}>
                      <Typography className={classes.nextToIcon}>
                        Verbunden mit GEMA und GVL.
                      </Typography>
                    </div>
                  </Grid>
                </>
              )}
            >
              <div>
                <Box mb={2}>
                  <Typography>
                    Ihre Daten wurden von den Verwertungsgesellschaften überprüft und Ihr Haus ist für die Übermittlung
                    von
                    Produktiv-Sendemeldungen via Sendemeldung.de freigeschaltet.
                  </Typography>
                </Box>

                {gemagvlxml_lieferant_id ? (
                  <SettingAccordion
                    id="lieferant_id"
                    title="Lieferant-ID"
                    description={gemagvlxml_lieferant_id}
                  >
                    <Typography>
                      Sendemeldungen Ihres Hauses werden unter der mit GEMA und GVL vereinbarten Lieferant-ID
                      {' '}
                      <strong>{gemagvlxml_lieferant_id}</strong>
                      {' '}
                      übermittelt.
                    </Typography>
                  </SettingAccordion>
                ) : null}

                {gemaCustomerId ? (
                  <SettingAccordion
                    id="gema_customer_id"
                    title="GEMA-Kundennummer"
                    description={gemaCustomerId}
                  >
                    <Typography>
                      Ihr Haus wird bei der GEMA unter der Kundennummer
                      {' '}
                      <strong>{gemaCustomerId}</strong>
                      {' '}
                      geführt.
                    </Typography>
                  </SettingAccordion>
                ) : null}

                {gvlCustomerId ? (
                  <SettingAccordion
                    id="gvl_customer_id"
                    title="GVL-Kundennummer"
                    description={gvlCustomerId}
                  >
                    <Typography>
                      Ihr Haus wird bei der GVL unter der Kundennummer
                      {' '}
                      <strong>{gvlCustomerId}</strong>
                      {' '}
                      geführt.
                    </Typography>
                  </SettingAccordion>
                ) : null}

              </div>
            </SettingAccordion>
          ) : hasPublished ? (
            <SettingAccordion
              id="gemagvl_link"
              expanded={expanded === 'gemagvl_link'}
              onChange={handleChange('gemagvl_link')}
              title="GEMA / GVL"
              description={(
                <>
                  <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <div className={classes.icon}>
                      <HourglassEmpty/>
                    </div>
                    <div className={classes.nextToIcon}>
                      <Typography className={classes.nextToIcon}>
                        Warte auf Freigabe.
                      </Typography>
                    </div>
                  </Grid>
                </>
              )}
            >
              <div>
                <Alert variant="outlined" severity="info">
                  Bitte haben Sie etwas Geduld, während die Verwertungsgesellschaften Ihre Beispiel-Sendemeldung prüfen.
                </Alert>
              </div>
            </SettingAccordion>
          ) : (
            <SettingAccordion
              id="gemagvlxml_lieferant_id"
              expanded={expanded === 'gemagvlxml_lieferant_id'}
              onChange={handleChange('gemagvlxml_lieferant_id')}
              onEdit={hasWritePermission ? (() => setEditLieferantID(organization)) : null}
              title="GEMA / GVL"
              description={(
                <>
                  {gemagvlxml_lieferant_id ? (
                    <>
                      {gemagvlxml_lieferant_id}
                      {' '}
                      <Chip label="Lieferant-ID" size="small"/>
                    </>
                  ) : (
                    <Chip
                      icon={<Add/>}
                      label="Lieferant-ID ergänzen"
                      variant="outlined"
                      clickable
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditLieferantID(organization);
                      }}
                    />
                  )}
                </>
              )}
            >
              <div>
                {!gemagvlxml_lieferant_id ? (
                  <Alert variant="outlined" severity="info">
                    Ihre Lieferant-ID erhalten Sie von den Verwertungsgesellschaften GEMA bzw. GVL.
                  </Alert>
                ) : null}
              </div>
            </SettingAccordion>
          )
        )}
      </Box>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editOrganization ? (
        <OrganizationDetailsForm
          data={editOrganization && editOrganizationData}
          onClose={() => setEditOrganization(null)}
          autoFocusField={editOrganizationAutoFocusField}
        />
      ) : null}

      {editLieferantID ? (
        <LieferantIDFormDialog
          data={editLieferantID}
          onClose={() => setEditLieferantID(null)}
        />
      ) : null}

    </DashboardLayout>
  );
}
