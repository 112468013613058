import {IconButton} from "@mui/material";
import React from "react";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";

export default React.forwardRef(function HrefIconButton(props, ref) {
  return (
    <HrefComponent
      component={IconButton}
      ref={ref}
      {...props}
    />
  );
});
