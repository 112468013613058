import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPendingFile} from "src/features/dashboard";
import {addUnloadBlocker, removeUnloadBlocker} from "src/features/ui/unload-blocker";
import {Button} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import FileRow from "src/components/entities/upload/FileRow";
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

const UploadingFileRow = React.memo(function ({internalId, expanded, ...props}) {
  const {
    name,
    size,
    uploadFailed,
  } = useSelector(getPendingFile)(internalId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadFailed) {
      return;
    }
    const UPLOADING_FILE_BLOCKER = {type: 'uploadingFile', id: internalId};
    dispatch(addUnloadBlocker(UPLOADING_FILE_BLOCKER));
    return () => {
      dispatch(removeUnloadBlocker(UPLOADING_FILE_BLOCKER));
    }
  }, [uploadFailed]);

  const clientStatus = uploadFailed ? "Upload fehlgeschlagen." : "wird hochgeladen...";

  const primaryActions = (
    !uploadFailed ? (
      <Button
        color="primary"
        startIcon={<Cancel/>}
      >
        Upload abbrechen
      </Button>
    ) : null
  );

  let serverStatus = `${size} Bytes hochzuladen`;

  return (
    <FileRow
      id={internalId}
      name={name}
      expanded={expanded}
      expansible={uploadFailed}
      {...props}
      clientInProgress={!uploadFailed}
      clientFailed={uploadFailed}
      clientStatus={clientStatus}
      serverStatus={serverStatus}
      primaryActions={primaryActions}
    >
      {!uploadFailed ? (
        <Alert severity="info">
          <AlertTitle>Upload läuft</AlertTitle>
          Die Datei wird gerade zu Sendemeldung.de übertragen.
        </Alert>
      ) : (
        <Alert variant="filled" severity="error">
          <AlertTitle>Upload fehlgeschlagen</AlertTitle>
          Die Datei konnte nicht zu Sendemeldung.de übertragen werden.
          Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
        </Alert>
      )}
    </FileRow>
  );
});

export default UploadingFileRow;
