import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import Alert from "@mui/material/Alert";
import {AlertTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import GEMAWerkFilter from "src/components/entities/refdata/GEMAWerkFilter";
import GEMAWerkRow from "src/components/entities/refdata/GEMAWerkRow";

export default function GEMAWerkTable({}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'gema_works',
    endpoint: '/api/sendemeldung/refdata/gema_api/',
    entityType: 'gema_work',
  });

  return (
    <>
      <PageToolbar
        // title="GEMA-Werkrecherche"
        searchField={(
          <GEMAWerkFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="contained" severity="info">
            <AlertTitle>Suchbegriff erforderlich</AlertTitle>
            Bitte geben Sie einen Titel ein, um die Suche zu starten.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Suche läuft...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Suchergebnisse</AlertTitle>
            Ihre Suchanfrage ist entweder zu unspezifisch oder es wurden keine Werke mittels der GEMA-Werkrecherche
            gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="labels table">
            <TableHead>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap'}}>GEMA-Werknummer</TableCell>
                <TableCell>Titel</TableCell>
                <TableCell align="center">Beteiligte</TableCell>
                <TableCell>Sonstige Personen</TableCell>
                <TableCell align="center">Dauer</TableCell>
                <TableCell align="center">Identifier</TableCell>
                <TableCell align="center">Genre</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <GEMAWerkRow
                  key={id || i}
                  id={id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
