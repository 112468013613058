import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {getGEMAGVL4LieferungGetter, getGEMAGVLXMLLieferungGetter, getTodoTaskGetter} from "src/features/entity";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_LIEFERUNG} from "src/api/api-schemas";
import Alert from '@mui/material/Alert';
import {
  ArrowDropDown,
  AttachFile,
  Backup,
  Cancel,
  Check,
  Clear,
  Close,
  Delete,
  Drafts, Edit,
  Email,
  ExpandMore,
  GetApp,
  HourglassEmpty,
  InfoOutlined,
  MoreHoriz, PublishedWithChanges,
  Search,
  Undo,
  Warning
} from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper, Skeleton,
  Tooltip
} from '@mui/material';
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import Typography from "@mui/material/Typography";
import {useSessionTokenUrl} from "src/api/api-hooks";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import HrefChip from "src/packages/gatsby-mui-helpers/HrefChip";
import HrefIconButton from "src/packages/gatsby-mui-helpers/HrefIconButton";
import GEMAGVLXMLStats from "src/components/entities/gemagvlxml/GEMAGVLXMLStats";
import ReportCancelRequestFormDialog from "src/components/entities/gemagvlxml/ReportCancelRequestFormDialog";
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {GEMAGVLXMLHistory} from "src/components/entities/gemagvlxml/GEMAGVLXMLHistory";
import CollectiveReportCoveredMonthsFormDialog
  from "src/components/entities/gemagvlxml/CollectiveReportCoveredMonthsFormDialog";
import {parseDate} from "src/packages/date-utils";
import dateFormat from "dateformat";
import NaturalTime from "src/packages/natural-time-view";
import {renderToStaticMarkup, renderToString} from "react-dom/server";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import germanStrings from "react-timeago/lib/language-strings/de";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 48,
    color: theme.palette.action.active,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    //flexBasis: '100.0%',
    //flexShrink: 0,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& > p': {
      display: 'inline-block',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandedAccordion: {
    // background: theme.palette.action.hover,
  },
}));

export function GemaGVLXMLGenerateButton({id, ...props}) {
  const hasSubmitPermission = useHasPermissions({perm_submit_reports: true});

  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    organization,
    can_publish: canPublish,
    is_pre_approval_report: isPreApprovalReport,
  } = getGEMAGVLXMLLieferung(id);
  const showPublish = canPublish && hasSubmitPermission;

  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const generate = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/export/`,
    );
  };

  const publish = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/publish/`,
    );
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedItem, setSelectedItem] = React.useState('publish');

  const handleMenuItemClick = (event, item) => {
    setSelectedItem(item);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!showPublish) {
    return (
      <Button
        color="primary"
        {...props}
        onClick={() => generate({organization, gemagvlxml_lieferung: gemaGvlXmlLieferungId})}
      >
        GEMAGVL-XML-Sendemeldung generieren
      </Button>
    );
  }

  return (
    <div style={{zIndex: 1000}}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant='text' color="primary" ref={anchorRef} aria-label="split button" {...props}>
            {selectedItem === 'publish' ? (
              (!isPreApprovalReport && canPublish) ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt an GEMA und GVL übermitteln?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : (isPreApprovalReport && canPublish) ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen zur Prüfung an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt als Test-Lieferung zur Überprüfung durch GEMA / GVL freigeben?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    zur Überprüfung an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : null
            ) : (
              <Button
                color="primary"
                onClick={() => generate({organization, gemagvlxml_lieferung: gemaGvlXmlLieferungId})}
              >
                GEMAGVL-XML-Sendemeldung generieren
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDown/>
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        selected={selectedItem === 'publish'}
                        onClick={(event) => handleMenuItemClick(event, 'publish')}
                      >
                        An GEMA/GVL übermitteln
                      </MenuItem>
                      <MenuItem
                        selected={selectedItem === 'export'}
                        onClick={(event) => handleMenuItemClick(event, 'export')}
                      >
                        XML-Sendemeldung nur generieren
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
}

const getComponentText = (component) => {
  const staticMarkup = renderToStaticMarkup(component);

  // Remove HTML tags.
  return staticMarkup.replace(/<[^>]*>/g, '');
};

function useReportCaption({id, type, isFirst}) {
  let reportCaption = null;
  let dedupeId = '';

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    created_at,
    datum_von,
    datum_bis,
  } = getGEMAGVLXMLLieferung(id);

  if (gemaGvlXmlLieferungId) {
    if (type === 'created_at') {
      reportCaption = (
        <>
          Erstellt <NaturalTime date={created_at} inSentence disableHover live={false}/>
        </>
      );

      dedupeId = getComponentText(reportCaption);
    } else if (type === 'report_datum_bis' && datum_bis) {
      reportCaption = (
        <>
          <NaturalDateRange
            startDate={datum_von}
            endDate={datum_bis}
          />
        </>
      );

      dedupeId = getComponentText(reportCaption);
    }
  }

  return {
    reportCaption: reportCaption ? (
      <Typography gutterBottom sx={isFirst ? {} : {mt: 2}}>
        {reportCaption}
      </Typography>
    ) : null,
    dedupeId,
  };
}

export default function GemaGVLXMLRow(
  {
    id,
    previousId,
    expanded = false,
    defaultExpanded,
    expandedUuid,
    expansible,
    onChange,
    primaryActions,
    isDetailPage,
    renderCaptionType,
    ...props
  },
) {
  const hasWritePermission = useHasPermissions({perm_write_reports: true});
  const hasSubmitPermission = useHasPermissions({perm_submit_reports: true});

  const classes = useStyles();

  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const {reportCaption, dedupeId: reportCaptionDedupeId} = useReportCaption({id, type: renderCaptionType, isFirst: !previousId});
  const {dedupeId: previousReportCaptionDedupeId} = useReportCaption({id: previousId, type: renderCaptionType});

  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded !== undefined ? defaultExpanded : expanded);
  const simpleOnChange = useCallback((event, changedExpanded) => {
    setIsExpanded(changedExpanded);
  }, [setIsExpanded]);

  if (onChange) {
    expansible = !!id;
  }

  expanded = expanded || isExpanded;
  onChange = onChange || simpleOnChange;

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    organization,
    status,
    datum_von,
    datum_bis,
    stations,
    error_messages,
    warning_messages,
    gemagvl4_lieferung,
    ausstrahlungen_count,
    gemagvlxml_exports: xmlExports,
    isDeleted,
    is_completed: isCompleted,
    is_failed: isFailed,
    is_processing: isProcessing,
    is_processing_started: isProcessingStarted,
    is_processing_queued: isProcessingQueued,
    is_processing_failed: isProcessingFailed,
    processing_progress: processingProgress,
    todo_tasks: todoTasks,
    can_delete: canDelete,
    can_request_cancel: canRequestCancel,
    can_publish: canPublish,
    can_reset_to_draft: canResetToDraft,
    can_reset_to_publishing: canResetToPublishing,
    can_redraft: canRedraft,
    is_pre_approval_report: isPreApprovalReport,
    is_last_export_skipped: isLastExportSkipped,
    has_music_playlist_items: hasMusicPlaylistItems,
    has_jingle_playlist_items: hasJinglePlaylistItems,
    is_collective_report: isCollectiveReport,
    collective_report_covered_months: collectiveReportCoveredMonths,
    can_change_collective_report_covered_months: canChangeCollectiveReportCoveredMonths,
  } = getGEMAGVLXMLLieferung(id);
  const showDelete = canDelete && hasWritePermission;
  const showPublish = canPublish && hasSubmitPermission;
  const showRequestCancel = canRequestCancel && hasSubmitPermission;
  const showResetToDraft = canResetToDraft && hasWritePermission;
  const showRedraft = canRedraft && hasSubmitPermission;
  const showResetToPublishing = canResetToPublishing && hasWritePermission;

  const onlyJingles = !isImporting && !hasMusicPlaylistItems && hasJinglePlaylistItems;
  const onlyMusic = !isImporting && !hasJinglePlaylistItems && hasMusicPlaylistItems;

  const hasMultiplePublishedExports = xmlExports?.filter(({status}) => status === "publishing")?.length > 1;

  // When GEMAGVLXMLLieferung is in import status, we additionally need the GEMAGVL4Lieferung.
  let observeGemaGVL4LieferungId = gemagvl4_lieferung;
  if (status !== -100) {
    observeGemaGVL4LieferungId = null;
  }
  useEntityObserver({type: 'gemagvl4_lieferung', id: observeGemaGVL4LieferungId});

  const getGEMAGVL4Lieferung = useSelector(getGEMAGVL4LieferungGetter);
  let {
    status: status4,
    // is_processing: isProcessing4,
    is_processing_queued: isProcessingQueued4,
  } = getGEMAGVL4Lieferung(gemagvl4_lieferung);

  const isImporting = (status === -100 && status4 === 100);

  if (expandedUuid !== undefined && expandedUuid === gemagvl4_lieferung) {
    expanded = true;
  }

  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const doDelete = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/delete/`,
    );
  };

  const doRedraft = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/redraft/`,
    );
  };

  const publish = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/publish/`,
    );
  };

  const resetToDraft = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/reset_to_draft/`,
    );
  };

  const resetToPublishing = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/reset_to_publishing/`,
    );
  };

  let downloadUrl = null;
  const downloadUrlCandidate = useSessionTokenUrl(`/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemaGvlXmlLieferungId}/blob/`);
  if (status === 200 || status === 307 || status === 50) {
    downloadUrl = downloadUrlCandidate;
  }

  const isCancelled = (status === 410);
  const isCancelRequested = (status === 306);

  let serverStatus = (
    processingProgress?.status_text ? (
      processingProgress?.status_text
    ) : isProcessingQueued ? (
      "Warte auf Verarbeitung..."
    ) : isProcessingStarted ? (
      "wird verarbeitet..."
    ) : status === 200 ? (
      "XML-Meldungen generiert."
    ) : status === 500 && !error_messages?.length ? (
      "Angaben unvollständig."
    ) : status === 501 ? (
      "Import fehlgeschlagen."
    ) : (status === 500 || isProcessingFailed) ? (
      "Ein Fehler ist aufgetreten."
    ) : status >= 100 && status < 200 ? (
      "XML-Meldungen werden generiert..."
    ) : status === -100 ? (
      "wird aufbereitet..."
    ) : isCancelled ? (
      "Lieferung storniert."
    ) : isCancelRequested ? (
      "Stornierung wird versucht."
    ) : status === 307 && isPreApprovalReport ? (
      "Freigegeben zur Überprüfung durch GEMA / GVL."
    ) : status === 307 && !isPreApprovalReport ? (
      "Freigegeben für GEMA / GVL."
    ) : null
  );

  let serverProgress = undefined;
  if (processingProgress?.total) {
    serverProgress = processingProgress?.progress / processingProgress?.total;
  }

  const getTodoTask = useSelector(getTodoTaskGetter);
  const hasLoadedAllTodoTasks = todoTasks?.map(getTodoTask).filter(({id}) => !id)?.length === 0;
  const hasCriticalTodoTasks = todoTasks?.map(getTodoTask).filter(
    ({message}) => message?.severity === 'error',
  )?.length > 0;

  useEntityObserver({
    type: 'todo_task',
    ids: expanded ? todoTasks : [],
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const [showDetails, setShowDetails] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [cancelRequest, setCancelRequest] = useState(null);
  const [coveredMonths, setCoveredMonths] = useState(null);

  if (isDeleted) {
    return (
      <Box mt={2} mb={2}>
        <Alert variant="filled" color="error" severity="success">
          Diese Lieferung wurde gelöscht.
        </Alert>
      </Box>
    );
  }

  const potentialDelayInfo = (
    <p>
      Sie müssen nicht warten! Die Verarbeitung erfolgt automatisch, auch wenn Sie diese Webseite schließen. Das
      Ergebnis ist dann abrufbar, wenn Sie sich das nächste Mal einloggen.
    </p>
  );

  let startDate;
  try {
    if (datum_von) {
      startDate = parseDate(datum_von);
    }
  } catch(e) {
    console.error(e);
  }

  let reportTimeSpan = datum_von && datum_bis || ausstrahlungen_count ? (
      (!isImporting && datum_von && isCollectiveReport && !collectiveReportCoveredMonths && !collectiveReportCoveredMonths) ? (
        (canChangeCollectiveReportCoveredMonths && isDetailPage && expanded) ? (
          <>
            Meldezeitraum festlegen
          </>
        ) : (
          <>
            {startDate ? (
              <>
                {dateFormat(startDate, "mmmm yyyy")} &ndash; unbekannt
              </>
            ) : (
              "Meldezeitraum unbekannt"
            )}
          </>
        )
      ) : (
        <NaturalDateRange
          startDate={datum_von}
          endDate={datum_bis}
          openRange={isImporting}
          warnUnusual={status >= 0 || status !== 307}
        />
      )
  ) : null;

  if (reportTimeSpan && canChangeCollectiveReportCoveredMonths && isDetailPage && expanded) {
    reportTimeSpan = (
      <Button
        color="inherit"
        size="small"
        onClick={evt => {
          setCoveredMonths({
            organization,
            id: gemaGvlXmlLieferungId,
            collective_report_covered_months: collectiveReportCoveredMonths,
          });
          evt.stopPropagation();
        }}
        endIcon={<Edit/>}
        sx={!collectiveReportCoveredMonths ? {} : {p: 0}}
      >
        {!collectiveReportCoveredMonths ? (
          reportTimeSpan
        ) : (
          <Typography className={classes.heading2} sx={{textTransform: 'initial'}}>
            {reportTimeSpan}
          </Typography>
        )}
      </Button>
    );

    if (!collectiveReportCoveredMonths && isDetailPage && expanded) {
      reportTimeSpan = (
        <Tooltip title="Diese Sammelmeldung enthält keine Angabe zur Länge des Meldezeitraums. Sie können den Meldezeitraum daher hierüber manuell festlegen.">
          {reportTimeSpan}
        </Tooltip>
      );
    }
  }

  return (
    <>
      {reportCaptionDedupeId !== previousReportCaptionDedupeId ? reportCaption : null}
      <Accordion expanded={expanded} onChange={expansible ? onChange : null} TransitionProps={{unmountOnExit: true}}
                 className={expanded ? classes.expandedAccordion : null} {...props}>
        <AccordionSummary
          expandIcon={expansible ? (<ExpandMore/>) : null}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Grid container style={{alignItems: 'center'}}>
            <Grid item container xs={6} style={{alignItems: 'center'}}>
              <div className={classes.icon}>
                {(status === 307) ? (
                  <Email color="action" fontSize="large"/>
                ) : isCancelled ? (
                  <Cancel color="action" fontSize="large"/>
                ) : isCancelRequested ? (
                  <Email color="action" fontSize="large"/>
                ) : !id ? (
                  <Skeleton>
                    <Drafts color="action" fontSize="large"/>
                  </Skeleton>
                ) : (
                  <Drafts color="action" fontSize="large"/>
                )}
              </div>
              <div className={classes.heading2}>
                <Typography className={classes.heading2}>
                  {!id && (
                    <Skeleton variant="text"><span>Monat Jahr</span></Skeleton>
                  )}
                  {reportTimeSpan}
                  {ausstrahlungen_count ? (
                    <Typography variant="inherit" className={classes.secondaryHeading2} component="span">
                      {isImporting ? '≥ ' : null}{ausstrahlungen_count} {ausstrahlungen_count === 1 ? (
                        onlyJingles ? "Jingle" : onlyMusic ? "Musik-Ausstrahlung" : "Ausstrahlung"
                      ) : (
                        onlyJingles ? "Jingles" : onlyMusic ? "Musik-Ausstrahlungen" : "Ausstrahlungen"
                      )}
                    </Typography>
                  ) : null}
                  {isPreApprovalReport ? (
                    <Typography variant="inherit" className={classes.secondaryHeading2} component="span">
                      <Tooltip
                        title={(
                          <>
                            Bevor die Verwertungsgesellschaften Produktivmeldungen im XML-Format annehmen, muss zunächst
                            eine Beispiel-Sendemeldung bereitgestellt werden.
                            Diese wird von GEMA / GVL technisch überprüft; im Erfolgsfall erhält Ihr Sendeunternehmen
                            die entsprechende Freigabe.
                          </>
                        )}
                      >
                        <Chip label="Testlieferung" size="small"/>
                      </Tooltip>
                    </Typography>
                  ) : null}
                </Typography>
                <br/>
                {!id && (
                  <ProgrammeChip id={null}/>
                )}
                {stations?.map((stationId, i) => (
                  <React.Fragment key={stationId || i}>
                    <ProgrammeChip id={stationId}/>
                    {' '}
                  </React.Fragment>
                ))}
              </div>
            </Grid>
            <Grid item container xs={6} style={{alignItems: 'center'}}>
              <div className={classes.icon}>
                {isCompleted && showPublish ? (
                  <AttachFile color="action" fontSize="large"/>
                ) : isCompleted ? (
                  <Check color="action" fontSize="large"/>
                ) : isFailed ? (
                  <Warning color="primary" fontSize="large"/>
                ) : isProcessing ? (
                  <CircularProgressWithLabel size="2rem" color="inherit" value={serverProgress}/>
                ) : isCancelled ? (
                  <Clear color="action" fontSize="large"/>
                ) : isCancelRequested ? (
                  <HourglassEmpty color="action" fontSize="large"/>
                ) : !id ? (
                  <Skeleton>
                    <HourglassEmpty color="action" fontSize="large"/>
                  </Skeleton>
                ) : !isDetailPage ? (
                  status === 50 ? (
                    <PublishedWithChanges color="action" fontSize="large"/>
                  ) : (
                    <HourglassEmpty color="action" fontSize="large"/>
                  )
                ) : null}
              </div>
              <div className={classes.heading2}>
                <Typography variant="inherit" className={classes.secondaryHeading} component="div">
                  {!id && (
                    <Skeleton variant="text"><span>Status-Text</span></Skeleton>
                  )}
                  {(status === 0 || status === 50 || (status === 200 && showPublish)) && !isDetailPage ? (
                    <>
                      <HrefChip
                        label={status === 50 ? "Lieferung nachbearbeiten" : "Jetzt prüfen und bearbeiten"}
                        variant="outlined"
                        clickable
                        color="primary"
                        href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
                      />
                    </>
                  ) : serverStatus}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '100%'}}>
            {status === -100 ? (
              (status4 === 100 ? (
                isProcessingQueued4 ? (
                  <Box mb={2}>
                    <Alert severity="info">
                      <AlertTitle>Warte auf Import...</AlertTitle>
                      <p>Wir starten den Import Ihrer Sendemeldungen, sobald alle vorherigen Aufgaben abgeschlossen
                        sind.</p>
                      {potentialDelayInfo}
                    </Alert>
                  </Box>
                ) : (
                  <Box mb={2}>
                    <Alert severity="info">
                      <AlertTitle>Import läuft</AlertTitle>
                      <p>Wir importieren Ihre Sendemeldungen gerade aus Ihrer hochgeladenen Datei.</p>
                      {potentialDelayInfo}
                    </Alert>
                  </Box>
                )
              ) : (
                isProcessingQueued4 ? (
                  <Box mb={2}>
                    <Alert severity="info">
                      <AlertTitle>Warte auf Weiterverarbeitung...</AlertTitle>
                      <p>
                        Wir bereiten Ihre Sendemeldungen in Kürze für die weitere Bearbeitung vor.
                        Dies geschieht, sobald alle vorherigen Aufgaben abgeschlossen sind.
                      </p>
                      {potentialDelayInfo}
                    </Alert>
                  </Box>
                ) : (
                  <Box mb={2}>
                    <Alert severity="info">
                      <AlertTitle>Aufbereitung läuft</AlertTitle>
                      <p>
                        Wir führen Ihre Sendemeldungen gerade mit Ihrer Musikdatenbank zusammen und bereiten sie für die
                        weitere Bearbeitung vor.
                      </p>
                      {potentialDelayInfo}
                    </Alert>
                  </Box>
                )
              ))
            ) : null}

            {status >= 100 && status < 200 ? (
              <Box mb={2}>
                <Alert severity="info">
                  <AlertTitle>Verarbeitung läuft</AlertTitle>
                  Wir generieren gerade Ihre XML-Meldungen. Bitte haben Sie einen Augenblick Geduld.
                </Alert>
              </Box>
            ) : null}

            {status === 1 ? (
              <Box mb={2}>
                <Alert severity="info">
                  <AlertTitle>In Löschung</AlertTitle>
                  Ihre Sendemeldungen werden wunschgemäß gelöscht, sodass Sie die GEMAGVL4-Datei &ndash; ggf. mit
                  Korrekturen &ndash; im Anschluss erneut hochladen können.
                </Alert>
              </Box>
            ) : null}

            {status === 200 ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="info">
                    <AlertTitle>XML-Meldungen erfolgreich generiert</AlertTitle>
                    Ihre XML-Meldungen stehen jetzt zum Download bereit.
                    Sie können diese nun an die Verwertungsgesellschaften übermitteln.
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {status === 0 ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  isVisible={expanded}
                >
                  {!hasLoadedAllTodoTasks ? (
                    <Skeleton>
                      <Alert variant="filled" severity="info">
                        <AlertTitle>Ihre XML-Meldungen sind vorbereitet</AlertTitle>
                        Wir haben Ihre XML-Meldungen bereits vorbereitet.
                        Sie können diese nun prüfen, bearbeiten und übermitteln.
                      </Alert>
                    </Skeleton>
                  ) : !hasCriticalTodoTasks ? (
                    <Alert variant="filled" severity="info">
                      <AlertTitle>Ihre XML-Meldungen sind vorbereitet</AlertTitle>
                      Wir haben Ihre XML-Meldungen bereits vorbereitet.
                      Sie können diese nun prüfen, bearbeiten und übermitteln.
                    </Alert>
                  ) : (
                    <Alert variant="filled" severity="warning">
                      <AlertTitle>Ihre XML-Meldungen sind vorbereitet</AlertTitle>
                      <p>
                        Wir haben Ihre XML-Meldungen bereits vorbereitet. Es liegen jedoch Hinweise vor, die zwingend bearbeitet werden müssen.
                      </p>
                      Nach Prüfung und Bearbeitung können Sie Ihre Sendemeldung übermitteln.
                    </Alert>
                  )}
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {status === 50 ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="info">
                    <AlertTitle>Überarbeitungsmodus</AlertTitle>
                    <Box>Diese Sendemeldung wurde von Ihnen in der Vergangenheit bereits an die Verwertungsgesellschaften übertragen.</Box>
                    Sie haben jetzt die Möglichkeit, nachträgliche Änderungen vorzunehmen.
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {/*{status === 50 ? (*/}
            {/*  <Box mb={2}>*/}
            {/*    <GEMAGVLXMLStats*/}
            {/*      id={id}*/}
            {/*      forceChildren*/}
            {/*    >*/}
            {/*      <Alert variant="filled" severity="info">*/}
            {/*        <AlertTitle>Es liegen Rückmeldungen vor</AlertTitle>*/}
            {/*        GEMA/GVL haben Rückmeldungen zu Ihrer Lieferung bereitgestellt.*/}
            {/*        Sie können diese nun prüfen und bearbeiten.*/}
            {/*        <Box mt={2}>*/}
            {/*          <Alert variant="filled" severity="warning">*/}
            {/*            <AlertTitle>Hinweis</AlertTitle>*/}
            {/*            Wir erarbeiten derzeit gemeinsam mit GEMA/GVL einen Workflow für die Verarbeitung von*/}
            {/*            Rückmeldungen.*/}
            {/*            Möglichkeiten zur Übermittlung von Korrekturmeldungen stehen Ihnen demnächst zur Verfügung.*/}
            {/*          </Alert>*/}
            {/*        </Box>*/}
            {/*      </Alert>*/}
            {/*    </GEMAGVLXMLStats>*/}
            {/*  </Box>*/}
            {/*) : null}*/}

            {isCancelRequested ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="warning">
                    <AlertTitle>Stornierung wird versucht.</AlertTitle>
                    Diese Lieferung wurde bereits an GEMA/GVL übertragen.
                    Wir versuchen derzeit, eine nachträgliche Stornierung bei den Verwertungsgesellschaften zu erwirken.
                    Dies kann längere Zeit in Anspruch nehmen.
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {isCancelled ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="warning">
                    <AlertTitle>Diese Lieferung wurde storniert.</AlertTitle>
                    Diese Lieferung wurde erfolgreich storniert und daher nicht von den Verwertungsgesellschaften
                    verarbeitet.
                    Sie kann gelöscht werden.
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {status === 307 && isPreApprovalReport ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="info">
                    <AlertTitle>Ihre XML-Meldungen wurden zur Prüfung durch GEMA und GVL freigegeben.</AlertTitle>
                    Die Verwertungsgesellschaften haben nun Gelegenheit, Ihre XML-Meldungen zu überprüfen.
                    Wir informieren Sie, sobald eine Rückmeldung vorliegt.
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {status === 307 && !isPreApprovalReport ? (
              <Box mb={2}>
                <GEMAGVLXMLStats
                  id={id}
                  forceChildren
                  isVisible={expanded}
                >
                  <Alert variant="filled" severity="info">
                    <AlertTitle>Ihre XML-Meldungen wurden für GEMA und GVL freigegeben.</AlertTitle>
                    <p>Die Verwertungsgesellschaften können Ihre XML-Meldungen nun weiterverarbeiten.</p>
                    <p>Sollte es Rückfragen geben, werden wir Sie hierüber per E-Mail informieren.</p>
                    {isLastExportSkipped && (
                      <p>Hinweis: Bitte beachten Sie, dass keine neue XML-Meldung generiert wurde, da sich gegenüber Ihrer zuvor übermittelten XML-Meldung keine Änderungen ergeben haben.</p>
                    )}
                  </Alert>
                </GEMAGVLXMLStats>
              </Box>
            ) : null}

            {status === 500 && !error_messages?.length ? (
              <Box mb={2}>
                <Alert variant="filled" severity="warning">
                  <AlertTitle>Bitte ergänzen Sie die fehlenden Angaben.</AlertTitle>
                  Die Generierung der XML-Meldung konnte aufgrund fehlender Angaben nicht spezifikationskonform
                  durchgeführt werden.
                  Bitte ergänzen Sie die ggf. fehlenden Angaben und starten Sie die Generierung anschließend erneut.
                </Alert>
              </Box>
            ) : null}

            {error_messages?.map(({title, message}, i) => (
              <Box mb={2} key={i}>
                <Alert variant="filled" severity="error">
                  <AlertTitle>{title}</AlertTitle>
                  {message}
                </Alert>
              </Box>
            ))}
            {warning_messages?.map(({title, message}, i) => (
              <Box mb={2} key={i}>
                <Alert variant="filled" severity="warning">
                  <AlertTitle>{title}</AlertTitle>
                  {message}
                </Alert>
              </Box>
            ))}

            <Collapse in={!!showDetails}>
              <Box mt={4}>
                <Typography component="h6">
                  Verlauf
                  {' '}
                  <Tooltip title="Details verbergen">
                    <IconButton
                      onClick={() => setShowDetails(false)}
                      size="small"
                    >
                      <Close/>
                    </IconButton>
                  </Tooltip>
                </Typography>

                <GEMAGVLXMLHistory id={id} isVisible={showDetails}/>
              </Box>
            </Collapse>
          </div>
        </AccordionDetails>
        <Collapse
          in={!!(!showDetails || showDelete || showPublish || primaryActions || downloadUrl || !isDetailPage || ((status === 0 || (status === 500)) && !hasCriticalTodoTasks && hasLoadedAllTodoTasks))}>
          <Divider/>
          <AccordionActions>
            {(showDelete || showRequestCancel || showRedraft) ? (
              <>
                <Tooltip title="weitere Aktionen">
                  <IconButton
                    aria-label="more actions"
                    aria-controls={`${id}-more-menu`}
                    aria-haspopup="true"
                    onClick={openMenu}
                    size="large">
                    <MoreHoriz/>
                  </IconButton>
                </Tooltip>
                <Menu
                  id={`${id}-more-menu`}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={menuOpen}
                  onClose={closeMenu}
                >
                  {showRedraft ? (
                    <MenuItem onClick={() => confirm("Soll der Bearbeitungsmodus für diese bereits übermittelte Lieferung aktiviert werden? Sie können dann Änderungen durchführen und diese anschließend an die Verwertungsgesellschaften ergänzend übermitteln.") && (() => {
                      doRedraft({
                        organization,
                        gemagvlxml_lieferung: gemaGvlXmlLieferungId
                      });
                      closeMenu();
                    })()}>
                      <ListItemIcon>
                        <Edit fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        Lieferung nachbearbeiten
                      </ListItemText>
                    </MenuItem>
                  ) : null}
                  {showRedraft && (showRequestCancel || showDelete) ? (
                    <Divider/>
                  ) : null}
                  {showRequestCancel ? (
                    <MenuItem
                      onClick={() => {
                        setCancelRequest({
                          organization,
                          gemagvlxml_lieferung: gemaGvlXmlLieferungId,
                        });
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <Cancel fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        {hasMultiplePublishedExports ? "Gesamte Lieferung stornieren" : "Lieferung stornieren"}
                      </ListItemText>
                    </MenuItem>
                  ) : null}
                  {showDelete ? (
                    <MenuItem onClick={() => confirm("Lieferung wirklich löschen?") && doDelete({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}>
                      <ListItemIcon>
                        <Delete fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        Lieferung löschen
                      </ListItemText>
                    </MenuItem>
                  ) : null}
                </Menu>
              </>
            ) : null}
            {!isDetailPage ? (
              <Tooltip title="Sendemeldungen anzeigen">
                <HrefIconButton
                  href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
                >
                  <Search/>
                </HrefIconButton>
              </Tooltip>
            ) : (!showDetails) ? (
              <Tooltip title="Details anzeigen">
                <IconButton onClick={() => setShowDetails(true)} size="large">
                  <InfoOutlined/>
                </IconButton>
              </Tooltip>
            ) : null}
            {downloadUrl ? (
              <Tooltip title="XML-Meldungen herunterladen">
                <IconButton href={downloadUrl} size="large">
                  <GetApp/>
                </IconButton>
              </Tooltip>
            ) : null}
            <div style={{flex: 1}}/>
            {primaryActions ? primaryActions : (
              <>
                {showResetToDraft ? (
                  <Tooltip
                    title="Hiermit wird Ihre zuletzt exportierte XML-Meldung gelöscht und die Lieferung in den Bearbeitungsmodus zurückgesetzt."
                  >
                    <Button
                      onClick={() => confirm("Lieferung in Bearbeitungsmodus zurücksetzen? Ihre bereits exportierte (jedoch noch nicht übermittelte) XML-Meldung wird hierbei gelöscht.") && resetToDraft({
                        organization,
                        gemagvlxml_lieferung: gemaGvlXmlLieferungId
                      })}
                      startIcon={<Undo/>}>
                      Meldung bearbeiten
                    </Button>
                  </Tooltip>
                ) : null}

                {status === 200 && !isPreApprovalReport && showPublish ? (
                  <Tooltip
                    title="Hiermit werden die XML-Meldungen an GEMA und GVL übermittelt."
                  >
                    <Button
                      onClick={() => confirm("Lieferung jetzt an GEMA und GVL übermitteln?") && publish({
                        organization,
                        gemagvlxml_lieferung: gemaGvlXmlLieferungId
                      })}
                      color="primary"
                      startIcon={<Backup/>}
                    >
                      an GEMA/GVL übermitteln
                    </Button>
                  </Tooltip>
                ) : (status === 200 && isPreApprovalReport && showPublish) ? (
                  <Tooltip
                    title="Hiermit werden die XML-Meldungen zur Prüfung an GEMA und GVL übermittelt."
                  >
                    <Button
                      onClick={() => confirm("Lieferung jetzt als Test-Lieferung zur Überprüfung durch GEMA / GVL freigeben?") && publish({
                        organization,
                        gemagvlxml_lieferung: gemaGvlXmlLieferungId
                      })}
                      color="primary"
                      startIcon={<Backup/>}
                    >
                      zur Überprüfung an GEMA/GVL übermitteln
                    </Button>
                  </Tooltip>
                ) : (isPreApprovalReport && status === 200) ? (
                  <Tooltip
                    title="Zum Zwecke der Freigabe soll nur eine XML-Meldung je Sendeunternehmen an GEMA / GVL übermittelt werden."
                  >
                    <div>
                      <Button
                        color="primary"
                        startIcon={<Backup/>}
                        disabled
                      >
                        zur Überprüfung an GEMA/GVL übermitteln
                      </Button>
                    </div>
                  </Tooltip>
                ) : null}

                {(status >= 0 || status === -100) && (status !== 307) ? (
                  !isDetailPage ? (
                    <HrefButton
                      color="primary"
                      href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
                    >
                      Prüfen &amp; bearbeiten
                    </HrefButton>
                  ) : (status === 0 || status === 50 || (status === 500)) ? (
                    <>
                      {showResetToPublishing && !hasCriticalTodoTasks && hasLoadedAllTodoTasks ? (
                        <Tooltip
                          title="Hiermit wird die Bearbeitung der Sendemeldung beendet, ohne Änderungen an die Verwertungsgesellschaften zu übertragen."
                        >
                          <Button
                            onClick={() => confirm("Bearbeitungsmodus beenden? Ihre Änderungen werden nicht übermittelt, bleiben aber gespeichert.") && resetToPublishing({
                              organization,
                              gemagvlxml_lieferung: gemaGvlXmlLieferungId
                            })}
                            color="inherit"
                            startIcon={<Undo/>}
                          >
                            Bearbeitung abbrechen
                          </Button>
                        </Tooltip>
                      ) : null}
                      <OnlyIfPermissions perm_write_reports>
                        {hasCriticalTodoTasks ? (
                          <Tooltip
                            title="Es liegen Hinweise vor, die vor Übermittlung der Sendemeldung zwingend bearbeitet werden müssen."
                          >
                            <Box>
                              <GemaGVLXMLGenerateButton id={id} disabled/>
                            </Box>
                          </Tooltip>
                        ) : !hasLoadedAllTodoTasks ? (
                          null
                        ) : (
                          <GemaGVLXMLGenerateButton id={id}/>
                        )}
                      </OnlyIfPermissions>
                    </>
                  ) : (isCancelled && showDelete) ? (
                    <OnlyIfPermissions perm_write_reports>
                      <Button
                        color="primary"
                        startIcon={<Delete/>}
                        onClick={() => confirm("Lieferung wirklich löschen?") && doDelete({
                          organization,
                          gemagvlxml_lieferung: gemaGvlXmlLieferungId
                        })}
                      >
                        Lieferung löschen
                      </Button>
                    </OnlyIfPermissions>
                  ) : (
                    <></>
                  )
                ) : null}
              </>
            )}
          </AccordionActions>
        </Collapse>

        {/*TODO: Delayed removal of dialog for fadeout transition.*/}
        {cancelRequest ? (
          <ReportCancelRequestFormDialog
            data={cancelRequest}
            onClose={() => setCancelRequest(null)}
          />
        ) : null}

        {coveredMonths ? (
          <CollectiveReportCoveredMonthsFormDialog
            data={coveredMonths}
            onClose={() => setCoveredMonths(null)}
            autoFocusField="collective_report_covered_months"
          />
        ) : null}
      </Accordion>
    </>
  );
}
