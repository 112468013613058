import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";
import {musicMetaDataFilterOptions} from "src/components/entities/musicwork/components/MusicFilter";

export default function PlaylistFilter({extraOptions, ...props}) {
  const options = useMemo(() => {
    if (!extraOptions || extraOptions.length === 0) {
      return [...playlistFilterOptions];
    }

    // Merge options, giving precedence to extraOptions when ids overlap.
    const ids = new Set(extraOptions.map(({id}) => id));
    return [
      ...extraOptions,
      ...playlistFilterOptions.filter(({id}) => !ids.has(id)),
    ];
  }, [extraOptions, playlistFilterOptions]);

  return (
    <FancyFilterField
      options={options}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Ausstrahlungen suchen..."
      {...props}
    />
  );
}

const playlistFilterOptions = [
  {
    category: "Status",
    id: 'quality',
    label: 'Hinweise',
    choice: 'has_todo_tasks',
    chipLabel: ({id, choice}) => ({
      'has_todo_tasks': "Einträge mit Hinweisen",
      'no_todo_tasks': "Einträge ohne Hinweise",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'has_todo_tasks': "liegen vor",
      'no_todo_tasks': "liegen nicht vor",
    }[choice]),
    choices: ['has_todo_tasks', 'no_todo_tasks'],
  },
  ...musicMetaDataFilterOptions,
];
