import {Chip, Typography} from "@mui/material";
import React from "react";
import _ from "lodash";

export function formatLabelCode(value) {
  const intValue = _.toNumber(value);
  return !intValue ? (
    value
  ) : intValue < 100000 ? (
    ('0000' + intValue).slice(-5)
  ) : (
    ('0000000' + intValue).slice(-8)
  );
}

export default function LabelCode({
  value,
  ...props
}) {
  return (value ? (
    <Typography variant="inherit" component="span">
      <Chip
        label={formatLabelCode(value)}
        {...props}
      />
    </Typography>
  ) : null);
}
