import {useSelector} from "react-redux";
import {getGVLProductGetter} from "src/features/entity";
import {Box, Paper, Typography} from "@mui/material";
import React from "react";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import GVLProductTitle from "src/components/entities/musicwork/components/GVLProductTitle";

export default function GVLProduktSummary2({
  id,
}) {
  useEntityObserver({
    type: 'gvl_product',
    id
  });

  const {
    titel,
    interpret,
    identifiers,
    genre,
    dauer,
  } = useSelector(getGVLProductGetter)(id);

  return <Paper elevation={3}>
    <Box m={1}>
      <Typography gutterBottom>
        GVL-Produkt {id}
      </Typography>
      {titel?.map((el, i) => i === 0 ? (
        <Typography key={i} fontWeight="bold">
          <GVLProductTitle gvlProductTitle={el}/>
        </Typography>
      ) : null)}
      <Typography>
        {interpret}
      </Typography>
    </Box>
  </Paper>;
}
