import React, {useState} from "react";
import {AlertTitle, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {DashboardLayout} from "src/components/layout";
import {useSelector} from "react-redux";
import Alert from '@mui/material/Alert';
import {getStationGetter} from "src/features/entity";
import ProgrammeRow from "src/components/entities/programme/ProgrammeRow";
import CircularProgress from "@mui/material/CircularProgress";
import {ProgrammeForm} from "src/components/entities/programme/ProgrammeForm";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import StationFilter from "src/components/entities/programme/StationFilter";
import PageToolbar from "src/components/layout/components/PageToolbar";

export default function ProgrammeTable({organizationId}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    organizationId,
    listingIdSuffix: 'stations',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/stations/',
    entityType: 'station',
  });

  const getStation = useSelector(getStationGetter);

  const [editStation, setEditStation] = useState(null);

  return (
    <DashboardLayout
      titlePrefix="Programme"
      selectedPage="programmes"
      drawerContentProps={{expandSettings: true}}
    >
      <PageToolbar
        title="Programme"
        isAtTop
        searchField={(
          <StationFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            <AlertTitle>Es wurden noch keine Programme angelegt.</AlertTitle>
            Ihre Programme werden automatisch eingerichtet, sobald Sie GEMAGVL4-Dateien importieren.
            <Box mt={1}>
              <HrefButton
                href="/dashboard/import/"
                color="primary"
                variant="contained"
              >
                GEMAGVL4-Sendemeldungen hochladen
              </HrefButton>
            </Box>
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Programme werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            Diese Ansicht enthält keine Einträge.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="programmes table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Programmkennzeichen</TableCell>
                <TableCell>Status</TableCell>
                {/*<TableCell>Jingles</TableCell>*/}
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((stationId, i) => (
                <ProgrammeRow
                  key={stationId || i}
                  stationId={stationId}
                  editStation={(stationId) => setEditStation(getStation(stationId))}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editStation ? (
        <ProgrammeForm
          data={editStation}
          onClose={() => setEditStation(null)}
        />
      ) : null}
    </DashboardLayout>
  );
}
