import React from "react";
import {IconButton, Menu, Tooltip} from "@mui/material";
import {MoreHoriz} from "@mui/icons-material";

export default function SimpleMoreMenu({
  id = undefined,
  title="weitere Aktionen",
  size="large",
  children
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          aria-label="more actions"
          aria-controls={id}
          aria-haspopup="true"
          onClick={openMenu}
          size={size}
        >
          <MoreHoriz/>
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={closeMenu}
        onClick={(event) => {
          if (event.target !== anchorEl) {
            closeMenu();
          }
        }}
      >
        {children}
      </Menu>
    </>
  );
}
