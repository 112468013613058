import UploadedFileChip from "src/components/entities/upload/UploadedFileChip";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLExportGetter, getGEMAGVLXMLLieferungGetter, getNotificationGetter} from "src/features/entity";
import React from "react";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useEntityApi, useEntityDeleter, useEntityObserver} from "src/features/entity/entity-hooks";
import {NOTIFICATION} from "src/api/api-schemas";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Tooltip
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {Beenhere, ChatBubble, Delete, Error, MarkChatUnread, Warning} from "@mui/icons-material";
import NaturalTime from "src/packages/natural-time-view";
import CircularProgress from "@mui/material/CircularProgress";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {DocumentSender} from "src/components/entities/document/DocumentRow";
import SimpleMoreMenu from "src/packages/SimpleMoreMenu";

function UploadFailedMessage({actor}) {
  return (<>
    Die hochgeladene Datei <UploadedFileChip id={actor?.id}/> enthielt keine Sendemeldungen.
  </>);
}

function GEMAGVL4ImportedMessage({target}) {
  return (<>
    Es wurden Sendemeldungen aus <UploadedFileChip id={target?.id}/> importiert.
  </>);
}

function GEMAGVL4ImportFailedMessage({target}) {
  return (<>
    GEMAGVL4-Import aus <UploadedFileChip id={target?.id}/> fehlgeschlagen.
  </>);
}

function OrganizationApprovedMessage({}) {
  return (<>
    Freigabe zur Übermittlung von Produktivdaten erhalten.
  </>);
}

function XMLSubmittedToVWG({vwg, actor}) {
  useEntityObserver({type: 'gemagvlxml_export', id: actor?.id});

  const getGEMAGVLXMLExport = useSelector(getGEMAGVLXMLExportGetter);
  const {
    gemagvlxml_lieferung,
  } = getGEMAGVLXMLExport(actor?.id);

  useEntityObserver({type: 'gemagvlxml_lieferung', id: gemagvlxml_lieferung});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    datum_von,
    datum_bis,
    stations,
  } = getGEMAGVLXMLLieferung(gemagvlxml_lieferung);

  return (<>
    Ihre Lieferung
    {stations?.length > 0 ? (
      <>
        {' '}
        für
        {' '}
        {stations?.map((stationId, i) => (
          <React.Fragment key={stationId || i}>
            <ProgrammeChip id={stationId} size="small"/>
            {' '}
          </React.Fragment>
        ))}
      </>
    ) : null}
    {datum_von && datum_bis ? (
      <>
        {' '}
        für
        {' '}
        <NaturalDateRange
          startDate={datum_von}
          endDate={datum_bis}
        />
      </>
    ) : null}
    {' '}
    wurde erfolgreich an die {vwg} übermittelt.
  </>);
}

function XMLSubmittedToGVL(props) {
  return <XMLSubmittedToVWG vwg="GVL" {...props} />;
}

function XMLSubmittedToGEMA(props) {
  return <XMLSubmittedToVWG vwg="GEMA" {...props} />;
}

function DocumentReceived({actor}) {
  return (<>
    Sie haben eine neue Nachricht erhalten von <DocumentSender documentId={actor?.id}/>.
  </>);
}

function SuitableLink({
  value,
  ...props
}) {
  const {
    type,
    id
  } = value || {};

  return (type && id) ? (
    <>
      {type === 'gemagvlxml_lieferung' ? (
        <OnlyIfPermissions perm_read_reports>
          <HrefButton
            href={`/dashboard/reports/${id}/`}
            variant="outlined"
            color="primary"
            // className={classes.button}
            size="small"
            sx={{mt: 1}}
            {...props}
          >
            Sendemeldungen anzeigen
          </HrefButton>
        </OnlyIfPermissions>
      ) : type === 'document' ? (
        <OnlyIfPermissions perm_read_documents>
          <HrefButton
            href={`/dashboard/documents/inbox/`}
            variant="outlined"
            color="primary"
            // className={classes.button}
            size="small"
            sx={{mt: 1}}
            {...props}
          >
            Posteingang anzeigen
          </HrefButton>
        </OnlyIfPermissions>
      ) : null}
    </>
  ) : null;
}

const NOTIFICATION_MESSAGE_TYPES = {
  upload_failed: UploadFailedMessage,
  gemagvl4_imported: GEMAGVL4ImportedMessage,
  gemagvl4_import_failed: GEMAGVL4ImportFailedMessage,
  organization_approved_by_gemagvl: OrganizationApprovedMessage,
  xml_submitted_to_gvl: XMLSubmittedToGVL,
  xml_submitted_to_gema: XMLSubmittedToGEMA,
  document_received: DocumentReceived,
};

export default function NotificationRow({
  id,
  organizationId
}) {
  useEntityObserver({
    type: 'notification',
    id
  });

  const notificationData = useSelector(getNotificationGetter)(id);

  const {
    level,
    verb,
    description,
    timestamp,
    unread,
    isDeleted,
    actor,
    target,
    action_object,
  } = notificationData;

  useEntityObserver(actor);
  useEntityObserver(target);
  useEntityObserver(action_object);

  const entityApi = useEntityApi(NOTIFICATION);

  const markRead = async ({
    organizationId,
    id
  }) => {
    await entityApi.delete(
      `/api/sendemeldung/organizations/${organizationId}/notifications/${id}/unread/`,
    );
  };

  const markUnread = async ({
    organizationId,
    id
  }) => {
    await entityApi.put(
      `/api/sendemeldung/organizations/${organizationId}/notifications/${id}/unread/`,
    );
  };

  const {
    deleteEntity,
    deletingUuids
  } = useEntityDeleter({
    entityType: 'notifications',
    baseUrl: `/api/sendemeldung/organizations/${organizationId}/notifications/`,
  });

  const NotificationMessage = NOTIFICATION_MESSAGE_TYPES[verb];

  const notificationMessage = NotificationMessage ? (
    <NotificationMessage {...notificationData}/>
  ) : verb;

  if (isDeleted) {
    return (
      <Box mt={2} mb={2}>
        <Alert variant="filled" color="error" severity="success">
          Diese Benachrichtigung wurde gelöscht.
        </Alert>
      </Box>
    );
  }

  const markThisRead = () => markRead({
    organizationId,
    id
  });

  const markThisReadIfNecessary = unread ? markThisRead : undefined;

  const actorLink = (
    <SuitableLink value={actor} onClick={markThisReadIfNecessary}/>
  );
  const targetLink = (
    <SuitableLink value={target} onClick={markThisReadIfNecessary}/>
  );
  const actionObjectLink = (
    <SuitableLink value={action_object} onClick={markThisReadIfNecessary}/>
  );

  // TODO: Factor out organization_approved_by_gemagvl handling.

  return <>
    <ListItem>
      <ListItemIcon>
        <Badge color="primary" variant="dot" invisible={!unread}>
          {level === 'error' ? (
            <Error/>
          ) : level === 'warning' ? (
            <Warning/>
          ) : (
            <ChatBubble/>
          )}
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            {unread ? (
              <div style={{fontWeight: '600'}}>{notificationMessage}</div>
            ) : notificationMessage}
          </>
        }
        secondary={
          <>
            <NaturalTime date={timestamp}/>
            {/*{actor?.type === 'gemagvlxml_lieferung' ? (*/}
            {/*  <>*/}
            {/*    <GemaGVLXMLRow*/}
            {/*      id={actor?.id}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*) : null}*/}
            {verb === 'organization_approved_by_gemagvl' ? (
              <>
                <Box component="span" sx={{display: 'block'}}>
                  <p>
                    Die Verwertungsgesellschaften haben Ihre bereitgestellte Beispiel-Sendemeldung erfolgreich
                    geprüft.
                  </p>
                  <p>
                    Ab sofort können Sie Ihre offiziellen Sendemeldungen über Sendemeldung.de übermitteln!
                  </p>

                  Den Status Ihrer Sendemeldungen aus der Testphase haben wir zurückgesetzt. Sollten Sie bereits
                  Sendemeldungen für zu meldende Zeiträume für Ihre Tests verwendet haben, können Sie diese für Ihre
                  Produktivmeldung wiederverwenden.
                </Box>
                <Box component="span" sx={{display: 'block'}}>
                  <HrefButton
                    href={`/dashboard/reports/`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{mt: 1}}
                    onClick={markThisReadIfNecessary}
                  >
                    zu Ihren Sendemeldungen
                  </HrefButton>
                </Box>
              </>
            ) : null}
            {actorLink || targetLink || actionObjectLink ? (
              <Box component="span" sx={{display: 'block'}}>
                {actorLink}
                {targetLink}
                {actionObjectLink}
              </Box>
            ) : null}
          </>
        }
      />
      <ListItemSecondaryAction>
        {unread ? null : (
          <>
            {deletingUuids?.has(id) ? (
              <CircularProgress size="1.5rem"/>
            ) : (
              <SimpleMoreMenu id={id}>
                <MenuItem
                  onClick={() => markUnread({
                    id,
                    organizationId
                  })}
                >
                  <ListItemIcon>
                    <MarkChatUnread fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    als ungelesen markieren
                  </ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem
                  onClick={() => confirm("Benachrichtigung löschen?") && deleteEntity(id)}
                >
                  <ListItemIcon>
                    <Delete fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Benachrichtigung löschen
                  </ListItemText>
                </MenuItem>
              </SimpleMoreMenu>
            )}
          </>
        )}
        {unread ? (
          <Tooltip title="als gelesen markieren">
            <IconButton
              aria-label="markRead"
              onClick={markThisRead}
              size="large"
              sx={{ml: 2}}
            >
              <Beenhere/>
            </IconButton>
          </Tooltip>
        ) : null}
      </ListItemSecondaryAction>
    </ListItem>
  </>;
}
