import {ERSCHIENENE_TONAUFNAHME_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {ERSCHIENENE_TONAUFNAHME} from "src/api/api-schemas";
import LabelSuggestionsField from "src/components/entities/musicwork/components/LabelSuggestionsField";
import LabelCodeTextField from "src/components/entities/musicwork/components/LabelCodeTextField";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

export function ErschieneneTonaufnahmeForm({
  data,
  onClose,
  ...props
}) {
  const entityApi = useEntityApi(ERSCHIENENE_TONAUFNAHME);

  const {id} = data;

  const {id: organizationId} = useSelectedOrganization();

  const saveWork = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organizationId}/erschienene_tonaufnahmen/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      id={'edit-erschienene_tonaufnahme'}
      entityType="erschienene_tonaufnahmen"
      baseUrl={`/api/sendemeldung/organizations/${organizationId}/erschienene_tonaufnahmen/`}
      title={id ? "Erschienene Tonaufnahme bearbeiten" : "Erschienene Tonaufnahme anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveWork}
      allowDelete
      deleteConfirmation="Tonaufnahme wirklich löschen?"
      deleteCaption="Tonaufnahme löschen"
      schema={ERSCHIENENE_TONAUFNAHME_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="deklaration_tonaufnahme_knz"/>
          </Grid>
          <Grid item xs={4}>
            <YupField name="label_code" Component={LabelSuggestionsField} TextFieldComponent={LabelCodeTextField}/>
          </Grid>
          <Grid item xs={8}>
            <YupField name="label" Component={LabelSuggestionsField}/>
          </Grid>
          <Grid item xs={7}>
            <YupField name="album_titel"/>
          </Grid>
          <Grid item xs={5}>
            <YupField name="voe_datum"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="sonstige_info"/>
          </Grid>
        </Grid>
      </DialogContent>
    </EntityFormDialog>
  );
}
