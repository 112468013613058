import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {CHANGE_EMAIL_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {CONTACT} from "src/api/api-schemas";

function ChangeEmailFormDialogContent({}) {
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField name="email"/>
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function ChangeEmailForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(CONTACT);

  const saveContact = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/contacts/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'change-email'}
      title={"E-Mail-Adresse ändern"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveContact}
      schema={CHANGE_EMAIL_SCHEMA}
      {...props}
    >
      <ChangeEmailFormDialogContent
        data={data}
      />
    </SimpleYupFormDialog>
  );
}
