import {Chip} from "@mui/material";
import React from "react";

export const GEMA_IDENTIFIER_TYPES = {
  ISRC: "ISRC",
  EAN: "EAN",
  UPC: "UPC",
  KAT: "Katalognummer",
  ISWC: "ISWC",
};

export function GEMAWerkIdentifierType({type}) {
  let description = GEMA_IDENTIFIER_TYPES[type];
  if (description === undefined) {
    return null;
  }

  return (
    description
  );
}

export default function GEMAWerkIdentifier({
  gemaWorkIdentifier,
  showType
}) {
  const {
    type,
    value
  } = gemaWorkIdentifier || {};

  const typeBadge = showType ? (
    <Chip label={<GEMAWerkIdentifierType type={type}/>} size="small" component="span"/>
  ) : null;

  return (
    <>
      {value}
      {typeBadge ? (
        <>
          {' '}
          {typeBadge}
        </>
      ) : null}
    </>
  );
}
