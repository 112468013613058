import {formatDate, parseDate} from "src/packages/date-utils";
import dateFormat from "dateformat";
import {addDays, formatISO, parseISO} from "date-fns";
import {Tooltip} from "@mui/material";
import {Warning} from "@mui/icons-material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import _ from 'lodash';
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";

export default function NaturalHourGranularityDateTimeRange({startDate, endDate, startDateTime, endDateTime, ...props}) {
  let formattedDateTimeRange;

  if (!startDateTime && !endDateTime) {
    formattedDateTimeRange = 'unbekannter Zeitraum';
  } else {
    const startDT = parseISO(startDateTime);
    const endDT = parseISO(endDateTime);

    if (!startDate) {
      startDate = formatDate(startDT);
    }
    if (!endDate) {
      endDate = formatDate(endDT);
    }

    const startHour = startDT.getHours();
    let endHour = endDT.getHours();

    if (endDate === startDate && endHour === 0) {
      endHour = 24;
    } else if (endDate > startDate && endHour === 0) {
      const endDateMinus1Day = addDays(endDT, -1);
      endDate = formatDate(endDateMinus1Day);
      endHour = 24;
    }

    if (startDate === endDate) {
      formattedDateTimeRange = (
        <>
          <NaturalDateRange
            startDate={startDate}
            endDate={endDate}
            {...props}
          />
          {' '}
          zwischen
          {' '}
          {startHour} Uhr und {endHour} Uhr
        </>
      );
    } else {
      const formattedStartDate = (
        <>
          <NaturalDateRange
            startDate={startDate}
            endDate={startDate}
            {...props}
          />
          {' um '}
          {startHour} Uhr
        </>
      );
      const formattedEndDate = (
        <>
          <NaturalDateRange
            startDate={endDate}
            endDate={endDate}
            {...props}
          />
          {' um '}
          {endHour} Uhr
        </>
      );

      formattedDateTimeRange = (
        <>
          {formattedStartDate}
          {' '}&ndash;{' '}
          {formattedEndDate}
        </>
      );
    }
  }

  return formattedDateTimeRange;
}
