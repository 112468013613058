import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

const getCategory = (option) => option.category;

export default function MusicFilter({extraOptions, ...props}) {
  const options = useMemo(() => {
    if (!extraOptions || extraOptions.length === 0) {
      return musicFilterOptions;
    }

    // Merge options, giving precedence to extraOptions when ids overlap.
    const ids = new Set(extraOptions.map(({id}) => id));
    return [
      ...extraOptions,
      ...musicFilterOptions.filter(({id}) => !ids.has(id)),
    ];
  }, [extraOptions, musicFilterOptions])

  return (
    <FancyFilterField
      options={options}
      groupBy={getCategory}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Produktionen suchen..."
      {...props}
    />
  );
}

export const musicMetaDataFilterOptions = [
  {
    category: "Typ",
    id: 'nutzung_art_knz',
    label: 'Nutzungsart',
    choice: 'STANDARD',
    chipLabel: ({id, choice}) => ({
      'STANDARD': "Standard-Produktionen",
      'JINGLE': "Jingles",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'STANDARD': "Standard",
      'JINGLE': "Jingle",
    }[choice]),
    choices: ['STANDARD', 'JINGLE'],
  },
  {
    category: "Typ",
    id: 'musik_herkunft_knz',
    label: 'Musikherkunft',
    choice: 'VTON',
    chipLabel: ({id, choice}) => ({
      'EIGEN': "Eigenproduktionen",
      'AUFTRAG': "Auftragsproduktionen",
      'LIVE': "Live-Aufnahmen",
      'VTON': "veröffentlichte Tonaufnahmen",
      'PLM': "Production Library Music",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'EIGEN': "Eigen",
      'AUFTRAG': "Auftrag",
      'LIVE': "live",
      'VTON': "vTon",
      'PLM': "PLM",
    }[choice]),
    choices: ['EIGEN', 'AUFTRAG', 'LIVE', 'VTON', 'PLM'],
  },
  {
    category: 'Werk',
    id: 'title',
    label: 'Titel',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Titel",
      '0': "ohne Titel",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Werk',
    id: 'urheber',
    label: 'Urheber',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Urheber",
      '0': "ohne Urheber",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Werk',
    id: 'interpret',
    label: 'Interpret',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Interpret",
      '0': "ohne Interpret",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Identifier',
    id: 'isrc',
    label: 'ISRC',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit ISRC",
      '0': "ohne ISRC",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Identifier',
    id: 'ean',
    label: 'EAN',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit EAN",
      '0': "ohne EAN",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Identifier',
    id: 'catalog',
    label: 'Katalognummer',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Katalognummer",
      '0': "ohne Katalognummer",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Identifier',
    id: 'archive',
    label: 'Archivnummer',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Archivnummer",
      '0': "ohne Archivnummer",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Erschienene Tonaufnahme',
    id: 'label_code',
    label: 'Label-Code',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Label-Code",
      '0': "ohne Label-Code",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Erschienene Tonaufnahme',
    id: 'label',
    label: 'Label',
    choice: '0',
    chipLabel: ({id, choice}) => ({
      '1': "mit Label",
      '0': "ohne Label",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "vorhanden",
      '0': "fehlt",
    }[choice]),
    choices: ['1', '0'],
  },
  {
    category: 'Referenzdaten',
    id: 'link',
    label: 'GVL-Referenzdaten',
    choice: 's',
    chipLabel: ({id, choice}) => ({
      '1': "mit zugeordneten Referenzdaten",
      's': "mit vorgeschlagenen Referenzdaten",
      '0': "ohne Referenzdaten",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      '1': "zugeordnet",
      's': "vorgeschlagen",
      '0': "nicht vorhanden",
    }[choice]),
    choices: ['1', 's', '0'],
  },
];

const musicFilterOptions = [
  {
    category: "Status",
    id: 'quality',
    label: 'Hinweise',
    choice: 'has_todo_tasks',
    chipLabel: ({id, choice}) => ({
      'has_todo_tasks': "Einträge mit Hinweisen",
      'no_todo_tasks': "Einträge ohne Hinweise",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'has_todo_tasks': "liegen vor",
      'no_todo_tasks': "liegen nicht vor",
    }[choice]),
    choices: ['has_todo_tasks', 'no_todo_tasks'],
  },
  {
    category: "Status",
    id: 'status',
    label: 'Bearbeitungsstatus',
    choice: 'changed',
    chipLabel: ({id, choice}) => ({
      'changed': "bearbeitet",
      'unchanged': "nicht bearbeitet",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'changed': "bearbeitet",
      'unchanged': "nicht bearbeitet",
    }[choice]),
    choices: ['changed', 'unchanged'],
  },
  ...musicMetaDataFilterOptions,
];
