const HF_PERMISSION_GROUPS = [
  {
    id: 'admin',
    name: "Administrator",
    description: "Nutzer hat uneingeschränkten Zugriff.",
    permissions: {
      perm_read_reports: true,
      perm_write_reports: true,
      perm_submit_reports: true,
      perm_write_music: true,
      perm_write_details: true,
      perm_write_stations: true,
      perm_read_people: true,
      perm_write_people_and_rights: true,
      perm_read_documents: true,
      perm_write_documents: true,
    },
  },
  {
    id: 'editor',
    name: "Musikredakteur",
    description: "Nutzer darf alles außer Zugriffsrechte verwalten.",
    permissions: {
      perm_read_reports: true,
      perm_write_reports: true,
      perm_submit_reports: true,
      perm_write_music: true,
      perm_write_details: true,
      perm_write_stations: true,
      perm_read_people: true,
      perm_write_people_and_rights: false,
      perm_read_documents: true,
      perm_write_documents: true,
    },
  },
  {
    id: 'lira',
    name: "LIRA",
    description: "Nutzer darf Dokumente mit Verwertungsgesellschaften austauschen.",
    permissions: {
      perm_read_reports: true,
      perm_write_reports: false,
      perm_submit_reports: false,
      perm_write_music: false,
      perm_write_details: false,
      perm_write_stations: false,
      perm_read_people: true,
      perm_write_people_and_rights: false,
      perm_read_documents: true,
      perm_write_documents: true,
    },
  },
  {
    id: 'reader',
    name: "Lesezugriff",
    description: "Nutzer hat nur Lesezugriffsrechte.",
    permissions: {
      perm_read_reports: true,
      perm_write_reports: false,
      perm_submit_reports: false,
      perm_write_music: false,
      perm_write_details: false,
      perm_write_stations: false,
      perm_read_people: true,
      perm_write_people_and_rights: false,
      perm_read_documents: true,
      perm_write_documents: false,
    },
  },
  {
    id: 'custom',
    name: "benutzerdefiniert",
    description: "Nutzer hat von Ihnen festgelegte Einzelberechtigungen.",
    isCustom: true,
    permissions: {},
  },
];

const VWG_PERMISSION_GROUPS = [
  {
    id: 'admin',
    name: "Administrator",
    description: "Nutzer hat uneingeschränkten Zugriff.",
    permissions: {
      perm_read_reports: false,
      perm_write_reports: false,
      perm_submit_reports: false,
      perm_write_music: false,
      perm_write_details: true,
      perm_write_stations: false,
      perm_read_people: true,
      perm_write_people_and_rights: true,
      perm_read_documents: true,
      perm_write_documents: true,
    },
  },
  {
    id: 'reader',
    name: "Lesezugriff",
    description: "Nutzer hat nur Lesezugriffsrechte.",
    permissions: {
      perm_read_reports: false,
      perm_write_reports: false,
      perm_submit_reports: false,
      perm_write_music: false,
      perm_write_details: false,
      perm_write_stations: false,
      perm_read_people: true,
      perm_write_people_and_rights: false,
      perm_read_documents: true,
      perm_write_documents: false,
    },
  },
  {
    id: 'custom',
    name: "benutzerdefiniert",
    description: "Nutzer hat von Ihnen festgelegte Einzelberechtigungen.",
    isCustom: true,
    permissions: {},
  },
];

const PRV_PERMISSION_GROUPS = [];

export const PERMISSION_GROUPS_BY_TYPE = {
  HF: HF_PERMISSION_GROUPS,
  VWG: VWG_PERMISSION_GROUPS,
  PRV: PRV_PERMISSION_GROUPS,
}

function isCompatible(permissions, permissionGroup) {
  let compatible = true;
  Object.entries(permissionGroup.permissions)
    .forEach(([permName, groupPermValue]) => {
      const permValue = permissions?.[permName];
      if (groupPermValue !== permValue) {
        compatible = false;
      }
    });
  return compatible;
}

export function getPermissionGroupById(type, id) {
  for (const permissionGroup of PERMISSION_GROUPS_BY_TYPE[type]) {
    if (permissionGroup.id === id) {
      return permissionGroup;
    }
  }
}

export function getPermissionGroup(type, permissions) {
  if (!permissions || !type) {
    return undefined;
  }

  for (const permissionGroup of PERMISSION_GROUPS_BY_TYPE[type]) {
    if (isCompatible(permissions, permissionGroup)) {
      return permissionGroup;
    }
  }
}
