import {Chip} from "@mui/material";
import React, {useState} from "react";
import {Add} from "@mui/icons-material";
import { Skeleton } from '@mui/material';
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import {MUSIK_HERKUNFT_KNZ} from "src/features/dashboard/dashboard-validation";
import {useOrgMusicWork} from "src/features/entity/entity-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function MusikHerkunft({id, showOnlyButton}) {
  const {
    id: musikId,
    organization,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
  } = useOrgMusicWork({id, observe: false});

  const [editMusicNutzung, setEditMusicNutzung] = useState(null);

  let renderedHerkunft = MUSIK_HERKUNFT_KNZ[musik_herkunft_knz];

  if (showOnlyButton && (!musikId || renderedHerkunft)) {
    return null;
  }

  if (!musikId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const addHerkunftButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="Musikherkunft ergänzen"
        onClick={() => setEditMusicNutzung({
          id,
          organization,
          nutzung_art_knz,
          aufnahme_datum,
          prod_titel,
          prod_ort,
          musik_herkunft_knz
        })}
      />
    </OnlyIfPermissions>
  );

  if (!renderedHerkunft) {
    renderedHerkunft = addHerkunftButton;
  }

  return (
    <>
      {renderedHerkunft}
      {editMusicNutzung ? (
        <div align="left">
          <MusicNutzungForm
            data={editMusicNutzung}
            onClose={() => setEditMusicNutzung(null)}
          />
        </div>
      ) : null}
    </>
  );
}
