import React from "react";
import ProgrammeTable from "src/components/entities/programme/ProgrammeTable";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";

export default function ProgrammesPage() {
  useRequirePermissions({perm_read_stations: true});

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  return (
    <ProgrammeTable
      organizationId={selectedOrganizationId}
    />
  );
}
