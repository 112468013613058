import {useSelector} from "react-redux";
import {getUploadedFileGetter} from "src/features/entity";
import {Chip, Skeleton} from "@mui/material";
import React from "react";
import FileIcon from "./FileIcon";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import {Block, DeleteOutline} from "@mui/icons-material";

export default function UploadedFileChip({id}) {
  const getUploadedFile = useSelector(getUploadedFileGetter);
  const {
    id: uploadedFileId,
    name,
    type,
    ...props
  } = getUploadedFile(id);

  const hasPermissions = useHasPermissions({perm_read_reports: true});
  if (!hasPermissions) {
    return (
      <Chip
        icon={<Block/>}
        color="error"
        label={<i>nicht zugreifbare Datei</i>}
        size="small"
        variant="outlined"
      />
    );
  }

  if (!id) {
    return (
      <Chip
        icon={<DeleteOutline/>}
        color="error"
        label={<i>gelöschte Datei</i>}
        size="small"
        variant="outlined"
      />
    );
  }

  if (uploadedFileId === undefined) {
    return <Skeleton variant="text" width={100} sx={{display: 'inline-block'}}/>;
  }

  return (
    <Chip
      icon={<FileIcon type={type}/>}
      label={name}
      size="small"
      variant="outlined"
    />
  );
}
