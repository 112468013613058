import React, {useEffect, useState} from "react";
import {useEntityApi, useGVLProduct, useOrgMusicWork, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import {lightGreen, orange, red} from "@mui/material/colors";
import MusikProduktionId, {MusikProduktionIdTyp} from "src/components/entities/musicwork/components/MusikProduktionId";
import axios from "axios";
import MusikPerson from "src/components/entities/musicwork/components/MusikPerson";
import {
  Add, ArrowLeft, ArrowRight,
  Bolt,
  Block,
  Check,
  Clear,
  DoubleArrow,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Timelapse,
  VerifiedUser
} from "@mui/icons-material";
import GVLProductIdentifier, {
  GVLProductIdentifierType
} from "src/components/entities/musicwork/components/GVLProductIdentifier";
import GVLProductTitle from "src/components/entities/musicwork/components/GVLProductTitle";
import GVLProductPerson from "src/components/entities/musicwork/components/GVLProductPerson";
import Duration from "src/packages/Duration";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import CircularProgress from "@mui/material/CircularProgress";

function ComparisonOperator({
  score,
  added,
  ignored,
}) {
  return (
    added ? (
      <Tooltip title="Diese Angabe wird durch die Referenzdaten zusätzlich bereitgestellt.">
        <Avatar><Add/></Avatar>
      </Tooltip>
    ) : ignored ? (
      <Tooltip title="Diese Angabe wurde als Platzhalter erkannt und deshalb beim Vergleich ignoriert.">
        <Avatar><Clear/></Avatar>
      </Tooltip>
    ) : score === undefined ? (
      <Skeleton variant="circular"><Avatar/></Skeleton>
    ) : score === 1 ? (
      <Avatar sx={{bgcolor: lightGreen[500]}}>=</Avatar>
      // <Avatar>=</Avatar>
    ) : score >= 0.9 ? (
      <Avatar sx={{
        bgcolor: lightGreen[300],
        fontSize: 'small'
      }}>{`${Math.round(Math.min(score * 100, 99))}%`}</Avatar>
    ) : score >= 0.75 ? (
      <Avatar sx={{
        bgcolor: orange[500],
        fontSize: 'small'
      }}>{`${Math.round(score * 100)}%`}</Avatar>
    ) : (
      <Avatar sx={{bgcolor: red[500]}}><Bolt/></Avatar>
    )
  );
}

function ElementComparison({
  title,
  score,
  leftTitle,
  rightTitle,
  leftContent,
  leftExtra,
  rightContent,
  rightExtra,
  added,
  ignored,
}) {
  if (!leftTitle) {
    leftTitle = title;
  }
  if (!rightTitle) {
    rightTitle = title;
  }

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={6}>
        {(leftTitle || leftContent) ? (
          <Card sx={{
            width: 1,
            p: 1,
            mb: 1,
            position: 'relative',
            color: ignored ? '#888888' : undefined,
          }}>
            <Typography variant="subtitle2">{leftTitle}</Typography>
            {leftContent}
            {leftExtra ? (
              <Box position="absolute" sx={{
                right: 6,
                top: 6,
              }}>
                {leftExtra}
              </Box>
            ) : null}
          </Card>
        ) : null}
      </Grid>
      <Grid item xs={6} container>
        <Box sx={{
          display: 'flex',
          width: 1,
        }}>
          <Box sx={{
            pt: 1,
            pr: 1,
            pl: 1,
            ml: -1,
            position: 'relative',
          }}>
            {added ? (
              <Box sx={{
                position: 'absolute',
                background: '#d3d3d3',
                left: 30,
                top: 26,
                height: 5,
                width: 30,
              }}/>
            ) : ignored ? (
              <Box sx={{
                position: 'absolute',
                background: '#d3d3d3',
                left: 0,
                top: 26,
                height: 5,
                width: 30,
              }}/>
            ) : (
              <Box sx={{
                position: 'absolute',
                background: '#d3d3d3',
                left: 0,
                top: 26,
                height: 5,
                width: 60,
              }}/>
            )}
            <ComparisonOperator score={score} added={added} ignored={ignored}/>
          </Box>
          <Box sx={{flexGrow: 1}}>
            {(rightTitle || rightContent) && (
              <Card sx={{
                width: 1,
                p: 1,
                mb: 1,
                position: 'relative',
                color: '#888888',
              }}>
                <Typography variant="subtitle2">{rightTitle}</Typography>
                {rightContent}
                {rightExtra ? (
                  <Box position="absolute" sx={{
                    right: 6,
                    top: 6,
                  }}>
                    {rightExtra}
                  </Box>
                ) : null}
              </Card>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

function MusicWorkGVLProductComparisonView({
  comparison,
  gvlProduct,
  secondColumnHeader,
  hasOtherSuggestions,
}) {
  const {
    org_music_work_id: orgMusicWorkId,
    gvl_produkt_id: gvlProductId,
    min_airtime,
    max_airtime
  } = comparison?.metadata || {};

  // const {organization} = useOrgMusicWork({
  //   id: orgMusicWorkId,
  //   observe: false
  // });
  const {id: organizationId} = useSelectedOrganization();

  const data = useGVLProduct({id: gvlProductId});
  const haupttitel = data?.titel?.filter(({weiterer}) => !weiterer)?.[0];

  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  const confirm = async () => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organizationId}/org_music_works/${orgMusicWorkId}/link_gvl_produkt/`,
      {gvlProductId},
    );
  };
  const decline = async () => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organizationId}/org_music_works/${orgMusicWorkId}/unlink_gvl_produkt/`,
      {gvlProductId},
    );
  };

  return (
    <Grid container pt={1}>
      <Grid item xs={6} sx={{
        display: 'inline-block',
        alignSelf: 'flex-end'
      }}>
        <Typography variant="h6" gutterBottom>Musikproduktion</Typography>
      </Grid>
      <Grid item xs={6} textAlign="center" sx={{
        display: 'inline-block',
        alignSelf: 'flex-end'
      }}>
        {secondColumnHeader ? secondColumnHeader : (
          <Typography variant="h6" gutterBottom>
            vorgeschlagene Referenzdaten
          </Typography>
        )}
        {/*<Typography variant="subtitle1">*/}
        {/*  GVL-ID {gvlProduct.id}*/}
        {/*</Typography>*/}
        {/*{gvlProduct.id}*/}
      </Grid>

      {!comparison ? (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={1}>
            <CircularProgress/>
          </Grid>
        </Grid>
      ) : null}

      {comparison ? (
        <ElementComparison
          title="Titel"
          score={comparison?.title?.score}
          leftContent={comparison?.title?.metadata?.omw_title}
          leftExtra={(min_airtime && max_airtime) ? (
            <>
              <Tooltip title="Länge Ihrer Ausstrahlungen dieser Musikproduktion">
                <Chip
                  size="small"
                  icon={<Timelapse/>}
                  label={(
                    max_airtime === min_airtime ? (
                      <Duration value={max_airtime}/>
                    ) : (
                      <>
                        <Duration value={min_airtime}/> &ndash; <Duration value={max_airtime}/>
                      </>
                    )
                  )}
                  component="span"
                />
              </Tooltip>
            </>
          ) : null}
          rightContent={(
            <>
              {comparison?.title?.metadata?.gvl_title?.weiterer ? (
                <>
                  <GVLProductTitle gvlProductTitle={haupttitel}/>
                  <br/>
                </>
              ) : null}
              <GVLProductTitle gvlProductTitle={comparison?.title?.metadata?.gvl_title}/>
            </>
          )}
          rightExtra={(
            <>
              {gvlProduct.dauer && gvlProduct.dauer !== '0:00:00' ? (
                <>
                  <Chip size="small" icon={<Timelapse/>} label={<Duration value={gvlProduct.dauer}/>} component="span"/>
                  {' '}
                </>
              ) : null}
              <Chip size="small" color="primary" icon={<VerifiedUser/>} label="GVL" component="span"/>
            </>
          )}
        />
      ) : null}

      {comparison?.people?.artists?.items?.map((similarity, i) => (
        <ElementComparison
          key={i}
          title="Interpret"
          score={similarity?.score}
          leftContent={(
            <>
              {similarity?.metadata?.extracted_omw_artist_name ? (
                <Tooltip
                  title={(
                    similarity?.metadata?.omw_musik_titel ? (
                      "Der zum Vergleich herangezogene Interpret wurde aus einem Titeleintrag automatisch extrahiert."
                    ) : (
                      "Der zum Vergleich herangezogene Interpret wurde aus einem Sammeleintrag mit mehreren Interpreten automatisch extrahiert."
                    )
                  )}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <span style={{display: 'flex', textTransform: 'uppercase'}}>
                      {similarity?.metadata?.extracted_omw_artist_name}
                    </span>
                    <span style={{color: '#cccccc', display: 'flex', alignItems: 'center'}}>
                      {' '}<ArrowLeft color="disabled"/>{' '}
                      {similarity?.metadata?.omw_musik_person_id ? (
                        <MusikPerson id={similarity?.metadata?.omw_musik_person_id}/>
                      ) : similarity?.metadata?.omw_musik_titel ? (
                        similarity?.metadata?.omw_musik_titel
                      ) : "unbekannt"}
                    </span>
                  </Box>
                </Tooltip>
              ) : (
                <MusikPerson id={similarity?.metadata?.omw_musik_person_id}/>
              )}
            </>
          )}
          rightContent={(
            <>
              {similarity?.metadata?.extracted_gvl_artist_name ? (
                <Tooltip
                  title={(
                    similarity?.metadata?.gvl_titel ? (
                      "Der zum Vergleich herangezogene Interpret wurde aus einem Titeleintrag automatisch extrahiert."
                    ) : (
                      "Der zum Vergleich herangezogene Interpret wurde aus einem Sammeleintrag mit mehreren Interpreten automatisch extrahiert."
                    )
                  )}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <span style={{display: 'flex', textTransform: 'uppercase'}}>
                      {similarity?.metadata?.extracted_gvl_artist_name}
                    </span>
                    <span style={{color: '#cccccc', display: 'flex', alignItems: 'center'}}>
                      {' '}<ArrowLeft color="disabled"/>{' '}
                      {similarity?.metadata?.gvl_interpret ? (
                        similarity?.metadata?.gvl_interpret
                      ) : similarity?.metadata?.gvl_titel ? (
                        <GVLProductTitle gvlProductTitle={similarity?.metadata?.gvl_titel}/>
                      ) : "unbekannt"}
                    </span>
                  </Box>
                </Tooltip>
              ) : (
                similarity?.metadata?.gvl_interpret
              )}
            </>
          )}
        />
      ))}

      {comparison && comparison?.people?.artists?.score === undefined && gvlProduct?.interpret ? (
        <ElementComparison
          added
          rightTitle="Interpret"
          rightContent={gvlProduct?.interpret}
        />
      ) : null}

      {comparison?.people?.composers?.items?.map((similarity, i) => (
        <ElementComparison
          key={i}
          title="Komponist"
          score={similarity?.score}
          leftContent={(
            <>
              {similarity?.metadata?.extracted_omw_musik_person ? (
                <Tooltip title="Die zum Vergleich herangezogene Person wurde aus einem Sammeleintrag mit mehreren Personen automatisch extrahiert.">
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <span style={{display: 'flex', textTransform: 'uppercase'}}>
                      {similarity?.metadata?.extracted_omw_musik_person?.vorname} {similarity?.metadata?.extracted_omw_musik_person?.name}
                    </span>
                    <span style={{color: '#cccccc', display: 'flex', alignItems: 'center'}}>
                      {' '}<ArrowLeft color="disabled"/>{' '}
                      <MusikPerson id={similarity?.metadata?.omw_musik_person_id}/>
                    </span>
                  </Box>
                </Tooltip>
              ) : (
                <MusikPerson id={similarity?.metadata?.omw_musik_person_id}/>
              )}
            </>
          )}
          rightContent={(
            <>
              {similarity?.metadata?.extracted_gvl_person ? (
                <Tooltip title="Die zum Vergleich herangezogene Person wurde aus einem Sammeleintrag mit mehreren Personen automatisch extrahiert.">
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <span style={{display: 'flex', textTransform: 'uppercase'}}>
                      <GVLProductPerson gvlProductPerson={similarity?.metadata?.extracted_gvl_person}/>
                    </span>
                    <span style={{color: '#cccccc', display: 'flex', alignItems: 'center'}}>
                      {' '}<ArrowLeft color="disabled"/>{' '}
                      <GVLProductPerson gvlProductPerson={similarity?.metadata?.gvl_person}/>
                    </span>
                  </Box>
                </Tooltip>
              ) : (
                <GVLProductPerson gvlProductPerson={similarity?.metadata?.gvl_person}/>
              )}
            </>
          )}
        />
      ))}

      {comparison?.people?.composers?.metadata?.seems_placeholder && (
        <ElementComparison
          leftTitle={comparison?.people?.composers?.metadata?.omw_musik_person_ids?.length === 1 ? "Komponist" : "Komponisten"}
          ignored
          leftContent={(
            <>
              <Tooltip title="Diese Angabe scheint ein Duplikat der Interpret-Angabe zu sein und wird daher als Platzhalter bewertet.">
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                  <span style={{
                    display: 'flex',
                  }}>
                    <Block color="disabled"/>{' '}
                  </span>
                  <span style={{
                    color: '#cccccc',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    {' '}<ArrowLeft color="disabled"/>{' '}
                    {comparison?.people?.composers?.metadata?.omw_musik_person_ids?.map((id, i) => (
                      <><MusikPerson key={id || i} id={id}/><br/></>
                    ))}
                    </span>
                </Box>
              </Tooltip>
            </>
          )}
        />
      )}

      {[
        comparison?.identifiers?.isrc,
        comparison?.identifiers?.iswc,
        comparison?.identifiers?.catalog_number,
        comparison?.identifiers?.ean_upc,
      ].map((similarity, i) => similarity?.score === undefined ? null : (
        <ElementComparison
          key={i}
          score={similarity?.score}
          leftTitle={<MusikProduktionIdTyp id={similarity?.metadata?.omw_musik_produktion_id}/>}
          leftContent={<MusikProduktionId id={similarity?.metadata?.omw_musik_produktion_id}/>}
          rightTitle={<GVLProductIdentifierType type={similarity?.metadata?.gvl_identifier?.type}/>}
          rightContent={<GVLProductIdentifier gvlProductIdentifier={similarity?.metadata?.gvl_identifier}/>}
        />
      ))}

      {comparison && comparison?.identifiers?.isrc?.score === undefined ? (
        gvlProduct?.identifiers?.filter(({
          type,
          is_valid
        }) => type === 'ISRC' && is_valid)
          ?.map((gvlProductIdentifier, i) => (
            <ElementComparison
              key={i}
              added
              rightTitle={<GVLProductIdentifierType type={gvlProductIdentifier?.type}/>}
              rightContent={<GVLProductIdentifier gvlProductIdentifier={gvlProductIdentifier}/>}
            />
          ))
      ) : null}

      <OnlyIfPermissions perm_write_music>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button
              startIcon={<Clear/>}
              color="inherit"
              onClick={(event) => {
                event.stopPropagation();
                decline();
              }}
            >
              Ablehnen
            </Button>
            &nbsp;
            <Button
              startIcon={<Check/>}
              variant={!hasOtherSuggestions ? 'contained' : 'text'}
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                confirm();
              }}
            >
              Zuordnung bestätigen
            </Button>
          </Grid>
        </Grid>
      </OnlyIfPermissions>

      {comparison && hasOtherSuggestions ? (
        <Grid item xs={12} container spacing={1} mt={0.5}>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Alert variant="outlined" severity="info">
              Bitte beachten Sie, dass weitere Vorschläge existieren.
            </Alert>
          </Grid>
        </Grid>
      ) : null}

    </Grid>
  );
}

export const MusicWorkGVLProductComparison = React.memo(function MusicWorkGVLProductComparison({
  orgMusicWorkId,
  gvlProductId,
  ...props
}) {
  gvlProductId = parseInt(gvlProductId);
  // gvlProductId = 556492847;

  const {id: organizationId} = useSelectedOrganization();
  const orgMusicWork = useOrgMusicWork({id: orgMusicWorkId});
  const gvlProduct = useGVLProduct({id: gvlProductId});

  const [comparison, setComparison] = useState(null);

  useEffect(async () => {
    if (!organizationId) {
      return;
    }
    try {
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/org_music_works/${orgMusicWorkId}/comparisons/?gvl_produkt_id__in=${gvlProductId}`);
      result.data?.forEach((comparison) => {
        if (comparison?.metadata?.gvl_produkt_id === gvlProductId && comparison?.metadata?.org_music_work_id === orgMusicWorkId) {
          setComparison(comparison);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [organizationId, orgMusicWorkId, gvlProductId]);

  return (
    <MusicWorkGVLProductComparisonView
      comparison={comparison}
      gvlProduct={gvlProduct}
      {...props}
    />
  );
});

export default MusicWorkGVLProductComparison;

export function MusicWorkGVLProductComparisons({
  orgMusicWorkId,
  gvlProductIdsAndScores
}) {
  let [selectedIndex, setSelectedIndex] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = args => {
    setSelectedIndex(args);
    closeMenu();
  };

  if (!gvlProductIdsAndScores?.length) {
    return null;
  }

  if (selectedIndex > gvlProductIdsAndScores?.length - 1) {
    selectedIndex = 0;
  }

  const {
    gvl_product_id: gvlProductId,
    score
  } = gvlProductIdsAndScores[selectedIndex] || {};

  if (!gvlProductId) {
    return null;
  }

  return (
    <>
      <MusicWorkGVLProductComparison
        orgMusicWorkId={orgMusicWorkId}
        gvlProductId={gvlProductId}
        hasOtherSuggestions={gvlProductIdsAndScores?.length > 1}
        secondColumnHeader={(
          <Box sx={{alignContent: 'center'}}>
            <Typography variant="h6">
              Referenzdaten
            </Typography>
            <Box sx={{alignContent: 'center'}}>
              <Toolbar variant="dense">
                <IconButton
                  aria-label="previousMatch"
                  color="inherit"
                  onClick={() => setSelectedIndex(selectedIndex - 1)}
                  disabled={selectedIndex === 0}
                  size="large">
                  <KeyboardArrowLeft/>
                </IconButton>

                <Button
                  aria-haspopup="true"
                  onClick={openMenu}
                  color="inherit"
                  startIcon={<Typography component="span"><ComparisonOperator score={score}/></Typography>}
                  endIcon={<KeyboardArrowDown/>}
                  sx={{flex: 1}}
                >
                  <Typography sx={{
                    textTransform: 'none',
                    whiteSpace: 'nowrap'
                  }}>
                    Vorschlag {selectedIndex + 1} von {gvlProductIdsAndScores?.length}
                  </Typography>
                </Button>
                <Menu
                  id="item-choice-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={closeMenu}
                >
                  {gvlProductIdsAndScores.map(({
                    gvl_product_id: gvlProductId,
                    score
                  }, i) => (
                    <MenuItem
                      key={i}
                      selected={selectedIndex === i}
                      onClick={() => handleSelect(i)}
                    >
                      <ListItemIcon>
                        <ComparisonOperator score={score}/>
                      </ListItemIcon>
                      <ListItemText sx={{pl: 1}}>
                        GVL-Produkt {gvlProductId}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Menu>


                <IconButton
                  aria-label="nextMatch"
                  color="inherit"
                  onClick={() => setSelectedIndex(selectedIndex + 1)}
                  disabled={selectedIndex >= gvlProductIdsAndScores.length - 1}
                  size="large">
                  <KeyboardArrowRight/>
                </IconButton>
              </Toolbar>
            </Box>
          </Box>
        )}
      />
    </>
  );
}
