import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function DatabaseRightFilter({...props}) {
  return (
    <FancyFilterField
      options={databaseRightFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Berechtigungen suchen..."
      {...props}
    />
  );
}

const databaseRightFilterOptions = [
];
