import {useEntityObserver} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getGVLProductGetter} from "src/features/entity";
import {Box, Button, TableCell, TableRow, Typography} from "@mui/material";
import React, {useState} from "react";
import GVLProductTitle from "src/components/entities/musicwork/components/GVLProductTitle";
import GVLProductPerson from "src/components/entities/musicwork/components/GVLProductPerson";
import GVLProductIdentifier from "src/components/entities/musicwork/components/GVLProductIdentifier";
import Duration from "src/packages/Duration";
import {Search} from "@mui/icons-material";
import {GVLProduktPersonenDialog} from "src/components/entities/refdata/GVLProduktPersonenDialog";
import {GVLProduktIdentifierDialog} from "src/components/entities/refdata/GVLProduktIdentifierDialog";

export default function GVLProduktRow({
  id,
}) {
  useEntityObserver({
    type: 'gvl_product',
    id
  });

  const {
    titel,
    personen,
    identifiers,
    interpret,
    genre,
    dauer,
  } = useSelector(getGVLProductGetter)(id);

  const [showUrheber, setShowUrheber] = useState(false);
  const [showIdentifier, setShowIdentifier] = useState(false);

  return <>
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography>
          {id}
        </Typography>
      </TableCell>
      <TableCell>
        {titel?.map((el, i) => (
          <Typography key={i}>
            <GVLProductTitle gvlProductTitle={el}/>
          </Typography>
        ))}
      </TableCell>
      <TableCell>
        {personen?.filter(({rolle}) => rolle === 'K')?.length > 0 ? (
          <Box>
            <Button color="info" size="small" startIcon={<Search/>} onClick={() => setShowUrheber(true)}>
              Urheber
            </Button>
            <GVLProduktPersonenDialog
              id={id}
              open={showUrheber}
              onClose={() => setShowUrheber(false)}
            />
          </Box>
        ) : null}
      </TableCell>
      <TableCell>
        <Typography>
          {interpret}
        </Typography>
        <GVLProduktIdentifierDialog
          id={id}
          open={!!showIdentifier}
          type={showIdentifier}
          onClose={() => setShowIdentifier(false)}
        />
      </TableCell>
      <TableCell align="center">
        <Typography>
          <Duration value={dauer}/>
        </Typography>
      </TableCell>
      <TableCell align="center">
        {identifiers?.filter(({type}) => type === 'ISRC')
          ?.map((el, i) => (
            <Typography key={i}>
              <GVLProductIdentifier gvlProductIdentifier={el} showType/>
            </Typography>
          ))}
        <div style={{whiteSpace: 'nowrap'}}>
          {identifiers?.filter(({type}) => type === 'KAT')?.length > 0 ? (
            <Button color="info" size="small" startIcon={<Search/>} onClick={() => setShowIdentifier('KAT')}>
              KAT
            </Button>
          ) : null}
          {identifiers?.filter(({type}) => type === 'EAN')?.length > 0 ? (
            <Button color="info" size="small" startIcon={<Search/>} onClick={() => setShowIdentifier('EAN')}>
              EAN
            </Button>
          ) : null}
          {identifiers?.filter(({type}) => type === 'UPC')?.length > 0 ? (
            <Button color="info" size="small" startIcon={<Search/>} onClick={() => setShowIdentifier('UPC')}>
              UPC
            </Button>
          ) : null}
          {identifiers?.filter(({type}) => type === 'ISWC')?.length > 0 ? (
            <Button color="info" size="small" startIcon={<Search/>} onClick={() => setShowIdentifier('ISWC')}>
              ISWC
            </Button>
          ) : null}
        </div>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {genre}
        </Typography>
      </TableCell>
    </TableRow>
  </>;
}
