import {parseDate} from "src/packages/date-utils";
import dateFormat from "dateformat";
import {addDays} from "date-fns";
import {Tooltip} from "@mui/material";
import {Warning} from "@mui/icons-material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import _ from 'lodash';

export default function NaturalDateRange({startDate, endDate, openRange, warnUnusual}) {
  let isUnusual = false;
  let formattedDateRange;

  if (!startDate && !endDate) {
    formattedDateRange = 'unbekannter Zeitraum';
    isUnusual = true;
  } else {
    if (!_.isDate(startDate)) {
      startDate = parseDate(startDate);
    }
    if (!_.isDate(endDate)) {
      endDate = parseDate(endDate);
    }

    const dateFormatPrefix = openRange ? '' : 'dddd, ';

    // Simplify date range that contains only one day.
    if (startDate.toDateString() === endDate.toDateString()) {
      formattedDateRange = (
        dateFormat(startDate, dateFormatPrefix + "d. mmmm yyyy")
      );
    }

    // Simplify date ranges that contain full months.
    let startMonth,
      endMonth;
    if (startDate.getDate() === 1) {
      startMonth = startDate;
    } else {
      let startDateTomorrow = addDays(startDate, 1);
      if (startDateTomorrow.getDate() === 1) {
        startMonth = startDateTomorrow;
      }
    }
    if (endDate.getDate() === 1) {
      endMonth = addDays(endDate, -1);
    } else {
      let endDateTomorrow = addDays(endDate, 1);
      if (endDateTomorrow.getDate() === 1) {
        endMonth = endDate;
      }
    }

    if (startMonth > endMonth) {
      startMonth = null;
      endMonth = null;
    }

    let formattedStartDate,
      formattedEndDate;

    if (!formattedDateRange && startMonth && endMonth) {
      if (dateFormat(startMonth, "yyyy-mm") === dateFormat(endMonth, "yyyy-mm")) {
        formattedDateRange = dateFormat(startMonth, "mmmm yyyy");
        // return (
        //   dateFormat(startMonth, "mmmm yyyy")
        // );
      } else if (dateFormat(startMonth, "yyyy") === dateFormat(endMonth, "yyyy")) {
        formattedStartDate = dateFormat(startMonth, "mmmm");
        formattedEndDate = dateFormat(endMonth, "mmmm yyyy");

        const monthsLength = endMonth.getMonth() - startMonth.getMonth() + 1;
        if (monthsLength === 3 && startMonth.getMonth() % 3 === 0) {
          const quarter = startMonth.getMonth() / 3 + 1;
          formattedDateRange = (
            <>
              {formattedStartDate} &ndash; {formattedEndDate} ({quarter}. Quartal)
            </>
          );
        } else if (monthsLength === 4 && startMonth.getMonth() % 4 === 0) {
          const tertial = startMonth.getMonth() / 4 + 1;
          formattedDateRange = (
            <>
              {formattedStartDate} &ndash; {formattedEndDate} ({tertial}. Tertial)
            </>
          );
        } else if (monthsLength === 6 && startMonth.getMonth() % 6 === 0) {
          const half = startMonth.getMonth() / 6 + 1;
          formattedDateRange = (
            <>
              {formattedStartDate} &ndash; {formattedEndDate} ({half}. Halbjahr)
            </>
          );
        }
      } else {
        formattedStartDate = dateFormat(startMonth, "mmmm yyyy");
        formattedEndDate = dateFormat(endMonth, "mmmm yyyy");
      }
    }

    if (formattedDateRange || (formattedStartDate && formattedEndDate)) {

    } else if (dateFormat(startDate, "yyyy-mm") === dateFormat(endDate, "yyyy-mm")) {
      formattedStartDate = dateFormat(startDate, dateFormatPrefix + "d.");
      formattedEndDate = dateFormat(endDate, dateFormatPrefix + "d. mmmm yyyy");
      isUnusual = true;
    } else if (dateFormat(startDate, "yyyy") === dateFormat(endDate, "yyyy")) {
      formattedStartDate = dateFormat(startDate, dateFormatPrefix + "d. mmmm");
      formattedEndDate = dateFormat(endDate, dateFormatPrefix + "d. mmmm yyyy");
      isUnusual = true;
    } else {
      formattedStartDate = dateFormat(startDate, dateFormatPrefix + "d. mmmm yyyy");
      formattedEndDate = dateFormat(endDate, dateFormatPrefix + "d. mmmm yyyy");
      isUnusual = true;
    }

    if (!formattedDateRange) {
      formattedDateRange = (
        <>
          {formattedStartDate}
          {' '}&ndash;{' '}
          {formattedEndDate}
        </>
      );
    }
  }

  if (isUnusual && warnUnusual && !openRange) {
    return (
      <Tooltip
        title="Diese Lieferung hat einen ungewöhnlichen Meldezeitraum. Ist die Lieferung vollständig?"
      >
        <span>
          {formattedDateRange}
          {' '}
          <Warning color="primary" style={{verticalAlign: 'middle'}}/>
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      {openRange ? <><CircularProgress size="1rem" color="inherit"/>&nbsp;</> : null}{formattedDateRange}
    </span>
  );
}
