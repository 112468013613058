import {Chip, Tooltip} from "@mui/material";
import {Warning} from "@mui/icons-material";
import React from "react";

export const GVL_IDENTIFIER_TYPES = {
  ISRC: "ISRC",
  EAN: "EAN",
  UPC: "UPC",
  KAT: "Katalognummer",
  ISWC: "ISWC",
};

export function GVLProductIdentifierType({type}) {
  let description = GVL_IDENTIFIER_TYPES[type];
  if (description === undefined) {
    return null;
  }

  return (
    description
  );
}

export default function GVLProductIdentifier({
  gvlProductIdentifier,
  showType
}) {
  const {
    type,
    name,
    is_valid
  } = gvlProductIdentifier || {};

  const typeBadge = showType ? (
    <Chip label={<GVLProductIdentifierType type={type}/>} size="small" component="span"/>
  ) : null;

  return (
    <>
      {!is_valid ? (
        <Tooltip title="Dieser Identifier ist ungültig.">
          <Chip label={name} component="span" color="primary" size="small" icon={<Warning/>}/>
        </Tooltip>
      ) : (
        <>
          {name}
        </>
      )}
      {typeBadge ? (
        <>
          {' '}
          {typeBadge}
        </>
      ) : null}
    </>
  );
}
