import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {cleanupListing} from "src/features/ui/listing";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import {Box, Collapse, Grid, List, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {Check} from "@mui/icons-material";
import NotificationRow from "src/components/entities/notification/NotificationRow";
import {getOrganizationGetter} from "src/features/entity";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    //maxWidth: 800,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(1),
    },
  },
}));

export default function UnreadNotificationsBox({organizationId}) {
  const classes = useStyles();

  const organization = useSelector(getOrganizationGetter)(organizationId);
  const {request_user_unread_notifications_count} = organization;

  const dispatch = useDispatch();

  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(-1);

  useEffect(() => {
    if (!organization?.id) {
      return;
    }

    if (request_user_unread_notifications_count > unreadNotificationsCount) {
      const action = cleanupListing({
        id: `${organization?.id}/unreadNotifications`,
      });
      dispatch(action);
    }

    if (request_user_unread_notifications_count !== unreadNotificationsCount) {
      setUnreadNotificationsCount(request_user_unread_notifications_count);
    }
  }, [organization?.id, request_user_unread_notifications_count, unreadNotificationsCount]);

  const {
    isLoading: notificationsIsLoading,
    renderIds: notificationRenderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: `unreadNotifications`,
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/notifications/',
    entityType: 'notification',
    defaultOrdering: ['-timestamp'],
    filter: [{
      id: 'unread',
      choice: 'true'
    }],
    defaultPageSize: 20,
  });

  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (notificationRenderIds?.length > 0 && !showNotifications) {
      setShowNotifications(true);
    }
  }, [notificationRenderIds, showNotifications]);

  return (
    <Collapse in={showNotifications} component={Grid} item xs={16} p={1}>
      <Paper sx={{
        width: '100%',
        height: '100%',
        p: 2
      }}>
        <Box>
          <Typography variant="h6">
            Neue Benachrichtigungen
          </Typography>
        </Box>
        {notificationRenderIds?.length === 0 ? (
          notificationsIsLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={150} color="lightgray">
              <CircularProgress size="2rem" color="inherit"/>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height={150} color="darkgray">
              <Check/>&nbsp;keine ungelesenen Benachrichtigungen
            </Box>
          )
        ) : (
          <List className={classes.listRoot}>
            {notificationRenderIds?.map((id, i) => (
              <NotificationRow
                key={id || i}
                id={id}
                organizationId={organizationId}
              />
            ))}
          </List>
        )}
      </Paper>
    </Collapse>
  );
}
