import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function ReportFilter({extraOptions, ...props}) {
  const options = useMemo(() => {
    if (!extraOptions || extraOptions.length === 0) {
      return [...reportFilterOptions];
    }

    // Merge options, giving precedence to extraOptions when ids overlap.
    const ids = new Set(extraOptions.map(({id}) => id));
    return [
      ...reportFilterOptions.filter(({id}) => !ids.has(id)),
      ...extraOptions,
    ];
  }, [extraOptions, reportFilterOptions]);

  return (
    <FancyFilterField
      options={options}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Lieferungen suchen..."
      {...props}
    />
  );
}

const reportFilterOptions = [
  {
    category: "Status",
    id: 'status',
    label: 'Status',
    choice: 'outstanding',
    chipLabel: ({id, choice}) => ({
      'outstanding': "offene Lieferungen",
      'published': "veröffentlichte Lieferungen",
      'cancelled': "stornierte Lieferungen",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'outstanding': "offen",
      'published': "veröffentlicht",
      'cancelled': "storniert",
    }[choice]),
    choices: ['outstanding', 'published', 'cancelled'],
  },
  {
    category: "Inhalt",
    id: 'has_music',
    label: 'Musik',
    choice: 'yes',
    chipLabel: ({id, choice}) => ({
      'yes': "mit Musik",
      'no': "ohne Musik",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'yes': "enthalten",
      'no': "nicht enthalten",
    }[choice]),
    choices: ['yes', 'no'],
  },
  {
    category: "Inhalt",
    id: 'has_jingles',
    label: 'Jingles',
    choice: 'yes',
    chipLabel: ({id, choice}) => ({
      'yes': "mit Jingles",
      'no': "ohne Jingles",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'yes': "enthalten",
      'no': "nicht enthalten",
    }[choice]),
    choices: ['yes', 'no'],
  },
];
