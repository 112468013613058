import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Paper,} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ProjectTimeline} from "src/components/welcome/components";

export default function TimelinePage() {
  return (
    <DashboardLayout
      titlePrefix="Projektstatus"
      selectedPage="timeline"
    >
      <Box my={2}>
        <Typography variant="h6">
          Projektstatus
        </Typography>
      </Box>

      <Paper elevation={3} sx={{padding: '6px 16px'}}>
        <Box mx={4} my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            Zeitplan
          </Typography>
          <Typography gutterBottom>
            Die Entwicklung der Plattform Sendemeldung.de erfolgt auf
            Veranlassung von und in enger Abstimmung mit den Verbänden APR und VAUNET.
            Nachfolgend finden Sie den aktuellen Zeitplan.
          </Typography>
        </Box>
        <ProjectTimeline elevation={3} showFuture={true}/>
      </Paper>

    </DashboardLayout>
  );
}
