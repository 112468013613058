import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function DocumentFilter({...props}) {
  return (
    <FancyFilterField
      options={documentFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Dokumente und Nachrichten suchen..."
      {...props}
    />
  );
}

const documentFilterOptions = [
  {
    category: null,
    id: 'unread',
    label: 'Status',
    choice: 'true',
    chipLabel: ({
      id,
      choice
    }) => ({
      'true': "ungelesene Nachrichten",
      'false': "gelesene Nachrichten",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'true': "ungelesen",
      'false': "gelesen",
    }[choice]),
    choices: ['true', 'false'],
  },
  {
    category: null,
    id: 'type',
    label: 'Typ',
    choice: 'personal',
    chipLabel: ({
      id,
      choice
    }) => ({
      'personal': "persönliche Nachrichten",
      'public': "allgemeine Nachrichten",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'personal': "persönlich",
      'public': "allgemein",
    }[choice]),
    choices: ['personal', 'public'],
  },
];
