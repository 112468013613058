import {useCallback, useEffect, useRef} from "react";
import _ from "lodash";

export function useLazyEffect(effect, deps = [], wait = 300) {
  const cleanUp = useRef();
  const effectRef = useRef();
  effectRef.current = useCallback(effect, deps);

  const cleanUpMostRecentEffect = useCallback(
    () => {
      if (cleanUp.current instanceof Function) {
        cleanUp.current();
        cleanUp.current = undefined;
      }
    }, [cleanUp]
  );

  const lazyEffect = useCallback(
    _.debounce(() => {
      cleanUpMostRecentEffect();
      cleanUp.current = effectRef.current?.();
    }, wait),
    [],
  );

  // Schedule delayed effect and cancel it if it is no longer required.
  useEffect(() => {
    lazyEffect();
    return () => lazyEffect.cancel();
  }, deps);

  // Schedule additional effect that ensures that the cleanup function of the effect whose execution has actually
  // started is executed on unmount (note that the cleanup function is returned and not executed in effect).
  useEffect(() => cleanUpMostRecentEffect, [cleanUpMostRecentEffect]);
}
