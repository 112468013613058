import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import KnownBugList from "src/components/entities/known_bug/KnownBugList";
import {Button, Card, CardContent, Divider, Typography} from "@mui/material";
import {Link} from "gatsby-theme-material-ui";
import DocumentForm from "src/components/entities/document/DocumentForm";
import {getOrganizationGetter} from "src/features/entity";
import {Message} from "@mui/icons-material";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

export default function KnownBugsPage() {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {provider_id: providerId} = useSelector(getOrganizationGetter)(selectedOrganizationId);

  const [editDocument, setEditDocument] = useState(null);

  const hasMessagePermission = useHasPermissions({perm_write_documents: true});

  return (
    <DashboardLayout
      titlePrefix="Bekannte Probleme"
      selectedPage="known_bugs"
    >
      <Card sx={{mb: 1}}>
        <CardContent>
          <Typography gutterBottom>
            Wir entwickeln Sendemeldung.de kontinuierlich weiter.
          </Typography>

          <Typography gutterBottom>
            Damit wir Ihre Anforderungen berücksichtigen können, sind wir auf Ihre Mithilfe angewiesen:
            Bitte teilen Sie uns mit, wenn etwas nicht bzw. nicht wie erwartet funktioniert oder wenn Sie sonstige
            Anregungen haben.
            Wir geben uns größte Mühe, jede Anregung &ndash; in Absprache mit der Projekt-Steuerungsgruppe der Verbände
            &ndash; zu berücksichtigen.
          </Typography>

          <Typography>
            Damit Sie nichts melden müssen, das bereits bekannt ist, finden Sie untenstehend eine Liste bekannter
            Anregungen und Bugs, an deren Umsetzung bzw. Behebung wir bereits arbeiten.
          </Typography>
        </CardContent>
      </Card>

      <KnownBugList
        organizationId={selectedOrganizationId}
      />

      <Divider sx={{
        mt: 4,
        mb: 4
      }}/>

      <Card sx={{mt: 2}}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Haben Sie eine neue Anregung für uns oder einen Fehler gefunden?
          </Typography>
          {hasMessagePermission ? (
            <>
              <Typography gutterBottom>
                Bitte zögern Sie nicht, uns eine Nachricht über den Kontaktbereich zu schreiben oder uns eine E-Mail an
                {' '}
                <Link href="mailto:kontakt@sendemeldung.de" underline="hover">kontakt@sendemeldung.de</Link>
                {' '}
                zu senden.
              </Typography>
              <Typography sx={{pt: 1}}>
                <Button
                  startIcon={<Message/>}
                  onClick={() => {
                    setEditDocument({organization: providerId});
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Nachricht schreiben
                </Button>
              </Typography>
            </>
          ) : (
            <Typography>
                Bitte zögern Sie nicht, uns eine E-Mail an
                {' '}
                <Link href="mailto:kontakt@sendemeldung.de" underline="hover">kontakt@sendemeldung.de</Link>
                {' '}
                zu senden.
            </Typography>
          )}
        </CardContent>
      </Card>

      {editDocument ? (
        <DocumentForm
          data={editDocument}
          onClose={() => setEditDocument(null)}
          autoFocusField={{field: 'description', moveCaretToBeginning: true}}
        />
      ) : null}
    </DashboardLayout>
  );
}
