import {Button, Divider, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import {useSelector} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import {getGEMAGVLXMLLieferungGetter, getGEMAGVLXMLLieferungStatsGetter} from "src/features/entity";
import dateFormat from "dateformat";
import {formatDate, parseDate} from "src/packages/date-utils";
import {addDays} from "date-fns";
import SimpleDuration from "src/components/entities/gemagvlxml/SimpleDuration";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import {useEntityObserver} from "src/features/entity/entity-hooks";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonTitle: {
    textTransform: 'none',
  },
}));

function DateMenuItemContent({gemagvlxml_lieferung, date}) {
  useEntityObserver({type: 'gemagvlxml_lieferung_stats', gemagvlxml_lieferung});

  const {stations} = useSelector(getGEMAGVLXMLLieferungGetter)(gemagvlxml_lieferung);
  const {stats} = useSelector(getGEMAGVLXMLLieferungStatsGetter)(gemagvlxml_lieferung);

  const formattedDate = formatDate(date);
  const dayStats = stats?.coverage?.by_date?.[formattedDate];

  const stationsCount = stations?.length || 1;

  return (
    <>
      <ListItemText
        primary={(
          <>
            {dateFormat(date, "dddd, d. mmmm yyyy")}
          </>
        )}
        secondary={(
          <>
            {dayStats ? (
              <SimpleDuration value={dayStats?.standard_musik_dauer + dayStats?.jingle_musik_dauer}/>
            ) : null}
          </>
        )}
        style={{marginRight: '3rem'}}
      />

      {dayStats ? (
        <ListItemSecondaryAction>
          <CircularProgressWithLabel
            textColor="inherit"
            variant="determinate"
            value={(dayStats?.standard_musik_dauer + dayStats?.jingle_musik_dauer) / dayStats?.interval_length / stationsCount}
            showZero
          />
        </ListItemSecondaryAction>
      ) : null}
    </>
  );
}

export default function PlaylistDateChoiceButton({gemagvlxml_lieferung, date, selectDate}) {
  const classes = useStyles();

  let {datum_von, datum_bis} = useSelector(getGEMAGVLXMLLieferungGetter)(gemagvlxml_lieferung);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = args => {
    selectDate(args);
    closeMenu();
  };

  const dates = [];
  if (datum_von && datum_bis) {
    datum_von = parseDate(datum_von);
    datum_bis = parseDate(datum_bis);

    let currentDate = datum_von;
    while (currentDate <= datum_bis) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
  }

  if (!date && !dates?.length) {
    return null;
  }

  return (
    <>
      <Button
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        endIcon={date ? null : <KeyboardArrowDown/>}
        className={classes.root}
      >
        <Typography className={classes.buttonTitle}>
          {date ? dateFormat(date, "dd.mm.yyyy") : "Zeitraum"}
        </Typography>
      </Button>
      <Menu
        id="date-choice-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem
          selected={!date}
          onClick={() => handleSelect(null)}
        >
          gesamten Zeitraum anzeigen
        </MenuItem>
        <Divider/>
        {!open ? null : dates.map((value, i) => (
          <MenuItem
            key={value || i}
            selected={formatDate(value) === formatDate(date)}
            onClick={() => handleSelect(value)}
          >
            <DateMenuItemContent gemagvlxml_lieferung={gemagvlxml_lieferung} date={value}/>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
