import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function LabelFilter({...props}) {
  return (
    <FancyFilterField
      options={labelFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Label recherchieren..."
      {...props}
    />
  );
}

const labelFilterOptions = [
  {
    // category: "PLM",
    id: 'plm',
    label: 'Production Library / Archivmusik',
    choice: 'true',
    chipLabel: ({
      id,
      choice
    }) => ({
      'true': "PLM",
      'false': "nicht PLM",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'true': "ja",
      'false': "nein",
    }[choice]),
    choices: ['true', 'false'],
  },
];
