import {useSelector} from "react-redux";
import {getOrgMusicWorkGetter} from "src/features/entity";
import {Link} from "gatsby-theme-material-ui";
import {Chip, IconButton, Skeleton} from "@mui/material";
import React, {useState} from "react";
import {Add, Edit, Warning} from "@mui/icons-material";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function MusikTitel({
  id,
  createLink,
  onEdit
}) {
  const {
    id: musikTitelId,
    organization,
    musik_titel,
    nutzung_art_knz,
    recht_knz,
    besetzung_knz,
    gattung,
    is_silence,
    database,
  } = useSelector(getOrgMusicWorkGetter)(id);

  const [editMusicWork, setEditMusicWork] = useState(null);

  let renderedTitle = musik_titel;

  if (!musikTitelId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const addTitleButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="Titel ergänzen"
        component="span"
        onClick={() => setEditMusicWork({
          id,
          organization,
          musik_titel,
          recht_knz,
          besetzung_knz,
          gattung,
          is_silence,
        })}
      />
    </OnlyIfPermissions>
  );

  if (!renderedTitle) {
    if (createLink) {
      renderedTitle = (
        <Chip
          icon={<Warning/>}
          clickable
          size="small"
          variant="filled"
          color="primary"
          label="Titel fehlt"
          component="span"
        />
      );
    } else {
      renderedTitle = addTitleButton;
    }
  }

  if (createLink && musikTitelId && musik_titel !== undefined) {
    renderedTitle = (
      <Link to={`/dashboard/database/${database}/${id}`} color="inherit">
        {renderedTitle}
      </Link>
    );
  }

  return (
    <>
      {renderedTitle}
      {nutzung_art_knz === 'JINGLE' ? (
        <>
          {' '}
          <Chip size="small" label="Jingle" component="span"/>
        </>
      ) : null}
      {is_silence ? (
        <>
          {' '}
          <Chip size="small" label="Stille" component="span"/>
        </>
      ) : null}
      {onEdit ? (
        <>
          <OnlyIfPermissions perm_write_music>
            {' '}
            <IconButton
              aria-label="edit"
              onClick={onEdit ? (() => onEdit({
                id,
                organization,
                musik_titel
              })) : null}
              size="small"
            >
              <Edit/>
            </IconButton>
          </OnlyIfPermissions>
        </>
      ) : null}

      {editMusicWork ? (
        <div align="left">
          <MusicWorkForm
            data={editMusicWork}
            onClose={() => setEditMusicWork(null)}
          />
        </div>
      ) : null}
    </>
  );
}
