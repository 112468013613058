import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {getOrganizationGetter} from "src/features/entity";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import React, {useState} from "react";
import DocumentForm from "src/components/entities/document/DocumentForm";
import {Button} from "@mui/material";
import {Message} from "@mui/icons-material";

export function SimpleContactButton({
  alternateContent,
  children = "Nachricht schreiben",
}) {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    provider_id: providerId,
  } = useSelector(getOrganizationGetter)(selectedOrganizationId);

  const hasMessagePermission = useHasPermissions({perm_write_documents: true});

  const [editDocument, setEditDocument] = useState(null);

  return (
    <>
      {hasMessagePermission ? (
        <>
          <Button
            startIcon={<Message/>}
            onClick={() => {
              setEditDocument({organization: providerId});
            }}
            color="primary"
            variant="outlined"
          >
            {children}
          </Button>
          {editDocument ? (
            <DocumentForm
              data={editDocument}
              onClose={() => setEditDocument(null)}
              autoFocusField={{field: 'description', moveCaretToBeginning: true}}
            />
          ) : null}
        </>
      ) : alternateContent}
    </>
  );
}
