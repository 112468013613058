import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {MUSIC_WORK_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";
import TitleSuggestionsField from "src/components/entities/musicwork/components/TitleSuggestionsField";

export function MusicWorkForm({
  data,
  onClose,
  ...props
}) {
  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  const {
    id: organizationId,
    is_silence_flag_allowed
  } = useSelectedOrganization();

  const saveWork = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organizationId}/org_music_works/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'edit-music-work'}
      title={data?.id ? "Musikproduktion bearbeiten" : "Musikproduktion anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveWork}
      schema={MUSIC_WORK_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField
              name="musik_titel"
              Component={TitleSuggestionsField}
            />
          </Grid>
          <Grid item xs={12}>
            <YupField name="recht_knz"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="besetzung_knz"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="gattung"/>
          </Grid>
          {is_silence_flag_allowed ? (
            <Grid item xs={12}>
              <YupField name="is_silence"/>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
