import {useEntityApi, useEntityObserver, useOrganization} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getDocumentGetter} from "src/features/entity";
import {getSessionToken} from "src/features/session";
import {
  Alert,
  Badge, Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText, MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Delete,
  Drafts,
  Edit,
  Email, Error,
  GetApp,
  InsertDriveFile, MarkChatRead,
  MarkChatUnread,
  PictureAsPdf, Reply,
  Search
} from "@mui/icons-material";
import HrefIconButton from "src/packages/gatsby-mui-helpers/HrefIconButton";
import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import NaturalTime from "src/packages/natural-time-view";
import {ORGANIZATION_TYPES} from "src/components/dashboard_pages/organization";
import {DOCUMENT} from "src/api/api-schemas";
import SimpleMoreMenu from "src/packages/SimpleMoreMenu";
import CircularProgress from "@mui/material/CircularProgress";
import {useDocumentReplyFunction} from "src/components/entities/document/document-hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 800,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),
    },
  },
}));

function FileIcon({mimetype}) {
  switch (mimetype) {
    case "application/pdf":
      return <PictureAsPdf/>;
    default:
      return <InsertDriveFile/>;
  }
}

export function FileDocument({
  id,
  mimetype,
  url,
  name,
  description,
  status,
  size,
  editDocument,
  deleteDocument,
  is_unread,
  markRead,
  markUnread,
  isUploading,
  failed,
  isAttachment,
  reply,
}) {
  const classes = useStyles();

  if (isAttachment) {
    is_unread = false;
  }

  return (
    <List className={classes.root} sx={{pb: 1}}>
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          <Badge color="primary" variant="dot" invisible={!is_unread}>
            {isUploading ? (
              failed ? (
                <Error/>
              ) : (
                <CircularProgress size="1rem" color="inherit"/>
              )
            ) : status === 0 ? (
              <Drafts/>
            ) : (
              <FileIcon mimetype={mimetype}/>
            )}
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={
            isUploading ? (
              <>
                {name}
              </>
            ) : (
              <Link href={url} underline="hover">
                {is_unread ? (
                  <span style={{fontWeight: '600'}}>{name}</span>
                ) : name}
              </Link>
            )
          }
          secondary={(
            <span style={is_unread ? {fontWeight: '600'} : undefined}>
              {failed ? (
                <>
                  Upload fehlgeschlagen.
                </>
              ) : isUploading ? (
                <>
                  {size} bytes hochzuladen...
                </>
              ) : description}
            </span>
          )}
          sx={{mr: 2}}
        />
        <ListItemSecondaryAction sx={{height: '100%', py: 1}}>
          {status !== 0 && !isUploading && !isAttachment ? (
            <SimpleMoreMenu id={id} size="normal">
              {is_unread ? (
                <MenuItem
                  onClick={markRead}
                >
                  <ListItemIcon>
                    <MarkChatRead fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    als gelesen markieren
                  </ListItemText>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={markUnread}
                >
                  <ListItemIcon>
                    <MarkChatUnread fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    als ungelesen markieren
                  </ListItemText>
                </MenuItem>
              )}
              {reply ? (
                <Divider/>
              ) : null}
              {reply ? (
                <MenuItem
                  onClick={() => reply(id)}
                >
                  <ListItemIcon>
                    <Reply fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Antworten...
                  </ListItemText>
                </MenuItem>
              ) : null}
            </SimpleMoreMenu>
          ) : null}
          {status === 0 ? (
            editDocument ? (
              <IconButton
                // edge="end"
                aria-label="edit"
                onClick={() => editDocument(id)}
              >
                <Edit/>
              </IconButton>
            ) : deleteDocument ? (
              <IconButton
                // edge="end"
                aria-label="delete"
                onClick={() => deleteDocument(id)}
              >
                <Delete/>
              </IconButton>
            ) : null
          ) : url ? (
            <HrefIconButton
              // edge="end"
              aria-label="download"
              href={url}
              external
            >
              <GetApp/>
            </HrefIconButton>
          ) : null}
          {isUploading && deleteDocument ? (
            <IconButton
              // edge="end"
              aria-label="delete"
              onClick={() => deleteDocument(id)}
            >
              <Delete/>
            </IconButton>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

function MessageDocument({
  id,
  url,
  message,
  description,
  status,
  editDocument,
  viewMessage,
  is_unread,
  markUnread,
  markRead,
  reply,
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const defaultAction = (status === 0) ? editDocument : viewMessage;

  return (
    <List className={classes.root} sx={{pb: 1}}>
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          <Badge color="primary" variant="dot" invisible={!is_unread}>
            {status === 0 ? (
              <Drafts/>
            ) : (
              <Email/>
            )}
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={
            <Link onClick={() => defaultAction(id)} underline="hover">
              {is_unread ? (
                <span style={{fontWeight: '600'}}>{description}</span>
              ) : description}
            </Link>
          }
          secondary={(
            <span style={is_unread ? {fontWeight: '600'} : undefined}>
              {(message?.length > 256 && !expanded) ? (
                <>
                  {message?.substring(0, 250)}
                  <Link onClick={() => setExpanded(true)}>...</Link>
                </>
              ) : message}
            </span>
          )}
          sx={{mr: 2}}
        />
        <ListItemSecondaryAction sx={{height: '100%', py: 1}}>
          {status !== 0 ? (
            <SimpleMoreMenu id={id} size="normal">
              {is_unread ? (
                <MenuItem
                  onClick={markRead}
                >
                  <ListItemIcon>
                    <MarkChatRead fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    als gelesen markieren
                  </ListItemText>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={markUnread}
                >
                  <ListItemIcon>
                    <MarkChatUnread fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    als ungelesen markieren
                  </ListItemText>
                </MenuItem>
              )}
              {reply ? (
                <Divider/>
              ) : null}
              {reply ? (
                <MenuItem
                  onClick={() => reply(id)}
                >
                  <ListItemIcon>
                    <Reply fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Antworten...
                  </ListItemText>
                </MenuItem>
              ) : null}
            </SimpleMoreMenu>
          ) : null}
          {status === 0 ? (
            <IconButton
              // edge="end"
              aria-label="edit"
              onClick={() => editDocument(id)}
            >
              <Edit/>
            </IconButton>
          ) : (
            <IconButton
              // edge="end"
              aria-label="view"
              onClick={() => viewMessage(id)}
            >
              <Search/>
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export function OrganizationEntity({
  id
}) {
  const {
    company_name: companyName,
    type,
  } = useOrganization({id});

  return (
    <Tooltip title={ORGANIZATION_TYPES[type] || ""}>
      <Link color="secondary">{companyName}</Link>
    </Tooltip>
  );
}

export function DocumentSender({
  documentId: id
}) {
  useEntityObserver({
    type: 'document',
    id
  });

  const {
    sender,
  } = useSelector(getDocumentGetter)(id);

  return (
    <OrganizationEntity
      id={sender}
    />
  );

  // const {
  //   company_name: senderCompanyName,
  //   type: senderType,
  // } = useOrganization({id: sender});
  //
  // return (
  //   <Tooltip title={ORGANIZATION_TYPES[senderType] || ""}>
  //     <Link color="secondary">{senderCompanyName}</Link>
  //   </Tooltip>
  // );
}

export default function DocumentRow({
  id,
  organizationId,
  editDocument,
  viewMessage,
  deleteDocument,
  deletedMessage = "Dieser Entwurf wurde gelöscht.",
  hideTimestamp,
  skipDraft,
  skipSent,
  isAttachment,
  createMessage,
}) {
  useEntityObserver({
    type: 'document',
    id
  });

  const {
    name,
    description,
    message,
    sent_at,
    sender,
    organization,
    mimetype,
    status,
    isDeleted,
    type,
    is_unread,
  } = useSelector(getDocumentGetter)(id);

  const sessionToken = useSelector(getSessionToken);

  const {
    company_name: senderCompanyName,
    type: senderType,
  } = useOrganization({id: sender});

  const {
    company_name: receiverCompanyName,
    type: receiverType,
  } = useOrganization({id: organization});

  const entityApi = useEntityApi(DOCUMENT);

  let markRead = async () => {
    await entityApi.delete(
      `/api/sendemeldung/organizations/${organizationId}/documents/${id}/unread/`,
    );
  };

  let markUnread = async () => {
    await entityApi.put(
      `/api/sendemeldung/organizations/${organizationId}/documents/${id}/unread/`,
    );
  };

  const url = `/api/sendemeldung/organizations/${organizationId}/documents/${id}/blob/?token=${sessionToken}`;

  let {reply} = useDocumentReplyFunction({documentId: id, createMessage});
  if (status !== 200) {
    reply = null;
  }

  if (skipDraft && status === 0) {
    return null;
  } else if (skipSent && status === 200 && organizationId === sender) {
    return null;
  }

  if (isDeleted) {
    return (
      <Alert variant="filled" color="error" severity="success">
        {deletedMessage}
      </Alert>
    );
  }

  return (
    <>
      {!hideTimestamp && sent_at ? (
        <Typography sx={{pt: 1}}>
          <NaturalTime date={sent_at} inSentence/>
          {' '}
          {status === 0 ? (
            <>
              als Entwurf erfasst
            </>
          ) : status === 200 ? (
            organizationId === sender ? (
              <>
                versendet an
                {' '}
                <Tooltip title={ORGANIZATION_TYPES[receiverType] || ""}>
                  <Link color="secondary">{receiverCompanyName}</Link>
                </Tooltip>
              </>
            ) : (
              <>
                erhalten von
                {' '}
                <DocumentSender documentId={id}/>
                {/*<Tooltip title={ORGANIZATION_TYPES[senderType] || ""}>*/}
                {/*  <Link color="secondary">{senderCompanyName}</Link>*/}
                {/*</Tooltip>*/}
              </>
            )
          ) : null}
          {/*<Button size="small" color="inherit" startIcon={<Business/>}>{sender}</Button>*/}
          {/*<Chip*/}
          {/*  size="small"*/}
          {/*  label={sender}*/}
          {/*  icon={<Business/>}*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
        </Typography>
      ) : null}
      {type === 'file' ? (
        <FileDocument
          id={id}
          mimetype={mimetype}
          description={description}
          url={url}
          editDocument={editDocument}
          deleteDocument={deleteDocument}
          name={name}
          status={status}
          is_unread={is_unread}
          markRead={markRead}
          markUnread={markUnread}
          isAttachment={isAttachment}
          reply={reply}
        />
      ) : (type === 'message') ? (
        <MessageDocument
          id={id}
          mimetype={mimetype}
          description={description}
          url={url}
          editDocument={editDocument}
          viewMessage={id => markRead() && viewMessage(id)}
          message={message}
          status={status}
          is_unread={is_unread}
          markRead={markRead}
          markUnread={markUnread}
          reply={reply}
        />
      ) : (
        "unbekannter Dokumenttyp"
      )}
    </>
  );
}
