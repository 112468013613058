import React from "react";
import {useSelector} from "react-redux";
import {getDocumentGetter} from "src/features/entity";
import {getSelectedOrganizationId} from "src/features/dashboard";

export function useDocumentReplyFunction({
  documentId,
  createMessage,
}) {
  const getDocument = useSelector(getDocumentGetter);

  const {
    sender,
    organization,
    name,
    description,
    message
  } = getDocument(documentId);

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const recipient = (sender === selectedOrganizationId ? organization : sender);

  return {
    reply: () => createMessage({
      organization: recipient,
      description: `Re: ${name || description}`,
      message: message ? (
        '\n\nAntwort auf:\n' + message.replace(/(^|\n)/g, '$1> ')
      ) : undefined,
    }),
  };
}
