import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, {useRef} from "react";
import {useEntityObserver, useOrganization} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getDocumentGetter} from "src/features/entity";
import DocumentRow, {OrganizationEntity} from "src/components/entities/document/DocumentRow";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {useDocumentReplyFunction} from "src/components/entities/document/document-hooks";

export function MessageViewerDialog({
  organizationId,
  id,
  open,
  onClose,
  createMessage,
  ...props
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const ref = useRef();

  useEntityObserver({
    type: 'document',
    id
  });

  const {
    name,
    description,
    message,
    sent_at,
    sender,
    organization,
    mimetype,
    status,
    isDeleted,
    type,
    children,
  } = useSelector(getDocumentGetter)(id);

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    company_name: senderCompanyName,
    type: senderType,
  } = useOrganization({id: sender});

  const {
    company_name: receiverCompanyName,
    type: receiverType,
  } = useOrganization({id: organization});

  const {reply} = useDocumentReplyFunction({documentId: id, createMessage});

  return (
    <div ref={ref} style={{minWidth: 600}}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby={id}
        fullScreen={fullScreen}
        container={ref.current}
        style={{whiteSpace: 'normal'}}
        {...props}
      >
        <DialogTitle id={id}>
          Nachricht
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              {sender ? (
                <>
                  <Typography component="h5" color="textSecondary">
                    Absender
                  </Typography>
                  <Typography gutterBottom sx={{pb: 2}}>
                    <OrganizationEntity id={sender}/>
                  </Typography>
                </>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              {organization ? (
                <>
                  <Typography component="h5" color="textSecondary">
                    Empfänger
                  </Typography>
                  <Typography gutterBottom sx={{pb: 2}}>
                    <OrganizationEntity id={organization}/>
                  </Typography>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography component="h5" color="textSecondary">
                Betreff
              </Typography>
              <Typography gutterBottom sx={{
                pb: 2,
                fontWeight: 600
              }}>
                {description}
              </Typography>
            </Grid>
          </Grid>

          <Typography component="h5" color="textSecondary">
            Nachricht
          </Typography>
          <Typography gutterBottom sx={{whiteSpace: 'pre', textWrap: 'wrap'}}>
            {message}
          </Typography>

          {children?.length > 0 ? (
            <>
              <Typography component="h5" color="textSecondary" sx={{pt: 2}}>
                Anhänge
              </Typography>
              {children?.map((uuid, i) => (
                <Grid item xs={12} key={uuid || i}>
                  <DocumentRow
                    key={uuid || i}
                    id={uuid}
                    organizationId={selectedOrganizationId}
                    deletedMessage="Dieser Anhang wurde gelöscht."
                    hideTimestamp
                    isAttachment
                  />
                </Grid>
              ))}
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          {createMessage ? (
            <Button
              color="default"
              onClick={() => {
                onClose();
                reply();
              }}
            >
              Antworten
            </Button>
          ) : null}
          <Button
            color="default"
            onClick={onClose}
          >
            Schließen
          </Button>
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    window.location.assign(exportUrl);*/}
          {/*    onClose();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Herunterladen*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}
