import {useSelector} from "react-redux";
import {getOrganizationGetter, getStationGetter} from "src/features/entity";
import {Chip, Skeleton} from "@mui/material";
import {Edit, Warning} from "@mui/icons-material";
import React, {useState} from "react";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {ProgrammeForm} from "src/components/entities/programme/ProgrammeForm";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

export default function ProgrammeChip({
  id,
  showEdit,
  ...props
}) {
  const hasEditPermission = useHasPermissions({perm_write_stations: true});

  const getStation = useSelector(getStationGetter);
  const {
    id: stationId,
    name,
    gemagvl_sender_prg_id,
    gemagvl4_sender,
    is_draft: isDraft,
    organization
  } = getStation(id);

  const getOrganization = useSelector(getOrganizationGetter);
  const {has_active_stations: hasActiveStations} = getOrganization(organization);

  const [editStation, setEditStation] = useState(null);

  useEntityObserver({
    type: 'station',
    id
  });

  if (!stationId) {
    return (
      <Skeleton style={{display: 'inline-block'}}>
        <Chip component="span" label="test" {...props}/>
      </Skeleton>
    );
  }

  const doEditStation = (event) => {
    event.stopPropagation();
    setEditStation(getStation(stationId));
  };

  const noop = (event) => {
    event.stopPropagation();
  };

  showEdit = showEdit || isDraft || !name;

  if (!hasEditPermission) {
    showEdit = false;
  }

  return (
    <>
      {gemagvl_sender_prg_id ? (
        <Chip
          clickable={false}
          variant="outlined"
          onClick={noop}
          component="span"
          // icon={<Radio color="action"/>}
          deleteIcon={showEdit ? <Edit/> : null}
          onDelete={showEdit ? doEditStation : null}
          label={(
            <>
              {name || gemagvl_sender_prg_id}
              {(isDraft && hasActiveStations) ? (
                <>
                  {' '}
                  <Chip
                    label="neu"
                    color="primary"
                    size="small"
                    component="span"
                  />
                </>
              ) : null}
            </>
          )}
          {...props}
        />
      ) : gemagvl4_sender ? (
        <Chip
          clickable
          component="span"
          // icon={<Radio color="action"/>}
          variant="outlined"
          onClick={doEditStation}
          label={(
            <>
              {name || gemagvl4_sender}
              {' '}
              <Chip
                clickable
                icon={<Warning/>}
                label="XML-Programm-ID fehlt"
                color="primary"
                size="small"
                component="span"
              />
            </>
          )}
          {...props}
        />
      ) : (
        <Chip
          clickable
          component="span"
          // icon={<Radio color="action"/>}
          variant="outlined"
          onClick={doEditStation}
          label={(
            <>
              <Chip
                clickable
                icon={<Warning/>}
                label="unbekanntes Programm"
                color="primary"
                size="small"
                component="span"
              />
            </>
          )}
          {...props}
        />
      )}
      {editStation ? (
        <div style={{position: 'absolute'}}>
          <ProgrammeForm
            data={editStation}
            onClose={() => setEditStation(null)}
          />
        </div>
      ) : null}
    </>
  );
}
