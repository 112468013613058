import React, {useMemo} from "react";
import {DashboardLayout} from "src/components/layout";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import SystemOverloadIndicator from "src/components/dashboard/components/overload-indicator";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import ReportFilter from "src/components/entities/gemagvlxml/components/ReportFilter";
import {updateQueryString, useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {
  Box,
  Button, Chip,
  Fab,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {Add, CalendarMonth, Check, ExpandMore, Folder, LabelImportant, PlaylistPlay, Reply} from "@mui/icons-material";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import {useSelectedOrganization} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getStationGetter} from "src/features/entity";
import {CalendarDrawerMainMenuItems} from "src/components/dashboard_pages/calendar";
import {parseDate} from "src/packages/date-utils";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";
import {DrawerMainMenuItem} from "src/components/dashboard/components/drawer-main-menu-view";

const updateOrdering = (ordering) => updateQueryString({ordering});

const DEFAULT_ORDERING = ['-created_at'];

// function OrderingMenuButton({children, ...props}) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const menuOpen = Boolean(anchorEl);
//
//   const openMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const closeMenu = () => {
//     setAnchorEl(null);
//   };
//
//   return (
//     <>
//       <Tooltip title="Sortierung auswählen">
//         <Button
//           onClick={openMenu}
//           {...props}
//         >
//           {children}
//         </Button>
//       </Tooltip>
//       <Menu
//         id="report_ordering_menu"
//         anchorEl={anchorEl}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         keepMounted
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         open={menuOpen}
//         onClose={closeMenu}
//         onClick={(event) => {
//           if (event.target !== anchorEl) {
//             closeMenu();
//           }
//         }}
//       >
//         <MenuItem
//           onClick={() => null}
//         >
//           <ListItemIcon>
//             <KeyboardArrowDownIcon fontSize="small"/>
//           </ListItemIcon>
//           <ListItemText>
//             Sortiere nach Erstellungsdatum<br/><small>(neueste zuerst)</small>
//           </ListItemText>
//         </MenuItem>
//       </Menu>
//     </>
//   );
// }

export function ReportsDrawerMainMenuItems() {
  const {
    outstanding_reports_count,
  } = useSelectedOrganization();

  return (
    <>
      <DrawerMainMenuItem sx={{pl: 4}} href={'/dashboard/reports/'} active>
        <ListItemIcon><Folder/></ListItemIcon>
        <ListItemText primary="Alle Lieferungen"/>
        <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/reports/'}>
          {outstanding_reports_count ? (
            <Chip size="small" color="primary" label={outstanding_reports_count} align="right"
                  style={{cursor: 'pointer'}}/>
          ) : null}
        </HrefComponent>
      </DrawerMainMenuItem>
      <CalendarDrawerMainMenuItems/>
    </>
  );
}

export default function ReportsPage() {
  useRequirePermissions({perm_read_reports: true});

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
    ordering,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'reports',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/gemagvlxml_lieferungen/',
    entityType: 'gemagvlxml_lieferung',
    defaultOrdering: DEFAULT_ORDERING,
  });

  const {
    renderIds: stationIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'filter_stations',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/stations/',
    entityType: 'station',
    defaultPageSize: 100,
    defaultOrdering: ['name', 'gemagvl_sender_prg_id'],
    pageParamName: 'dummyStationsPage',
    pageSizeParamName: 'dummyStationsPageSize',
    orderingParamName: 'dummyStationsOrdering',
    filterParamName: 'dummyStationsFilter',
  });

  const getStation = useSelector(getStationGetter);
  const stations = stationIds?.map(getStation);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  const extraOptions = useMemo(() => {
    const extraOptions = [];

    const currentYear = new Date().getFullYear();
    const mostRecentYears = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];

    extraOptions.push({
      category: "Meldezeitraum",
      id: 'year',
      label: 'Jahr',
      choice: `${mostRecentYears[0]}`,
      chipLabel: ({id, choice}) => `Jahr ${choice}`,
      choiceLabel: ({id, choice}) => `${choice}`,
      choices: mostRecentYears.map(year => `${year}`),
    });

    stations?.forEach(({id, name, gemagvl_sender_prg_id}) => {
      const stationName = name || gemagvl_sender_prg_id;
      extraOptions.push({
        category: "Programme",
        id: `station_${id}`,
        label: stationName,
        choice: 'include',
        chipLabel: ({id, choice}) => ({
          'include': `für ${stationName}`,
          'exclude': `ohne ${stationName}`,
        }[choice]),
        choiceLabel: ({id, choice}) => ({
          'include': "enthalten",
          'exclude': "nicht enthalten",
        }[choice]),
        choices: ['include', 'exclude'],
      });
    });

    return extraOptions;
  }, [stations]);

  let previousId = null;

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="reports"
      drawerContentProps={{
        activeDrawerMainMenuItem: (
          <ReportsDrawerMainMenuItems/>
        ),
      }}
    >
      <PageToolbar
        title="Sendemeldungen"
        searchField={(
          <ReportFilter
            {...filterProps}
            allowSearch
            extraOptions={extraOptions}
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
        // extraField={!noDataExists && !filterProps?.value?.length ? (
        //   <Button
        //     href="/dashboard/import/"
        //     //variant="outlined"
        //     size="small"
        //     color="default"
        //     endIcon={<KeyboardArrowDownIcon/>}
        //   >
        //     Erstelldatum
        //   </Button>
        // ) : null}
        preMenuItems={[
          {
            key: 'orderCreatedAt',
            icon: ordering?.[0] === '-created_at' ? (<Check fontSize="small"/>) : null,
            text: "Sortierung nach Importdatum",
            onClick: () => updateOrdering('-created_at'),
          },
          {
            key: 'orderDatumBis',
            icon: ordering?.[0] === '-report_datum_bis' ? (<Check fontSize="small"/>) : null,
            text: "Sortierung nach Meldezeitraum",
            onClick: () => updateOrdering('-report_datum_bis'),
          },
          {
            divider: true,
          },
        ]}
      >
      </PageToolbar>

      <Box mt={-1}>
        <SystemOverloadIndicator/>

        {/*<div style={{*/}
        {/*  position: 'absolute',*/}
        {/*  width: '100%',*/}
        {/*}}>*/}
        {/*  <div style={{*/}
        {/*    position: 'absolute',*/}
        {/*    right: 0,*/}
        {/*    top: 0,*/}
        {/*    textAlign: 'right',*/}
        {/*  }}>*/}
        {/*    <Button*/}
        {/*      href="/dashboard/import/"*/}
        {/*      //variant="outlined"*/}
        {/*      size="small"*/}
        {/*      color="default"*/}
        {/*      endIcon={<KeyboardArrowDownIcon/>}*/}
        {/*    >*/}
        {/*      Erstelldatum*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {renderIds.length === 0 ? (
          noDataExists ? (
            <Alert variant="filled" severity="info">
              Es wurden noch keine Sendemeldungen importiert.

              <p>
                <HrefButton
                  href="/dashboard/import/"
                  variant="contained"
                  color="primary"
                >
                  jetzt Sendemeldungen hochladen
                </HrefButton>
              </p>
            </Alert>
          ) : isLoading ? (
            <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
              Sendemeldungen werden geladen...
            </Alert>
          ) : (
            <Alert variant="filled" severity="info">
              Diese Ansicht enthält keine Einträge.
            </Alert>
          )
        ) : (
          <>
            {/*<Button*/}
            {/*  href="/dashboard/import/"*/}
            {/*  //variant="outlined"*/}
            {/*  size="small"*/}
            {/*  color="default"*/}
            {/*  endIcon={<KeyboardArrowDownIcon/>}*/}
            {/*  sx={{*/}
            {/*    position: 'absolute',*/}
            {/*    right: 0,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Erstelldatum*/}
            {/*</Button>*/}
            {/*<Box>*/}
            {/*  Juli 2024*/}
            {/*</Box>*/}
            {/*<Button*/}
            {/*  href="/dashboard/import/"*/}
            {/*  //variant="outlined"*/}
            {/*  size="small"*/}
            {/*  color="inherit"*/}
            {/*  // endIcon={<KeyboardArrowDownIcon/>}*/}
            {/*  disabled*/}
            {/*  sx={{color: 'black'}}*/}
            {/*>*/}
            {/*  Am 27. August 2024*/}
            {/*</Button>*/}
            {/*<OrderingMenuButton*/}
            {/*  size="small"*/}
            {/*  color="default"*/}
            {/*  endIcon={<KeyboardArrowDownIcon/>}*/}
            {/*  sx={{*/}
            {/*    position: 'absolute',*/}
            {/*    right: 25,*/}
            {/*    zIndex: 100,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Erstellungsdatum*/}
            {/*</OrderingMenuButton>*/}
            {/*<Typography gutterBottom sx={{mt: 1}}>*/}
            {/*  /!*Erstellt am 27. August 2024*!/*/}
            {/*  &nbsp;*/}
            {/*</Typography>*/}
            {renderIds?.map((id, i) => {
              const result = (
                <GemaGVLXMLRow
                  key={id || i}
                  id={id}
                  previousId={previousId}
                  expanded={expanded === id}
                  expandedUuid={expanded}
                  onChange={handleChange(id)}
                  renderCaptionType={ordering?.[0] === '-report_datum_bis' ? 'report_datum_bis' : ordering?.[0] === '-created_at' ? 'created_at' : null}
                />
              );
              previousId = id;
              return result;
            })}
          </>
        )}

        {!noDataExists && (
          <OnlyIfPermissions perm_write_reports>
            <Box mt={8.5} mx={1.5} textAlign="right">
              <Tooltip title="Neue Sendemeldung hochladen">
                <HrefComponent
                  component={Fab}
                  href="/dashboard/import/"
                  style={{
                    position: 'fixed',
                    right: 20,
                    bottom: 20,
                  }}
                  color="primary"
                  aria-label="add"
                >
                  <Add/>
                </HrefComponent>
              </Tooltip>
            </Box>
          </OnlyIfPermissions>
        )}
      </Box>
    </DashboardLayout>
);
}
