import React from "react";
import {
  useEntityApi,
  useEntityObserver,
  useOrgMusicWork,
  useSelectedOrganization
} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getOrgMusicWorkLogGetter, getOrgMusicWorkLogsGetter} from "src/features/entity";
import NaturalTime from "src/packages/natural-time-view";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {IconButton, Typography} from "@mui/material";
import {Add, Clear, Edit, ImportExport, Link, LinkOff, Remove, ThumbUp, Undo} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import {MUSIK_PERSON_ROLLE_KNZ, MUSIK_PRODUKTION_ID_TYP_KNZ} from "src/features/dashboard/dashboard-validation";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

const useStyles = makeStyles({
  date: {
    paddingTop: '10px',
  },
  content: {
    marginTop: '5px',
  },
});

function IdentifierRelatedTimelineContent({
  action,
  initiator,
  details
}) {
  details = details?.identifier;

  let id_typ_knz;
  if (action === 'C') {
    if (details?.old?.id_typ_knz === details?.new?.id_typ_knz) {
      id_typ_knz = details?.new?.id_typ_knz;
    }
  } else {
    id_typ_knz = details?.id_typ_knz;
  }

  const type = MUSIK_PRODUKTION_ID_TYP_KNZ[id_typ_knz] || "Identifier";

  return (
    <>
      <Typography variant="h6" component="h1">
        {action === 'A' ? (
          `${type} hinzugefügt`
        ) : action === 'C' ? (
          `${type} bearbeitet`
        ) : action === 'D' ? (
          `${type} entfernt`
        ) : action === 'Q' ? (
          `Schnelllösung auf ${type} angewendet`
        ) : action}
      </Typography>
    </>
  );
}

function PersonRelatedTimelineContent({
  action,
  initiator,
  details
}) {
  details = details?.person;

  let musik_person_rolle_knz;
  if (action === 'C') {
    if (details?.old?.musik_person_rolle_knz === details?.new?.musik_person_rolle_knz) {
      musik_person_rolle_knz = details?.new?.musik_person_rolle_knz;
    }
  } else {
    musik_person_rolle_knz = details?.musik_person_rolle_knz;
  }

  const type = MUSIK_PERSON_ROLLE_KNZ[musik_person_rolle_knz] || "Person";

  return (
    <>
      <Typography variant="h6" component="h1">
        {action === 'A' ? (
          `${type} hinzugefügt`
        ) : action === 'C' ? (
          `${type} bearbeitet`
        ) : action === 'D' ? (
          `${type} entfernt`
        ) : action === 'Q' ? (
          `Schnelllösung auf ${type} angewendet`
        ) : action}
      </Typography>
    </>
  );
}

function ChangeItem({
  id,
  orgMusicWorkId,
  last
}) {
  const getOrgMusicWorkLog = useSelector(getOrgMusicWorkLogGetter);
  const {
    created_at,
    action,
    initiator,
    details
  } = getOrgMusicWorkLog(id);

  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  //const {organization} = useOrgMusicWork({id: orgMusicWorkId, observe: false});
  const {id: organizationId} = useSelectedOrganization();

  const resetLinkSuggestion = async (gvlProductId) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organizationId}/org_music_works/${orgMusicWorkId}/reset_gvl_produkt_suggestion/`,
      {gvlProductId},
    );
  };

  const classes = useStyles();

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography color="textSecondary" className={classes.date}>
          <NaturalTime date={created_at}/>
        </Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        {action === 'C' ? (
          <TimelineDot>
            <Edit/>
          </TimelineDot>
        ) : action === 'I' ? (
          <TimelineDot>
            <ImportExport/>
          </TimelineDot>
        ) : action === 'L' ? (
          <TimelineDot color="primary">
            <Link/>
          </TimelineDot>
        ) : action === 'U' ? (
          <TimelineDot>
            <LinkOff/>
          </TimelineDot>
        ) : action === 'R' ? (
          <TimelineDot>
            <Clear/>
          </TimelineDot>
        ) : action === 'A' ? (
          <TimelineDot>
            <Add/>
          </TimelineDot>
        ) : action === 'D' ? (
          <TimelineDot>
            <Remove/>
          </TimelineDot>
        ) : action === 'Q' ? (
          <TimelineDot>
            <ThumbUp/>
          </TimelineDot>
        ) : (
          <TimelineDot/>
        )}
        {!last ? (
          <TimelineConnector/>
        ) : null}
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        {details?.identifier ? (
          <IdentifierRelatedTimelineContent action={action} details={details} initiator={initiator}/>
        ) : details?.person ? (
          <PersonRelatedTimelineContent action={action} details={details} initiator={initiator}/>
        ) : (
          <Typography variant="h6" component="h1">
            {action === 'C' ? (
              details?.erschienene_tonaufnahme ? (
                "Erschienene Tonaufnahme bearbeitet"
              ) : (
                "Produktion bearbeitet"
              )
            ) : action === 'I' ? (
              "Produktion aus GEMAGVL4-Daten importiert"
            ) : action === 'L' ? (
              "Referenzdaten zugeordnet"
            ) : action === 'U' ? (
              "Referenzdatenzuordnung entfernt"
            ) : action === 'R' ? (
              <>
                Vorschlag für Referenzdatenzuordnung abgelehnt
                {details?.gvl_produkt_id ? (
                  <>
                    <OnlyIfPermissions perm_write_music>
                      {' '}
                      <IconButton
                        onClick={() => {
                          resetLinkSuggestion(details?.gvl_produkt_id);
                        }}
                      >
                        <Undo/>
                      </IconButton>
                      </OnlyIfPermissions>
                  </>
                ) : null}
              </>
            ) : action === 'A' ? (
              "Angaben hinzugefügt"
            ) : action === 'D' ? (
              details?.erschienene_tonaufnahme ? (
                "Erschienene Tonaufnahme entfernt"
              ) : (
                "Angaben entfernt"
              )
            ) : action === 'Q' ? (
              "Schnelllösung angewendet"
            ) : action}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}

export default function MusicWorkChangeHistory({id}) {
  useEntityObserver({
    type: 'org_music_work_logs',
    id
  });

  const orgMusicWorkId = id;

  const getOrgMusicWorkLogs = useSelector(getOrgMusicWorkLogsGetter);
  const {logs} = getOrgMusicWorkLogs(id);

  return (
    <Timeline>
      {logs?.map((id, i) => (
        <ChangeItem key={id || i} orgMusicWorkId={orgMusicWorkId} id={id} last={i === logs?.length - 1}/>
      ))}
    </Timeline>
  );
}
