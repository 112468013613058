import React from "react";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import DatabaseRightTable from "src/components/entities/database/DatabaseRightTable";

export default function DatabaseRightsPage({
  databaseId,
}) {
  useRequirePermissions({perm_read_music: true});

  const organizationId = useSelector(getSelectedOrganizationId);

  return (
    <DatabaseRightTable
      organizationId={organizationId}
      databaseId={databaseId}
    />
  );
}
