import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {getSessionContact} from "src/features/dashboard";
import {ChangePasswordForm} from "src/components/entities/person/ChangePasswordForm";
import {ChangeEmailForm} from "src/components/entities/person/ChangeEmailForm";
import {Add, Delete} from "@mui/icons-material";
import {Disable2FAForm} from "src/components/entities/person/Disable2FAForm";
import {Setup2FAForm} from "src/components/entities/person/Setup2FAForm";
import {SettingAccordion} from "src/components/layout/components/SettingAccordion";

export default function SecurityPage() {
  const {
    id,
    organization,
    username,
    email,
    totp_devices
  } = useSelector(getSessionContact);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [editPassword, setEditPassword] = useState(null);
  const [editEmail, setEditEmail] = useState(null);
  const [editDisable2fa, setEditDisable2fa] = useState(null);
  const [editSetup2fa, setEditSetup2fa] = useState(null);

  const has_2fa = totp_devices?.length > 0;
  const no_2fa = totp_devices?.length === 0;

  return (
    <DashboardLayout
      titlePrefix="Sicherheit"
      selectedPage="security"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h6">
          Mein Konto / Sicherheit
        </Typography>
      </Box>

      <Box sx={{width: '100%'}}>
        <SettingAccordion
          id="username"
          expanded={expanded === 'username'}
          onChange={handleChange('username')}
          onEdit={() => {
            setEditEmail({
              id,
              email,
              new_username: username
            });
          }}
          title="E-Mail-Adresse"
          description={(
            <>
              {username}
            </>
          )}
        >
          <p>Ihre E-Mail-Adresse ist zugleich Ihr
            {' '}
            <strong>Benutzername</strong>.</p>
        </SettingAccordion>

        <SettingAccordion
          id="password"
          expanded={expanded === 'password'}
          onChange={handleChange('password')}
          onEdit={() => {
            setEditPassword({
              id,
              organization,
              email,
              new_username: username
            });
          }}
          title="Passwort"
          description={(
            <>
              (wird aus Sicherheitsgründen nicht angezeigt)
            </>
          )}
        >
        </SettingAccordion>

        <SettingAccordion
          id="2fa"
          expanded={expanded === '2fa'}
          onChange={handleChange('2fa')}
          title="Login-Sicherheit"
          description={(
            <>
              {has_2fa ? (
                "Zwei-Faktor-Authentifizierung aktiv"
              ) : no_2fa ? (
                "klassisch (Benutzername und Passwort)"
              ) : null}
            </>
          )}
        >
          {has_2fa ? (
            <div>
              <p>
                Sie haben die Zwei-Faktor-Authentifizierung aktiviert.
                Um sich einzuloggen, benötigen Sie neben Ihrem Benutzernamen und ein Passwort jeweils einen Einmalcode,
                den Ihr Smartphone generiert.
              </p>
              <p>
                Um den zweiten Faktor zu ändern &ndash; etwa infolge eines Smartphone-Wechsels &ndash; können Sie die
                Zwei-Faktor-Authentifizierung vorübergehend deaktivieren.
              </p>
              <Button
                startIcon={<Delete/>}
                onClick={() => {
                  setEditDisable2fa({
                    id,
                    delete_totp_devices: true
                  });
                }}
                color="primary"
                variant="outlined"
              >
                Zwei-Faktor-Authentifizierung deaktivieren
              </Button>
            </div>
          ) : (
            <div>
              <p>
                Derzeit benötigen Sie lediglich Ihren Benutzernamen und Ihr Passwort, um sich einzuloggen.
              </p>
              <p>
                Um die Sicherheit Ihres Kontos zu erhöhen, können Sie die Zwei-Faktor-Authentifizierung einrichten.
                Neben Ihrem Passwort benötigen Sie dann zusätzlich einen Einmalcode, der von Ihrem Smartphone generiert
                wird, um sich einzuloggen.
              </p>
              <Button
                startIcon={<Add/>}
                onClick={() => {
                  setEditSetup2fa({id});
                }}
                color="primary"
                variant="outlined"
              >
                Zwei-Faktor-Authentifizierung einrichten
              </Button>
            </div>
          )}
        </SettingAccordion>
      </Box>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editPassword ? (
        <ChangePasswordForm
          data={editPassword}
          onClose={() => setEditPassword(null)}
        />
      ) : null}
      {editEmail ? (
        <ChangeEmailForm
          data={editEmail}
          onClose={() => setEditEmail(null)}
        />
      ) : null}
      {editDisable2fa ? (
        <Disable2FAForm
          data={editDisable2fa}
          onClose={() => setEditDisable2fa(null)}
        />
      ) : null}
      {editSetup2fa ? (
        <Setup2FAForm
          data={editSetup2fa}
          onClose={() => setEditSetup2fa(null)}
        />
      ) : null}
    </DashboardLayout>
  );
}
