import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Alert, AlertTitle, Box, Paper,} from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import Tab from "@mui/material/Tab";
import {Album, Audiotrack, GridView} from "@mui/icons-material";
import LabelTable from "src/components/entities/refdata/LabelTable";
import GVLProduktTable from "src/components/entities/refdata/GVLProduktTable";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import GEMAWerkTable from "src/components/entities/refdata/GEMAWerkTable";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function RefDataPage({
  '*': subPage,
  ...props
}) {
  useRequirePermissions({perm_read_music: true});

  const {
    gema_api_enabled
  } = useSelector(getSelectedOrganization);

  const classes = useStyles();
  const value = subPage === 'labels' ? 1 : subPage === 'gvl' ? 2 : subPage === 'gema' ? 3 : 0;

  return (
    <DashboardLayout
      titlePrefix="Referenzdaten"
      selectedPage="refdata"
    >
      <Box my={2}>
        <Typography variant="h6">
          Referenzdaten
        </Typography>
      </Box>

      <div className={classes.root}>
        <Paper>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <HrefComponent
                component={Tab}
                href={`/dashboard/refdata/`}
                icon={<GridView/>}
                label={(
                  <div>
                    Übersicht
                  </div>
                )}
                {...a11yProps(0)}
              />
              <HrefComponent
                component={Tab}
                href={`/dashboard/refdata/labels/`}
                icon={<Album/>}
                label={(
                  <div>
                    Label-Recherche
                  </div>
                )}
                {...a11yProps(1)}
              />
              <HrefComponent
                component={Tab}
                href={`/dashboard/refdata/gvl/`}
                icon={<Audiotrack/>}
                label={(
                  <div>
                    GVL-Datenbank
                  </div>
                )}
                {...a11yProps(2)}
              />
              {gema_api_enabled ? (
                <HrefComponent
                  component={Tab}
                  href={`/dashboard/refdata/gema/`}
                  icon={<Audiotrack/>}
                  label={(
                    <div>
                      GEMA-Werkrecherche
                    </div>
                  )}
                  {...a11yProps(2)}
                />
              ) : null}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Alert severity="info">
              <AlertTitle>Bereitstellung von Referenzdaten</AlertTitle>
              <Typography gutterBottom>
                Um eine möglichst hohe Meldequalität erreichen zu können, gleicht Sendemeldung.de Ihre Sendemeldungen
                automatisch mit Referenzdaten ab, die von den Verwertungsgesellschaften zur Verfügung gestellt wurden.
              </Typography>
              <Typography gutterBottom>
                Sobald Referenzdaten für Ihre Musikproduktionen zur Verfügung stehen, werden Sie hierüber
                {' '}
                <strong>automatisch</strong> informiert.
                Ihnen wird dann angeboten, Ihre bestehenden Metadaten entsprechend zu vervollständigen.
              </Typography>
              <Typography gutterBottom>
                In unseren Werkzeugen zur Musikdatenbank-Pflege innerhalb Sendemeldung.de sind die verfügbaren
                Referenzdaten bereits integriert: Wenn Sie Metadaten nachtragen, erhalten Sie automatisch entsprechende
                Vorschläge.
              </Typography>
              <Typography>
                Auf dieser Seite haben Sie darüber hinaus die Möglichkeit, unabhängig von Ihrer konkreten Musikdatenbank
                zu recherchieren &ndash; etwa dann, wenn Sie Daten für Ihr Sendesystem nachschlagen möchten.
              </Typography>
            </Alert>
            <Alert severity="warning" sx={{mt: 2}}>
              <AlertTitle>Bitte haben Sie Geduld...</AlertTitle>
              <Typography>
                Die automatische Bereitstellung von Referenzdaten erfolgt <strong>schrittweise</strong> in enger
                Abstimmung mit den Verwertungsgesellschaften.
                Zunächst werden diejenigen Produktionen ergänzt, für die bereits
                eine gute Datenqualität im Sinne der GEMAGVL4-Schnittstelle vorhanden ist &ndash; hier ist die
                Zuordnung von Referenzdaten oft <strong>eindeutig</strong> möglich.
                In den kommenden Monaten integrieren wir weitere Daten und bauen diese Funktionen weiter aus,
                um Hilfestellungen auch dann anbieten zu können, wenn Identifier bislang vollständig fehlen.
              </Typography>
            </Alert>
          </TabPanel>
          <TabPanel value={value} index={1} style={{marginTop: -20}}>
            <LabelTable/>
          </TabPanel>
          <TabPanel value={value} index={2} style={{marginTop: -20}}>
            <GVLProduktTable/>
          </TabPanel>
          <TabPanel value={value} index={3} style={{marginTop: -20}}>
            <GEMAWerkTable/>
          </TabPanel>
        </Paper>
      </div>

    </DashboardLayout>
  );
}
