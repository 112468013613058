import {Box, Chip, Skeleton, TableCell, TableRow, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Unshrinkable} from "src/packages/unshrinkable";
import NaturalTime from "src/packages/natural-time-view";
import React from "react";
import ErschieneneTonaufnahme from "./components/ErschieneneTonaufnahme";
import MusikTitel from "src/components/entities/musicwork/components/MusikTitel";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import {useErschieneneTonaufnahme, useOrgMusicWork} from "src/features/entity/entity-hooks";
import Alert from '@mui/material/Alert';
import HrefIconButton from "src/packages/gatsby-mui-helpers/HrefIconButton";
import MusikProduktionIds from "src/components/entities/musicwork/components/MusikProduktionIds";
import MusikInterpreten from "src/components/entities/musicwork/components/MusikInterpreten";
import MusikUrheber from "src/components/entities/musicwork/components/MusikUrheber";
import MusikHerkunft from "src/components/entities/musicwork/components/MusikHerkunft";
import GVLLinkIndicator from "src/components/entities/musicwork/components/GVLLinkIndicator";

export default function MusicWorkRow({
  id,
  onEdit
}) {
  const {
    id: orgMusicWorkId,
    created_at,
    updated_at,
    erschienene_tonaufnahme: erschienene_tonaufnahme_id,
    todo_tasks,
    isDeleted,
    is_tentative: isTentative,
    linked_gvl_produkt: linkedGVLProduktId,
    needs_erschienene_tonaufnahme: needsErschieneneTonaufnahme,
    database,
  } = useOrgMusicWork({id});

  const {is_empty: erschieneneTonaufnahmeIsEmpty} = useErschieneneTonaufnahme({id: erschienene_tonaufnahme_id, observe: false});

  const hasTasks = todo_tasks?.length > 0;

  if (isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={7}>
          <Alert variant="filled" color="error" severity="success">
            Diese Musikproduktion wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          {!orgMusicWorkId ? (
            <Skeleton variant="text"/>
          ) : (
            <MusikTitel id={orgMusicWorkId} createLink/>
          )}
        </TableCell>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikUrheber id={id} hideEdit/>
        </TableCell>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikInterpreten id={id} hideEdit/>
        </TableCell>
        <TableCell align="center" style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikProduktionIds id={id} hideEdit abbreviated/>
        </TableCell>
        <TableCell align="center" style={hasTasks ? {borderBottom: "none"} : null}>
          {(erschieneneTonaufnahmeIsEmpty && !needsErschieneneTonaufnahme) ? (
            <>
              <MusikHerkunft id={id}/>
            </>
          ) : (
            <>
              <MusikHerkunft id={id} showOnlyButton={needsErschieneneTonaufnahme}/>
              <ErschieneneTonaufnahme
                id={erschienene_tonaufnahme_id}
                orgMusicWorkId={orgMusicWorkId}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center" style={hasTasks ? {borderBottom: "none"} : null}>
          {updated_at ? (
            <>
              {isTentative ? (
                <Tooltip
                  title="Diese Musikproduktion wurde automatisch aus GEMAGVL4 importiert. Es wurden noch keine manuellen Änderungen vorgenommen."
                >
                  <span>
                  <Unshrinkable><NaturalTime date={created_at}/></Unshrinkable>
                  <br/>
                  <span>erfasst</span>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title="An dieser Musikproduktion wurden manuelle Änderungen durchgeführt. Diese bleiben in Sendemeldung.de gespeichert, auch wenn Sie die zugehörigen Lieferungen löschen. Ihre Änderungen werden auf neu importierte GEMAGVL4-Meldungen automatisch angewendet."
                >
                  <span>
                  <Unshrinkable><NaturalTime date={updated_at}/></Unshrinkable>
                  <br/>
                  <Chip label="geändert" size="small"/>
                    </span>
                </Tooltip>
              )}
            </>
          ) : null}
          {linkedGVLProduktId ? (
            <Box mt={1}>
              <GVLLinkIndicator id={id} label="Referenzdaten"/>
            </Box>
          ) : null}
        </TableCell>
        <TableCell align="right" style={hasTasks ? {borderBottom: "none"} : null}>
          <HrefIconButton
            aria-label="view"
            // onClick={onEdit ? (() => onEdit({id, organization, musik_titel, recht_knz, besetzung_knz, gattung, nutzung_art_knz, musik_herkunft_knz, aufnahme_datum, prod_titel, prod_ort, erschienene_tonaufnahme})) : null}
            href={`/dashboard/database/${database}/${id}`}
          >
            <Search/>
          </HrefIconButton>
        </TableCell>
      </TableRow>
      {hasTasks ? (
        <TableRow>
          <TableCell/>
          <TableCell colSpan={5}>
            <TodoTasks ids={todo_tasks}/>
          </TableCell>
          <TableCell/>
        </TableRow>
      ) : null}
    </>
  );
}
