import {Alert, Chip, IconButton, TableCell, TableRow} from "@mui/material";
import {Check, Edit} from "@mui/icons-material";
import React from "react";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {useDatabaseRight, useOrganization} from "src/features/entity/entity-hooks";

export default function DatabaseRightRow({
  databaseRightId,
  editDatabaseRight,
  organizationId,
}) {
  const right = useDatabaseRight({id: databaseRightId});
  const organization = useOrganization({id: right?.organization});

  if (right?.isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <Alert variant="filled" color="error" severity="success">
            Diese Berechtigung wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {organization?.company_name}
      </TableCell>
      <TableCell align="center">
        {right?.can_write ? (
          <>
            <Chip
              icon={<Check/>}
              size="small"
              label="Vollzugriff"
              color="secondary"
            />
          </>
        ) : (
          <>
            <Chip
              icon={<Check/>}
              size="small"
              label="Zugriff auf bestehende Einträge"
              color="secondary"
            />
          </>
        )}
      </TableCell>
      <TableCell align="right">
        {right?.organization !== organizationId ? (
          <OnlyIfPermissions perm_write_people_and_rights>
            <IconButton
              aria-label="edit"
              onClick={() => editDatabaseRight(databaseRightId)}
              size="large">
              <Edit/>
            </IconButton>
          </OnlyIfPermissions>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
