import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {SETUP_2FA_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {CircularProgress, DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React, {useEffect} from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {CONTACT} from "src/api/api-schemas";
import {useFormContext} from "react-hook-form";
import axios from "axios";
import QRCode from "react-qr-code";
import { Alert } from '@mui/material';
import {Link} from "gatsby-theme-material-ui";

function Setup2FAFormDialogContent({}) {
  const {watch, setValue} = useFormContext();

  const id = watch('id');

  const key = watch('tfa_key');
  const digits = watch('tfa_digits');
  const step = watch('tfa_step');
  const config_url = watch('tfa_config_url');

  const hasInitData = !!(key && digits && step && config_url);

  const error = watch('tfa_error');

  useEffect(async () => {
    if (!id) {
      return;
    }

    if (key || error) {
      return;
    }

    try {
      const result = await axios.get(`/api/sendemeldung/contacts/${id}/init_totp/`);

      const {key, digits, step, config_url} = result.data;

      setValue('tfa_key', key);
      setValue('tfa_digits', digits);
      setValue('tfa_step', step);
      setValue('tfa_config_url', config_url);
    } catch (e) {
      setValue('tfa_error', e);
    }
  }, [id, key, error]);

  return (
    <DialogContent>
      <Grid container spacing={2}>
        {error ? (
          "Ein Fehler ist aufgetreten."
        ) : !hasInitData ? (
          <Grid item xs={12}>
            <CircularProgress size='1.5rem'/>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                Zwei-Faktor-Authentifizierung erhöht die Sicherheit Ihres Accounts, indem Ihre Identität anhand Ihres
                Smartphones überprüft wird, wenn Sie sich mit Benutzername und Passwort einloggen.
              </Alert>
            </Grid>

            <Grid item xs={6}>
              <QRCode value={config_url}/>
            </Grid>

            <Grid item xs={6}>
              Führen Sie folgende Schritte durch:
              <ol>
                <li>
                  Installieren Sie auf Ihrem Handy Microsoft Authenticator
                  {' '}
                  (<Link color="primary" href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="blank">Android</Link>
                  {' '}
                  /
                  {' '}
                  <Link color="primary" href="https://itunes.apple.com/app/id983156458" target="blank">iOS</Link>)
                  {' '}
                  oder eine vergleichbare App.
                </li>
                <li>
                  Fügen Sie ein Konto hinzu und scannen Sie den QR-Code.
                </li>
                <li>
                  Geben Sie den angezeigten Code zur Bestätigung hier ein:
                  <YupField name="otp_token"/>
                </li>
              </ol>
            </Grid>
          </>
        )}
      </Grid>
    </DialogContent>
  );
}

export function Setup2FAForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(CONTACT);

  const saveContact = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/contacts/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'setup-2fa'}
      title={"Zwei-Faktor-Authentifizierung einrichten"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveContact}
      schema={SETUP_2FA_SCHEMA}
      saveCaption={"Fertig, jetzt aktivieren!"}
      cancelCaption={"Abbrechen"}
      {...props}
    >
      <Setup2FAFormDialogContent
        data={data}
      />
    </SimpleYupFormDialog>
  );
}
