import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function GEMAWerkFilter({...props}) {
  return (
    <FancyFilterField
      options={gemaWerkFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="GEMA-Werkrecherche..."
      {...props}
    />
  );
}

const gemaWerkFilterOptions = [
];
