import {DATABASE_RIGHT_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {DATABASE_RIGHT} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {selectOrganizations} from "src/features/orm/selectors";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

function DatabaseRightFormDialogContent({
  data,
}) {
  const organizations = useSelector(selectOrganizations);

  let options = {};

  organizations?.forEach(({
    id,
    customer_id,
    render_name
  }) => {
    options[id] = `${render_name} (#${customer_id})`;
  });

  return (
    <DialogContent>
      <Grid container spacing={2}>
        {!data?.id ? (
          <Grid item xs={12}>
            <YupField
              name="organization"
              items={options}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <YupField name="can_write"/>
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function DatabaseRightForm({
  data,
  onClose,
  ...props
}) {
  const entityApi = useEntityApi(DATABASE_RIGHT);

  const {id: organizationId} = useSelectedOrganization();
  const {
    database
  } = data;

  const saveDatabaseRight = async (validatedData) => {
    const {
      id,
      database,
    } = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organizationId}/owned_databases/${database}/rights/${id}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organizationId}/owned_databases/${database}/rights/`,
        validatedData,
        {
          createEntities: true,
          organization: organizationId,
        },
      );
    }

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      id={'edit-database-right'}
      entityType="database_rights"
      baseUrl={`/api/sendemeldung/organizations/${organizationId}/owned_databases/${database}/rights/`}
      title={data?.id ? "Datenbankberechtigung bearbeiten" : "Datenbankberechtigung anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveDatabaseRight}
      allowDelete
      deleteConfirmation="Datenbankberechtigung wirklich löschen?"
      deleteCaption="Berechtigung löschen"
      schema={DATABASE_RIGHT_SCHEMA}
      {...props}
    >
      <DatabaseRightFormDialogContent
        data={data}
      />
    </EntityFormDialog>
  );
}
