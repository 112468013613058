import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, {useRef} from "react";
import {useSelector} from "react-redux";
import {getGVLProductGetter} from "src/features/entity";
import GVLProductPerson from "src/components/entities/musicwork/components/GVLProductPerson";
import GVLProduktSummary from "src/components/entities/refdata/GVLProduktSummary";

export function GVLProduktPersonenDialog({
  id,
  open,
  onClose,
  ...props
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const ref = useRef();

  const elId = `gvl_produkt_urheber_${id}`;

  const {
    personen,
  } = useSelector(getGVLProductGetter)(id);

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby={elId}
        fullScreen={fullScreen}
        container={ref.current}
        style={{whiteSpace: 'normal'}}
        {...props}>
        <DialogTitle id={elId}>
          Urheber zu GVL-Produkt
        </DialogTitle>

        <DialogContent>
          <GVLProduktSummary id={id}/>

          <Alert severity="warning" sx={{mt: 2}}>
            <AlertTitle>Hinweis</AlertTitle>
            <Typography gutterBottom>
              Angaben zu Urhebern / Komponisten werden in der GVL-Datenbank lediglich zur Herstellung von Zuordnungen
              verwendet.
            </Typography>
            <Typography>
              Diese Daten sind nur bedingt moderiert und es können doppelte und fehlerhafte Einträge
              existieren, die nicht ungeprüft übernommen werden sollten.
            </Typography>
          </Alert>

          <Box pt={2}>
            <Typography variant="subtitle2">Urheber</Typography>
            {personen?.filter(({rolle}) => rolle === 'K')
              ?.map((el, i) => (
                <Typography key={i}>
                  <GVLProductPerson gvlProductPerson={el}/>
                </Typography>
              ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="default"
            onClick={onClose}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
