import {useSelector} from "react-redux";
import {getSessionToken} from "../features/session";

export function useSessionTokenUrl(url) {
  const sessionToken = useSelector(getSessionToken);

  // TODO: Handle more complex cases.
  if (url.indexOf('?') > 0) {
    return `${url}&token=${sessionToken}`;
  } else {
    return `${url}?token=${sessionToken}`;
  }
}
