import makeStyles from "@mui/styles/makeStyles";
import {Accordion, AccordionDetails, AccordionSummary, Grid, IconButton} from "@mui/material";
import {Edit, ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    flexBasis: '33.33%',
  },
  multiColumn: {
    flexBasis: '66.67%',
  },
}));

export function SettingAccordion({id, title, description, onEdit, children, ...props}) {
  const classes = useStyles();

  return (
    <Accordion {...props}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Grid container style={{alignItems: 'center'}}>
          <Grid item container xs={11} style={{alignItems: 'left'}}>
            <div className={classes.column}>
              <Typography className={classes.heading}>{title}</Typography>
            </div>
            <div className={classes.multiColumn}>
              <Typography className={classes.secondaryHeading} component="div">
                {description}
              </Typography>
            </div>
          </Grid>
          <Grid item container xs={1} style={{alignItems: 'right'}}>
            {onEdit ? (
              <IconButton
                aria-label="edit"
                onClick={onEdit ? ((event) => {
                  event.stopPropagation();
                  onEdit();
                }) : null}
                size="large">
                <Edit/>
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
