import React, {useState} from "react";
import _ from "lodash";
import {Box} from '@mui/material';
import {useFormContext} from "react-hook-form";
import axios from "axios";
import {useLazyEffect} from "src/packages/lazy-effect";
import {normalize} from "normalizr";
import {GEMA_WORK} from "src/api/api-schemas";
import {setEntities} from "src/features/entity";
import {useDispatch} from "react-redux";
import GEMAWerkSummary from "src/components/entities/refdata/GEMAWerkSummary";
import GVLProduktSummary2 from "src/components/entities/refdata/GVLProduktSummary2";
import {FormTextFieldSuggestions} from "src/packages/react-hook-form-mui-form-fields";
import {useOrgMusicWork, useSelectedOrganization} from "src/features/entity/entity-hooks";

const TitleSuggestionsField = ({
  name,
  onFocus,
  ...props
}) => {
  const {watch} = useFormContext();

  const id = watch('id');
  const {organization} = useOrgMusicWork({id});
  const {id: organizationId} = useSelectedOrganization();

  const [options, setOptions] = useState(null);

  const [focussedOnce, setFocussedOnce] = useState(false);

  const dispatch = useDispatch();

  useLazyEffect(async () => {
    if (!focussedOnce) {
      return;
    }
    try {
      setOptions(null);
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/org_music_works/${id}/suggested_titles/`);
      setOptions(result.data?.results || []);

      const gemaWorks = result.data?.gema_works;
      if (gemaWorks) {
        const normalizedEntities = normalize(gemaWorks, [GEMA_WORK]);
        dispatch(setEntities(normalizedEntities));
      }
    } catch (e) {
      console.error(e);
      setOptions([]);
    }
  }, [name, id, organizationId, focussedOnce], 300);

  return (
    <FormTextFieldSuggestions
      name={name}
      suggestions={options}
      autocompleteProcessOnChangeData={(data) => {
        if (!data) {
          return null;
        }

        return data[name];
      }}
      autocompleteProps={{
        isOptionEqualToValue: (option, value) => option[name] === value,
        getOptionLabel: (option) => (_.isString(option) || _.isInteger(option)) ? `${option}` : `${option[name]}`,
        renderOption: (props, option, {selected}) => (
          <li {...props}>
            <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column">
              <Box>
                {option?.musik_titel}
              </Box>
              {option?.source?.gema_work_id ? (
                <Box pl={2} py={1} display="flex" justifyContent="center" alignItems="start">
                  <Box pr={1} pt={1} color="gray">
                    Quelle:
                  </Box>
                  <GEMAWerkSummary id={option?.source?.gema_work_id}/>
                </Box>
              ) : null}
              {option?.source?.gvl_produkt_id ? (
                <Box pl={2} py={1} display="flex" justifyContent="center" alignItems="start">
                  <Box pr={1} pt={1} color="gray">
                    Quelle:
                  </Box>
                  <GVLProduktSummary2 id={option?.source?.gvl_produkt_id}/>
                </Box>
              ) : null}
            </Box>
          </li>
        ),
      }}
      onFocus={(e) => {
        setFocussedOnce(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      {...props}
    />
  );
};

export default TitleSuggestionsField;
