import {ORGANIZATION_CONTACT_ROLE_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  DialogContent,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React, {useEffect} from "react";
import {useEntityApi, useOrganization} from "src/features/entity/entity-hooks";
import {CONTACT_ROLE} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getContactGetter} from "src/features/entity";
import Typography from "@mui/material/Typography";
import {useFormContext} from "react-hook-form";
import {getProfile, hasProfileData} from "src/features/participation";
import {getPermissionGroup, getPermissionGroupById, PERMISSION_GROUPS_BY_TYPE} from "src/features/entity/user-utils";
import {KeyboardArrowDown} from "@mui/icons-material";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

function PersonFormPermissionsCard({isRequestUser}) {
  const {
    watch,
    setValue
  } = useFormContext();

  const organization = watch('organization');
  const {
    type,
    supports_music,
    supports_reports,
    supports_stations
  } = useOrganization({id: organization});

  const permReadReports = watch('perm_read_reports');
  const permWriteReports = watch('perm_write_reports');
  const permSubmitReports = watch('perm_submit_reports');

  const permReadPeople = watch('perm_read_people');
  const permWritePeopleAndRights = watch('perm_write_people_and_rights');

  const permReadDocuments = watch('perm_read_documents');
  const permWriteDocuments = watch('perm_write_documents');

  const permWriteMusic = watch('perm_write_music');

  useEffect(() => {
    if ((!permReadReports || !permWriteMusic) && permWriteReports) {
      setValue('perm_write_reports', false);
    }
  }, [permReadReports, permWriteReports, permWriteMusic]);

  useEffect(() => {
    if (!permWriteReports && permSubmitReports) {
      setValue('perm_submit_reports', false);
    }
  }, [permWriteReports, permSubmitReports]);

  useEffect(() => {
    if (!permReadPeople && permWritePeopleAndRights) {
      setValue('perm_write_people_and_rights', false);
    }
  }, [permReadPeople, permWritePeopleAndRights]);

  useEffect(() => {
    if (!permReadDocuments && permWriteDocuments) {
      setValue('perm_write_documents', false);
    }
  }, [permReadDocuments, permWriteDocuments]);

  const permissionGroup = getPermissionGroup(type, {
    perm_read_reports: permReadReports,
    perm_write_reports: permWriteReports,
    perm_submit_reports: permSubmitReports,
    perm_write_music: watch('perm_write_music'),
    perm_write_details: watch('perm_write_details'),
    perm_write_stations: watch('perm_write_stations'),
    perm_read_people: permReadPeople,
    perm_write_people_and_rights: permWritePeopleAndRights,
    perm_read_documents: permReadDocuments,
    perm_write_documents: permWriteDocuments,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (groupId) => {
    const permissions = PERMISSION_GROUPS_BY_TYPE[type].filter(({id}) => id === groupId)[0].permissions;

    Object.entries(permissions)
      .forEach(([permName, permValue]) => {
        setValue(permName, permValue);
      });

    closeMenu();
  };

  return (
    <Card>
      <CardContent sx={{position: 'relative'}}>
        <Typography color="textSecondary" gutterBottom>
          Zugriffsrechte
        </Typography>

        <Button
          aria-label="permission level of user"
          aria-controls="permissions-choice-menu"
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
          endIcon={<KeyboardArrowDown/>}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          <Typography variant="h6" sx={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}>
            {permissionGroup?.name}
          </Typography>
        </Button>

        <Menu
          id="permissions-choice-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={closeMenu}
        >
          {PERMISSION_GROUPS_BY_TYPE[type].map(({
            id,
            name,
            description,
            isCustom,
            permissions
          }, i) => (
            (!isCustom || id === permissionGroup?.id) ? (
              <MenuItem
                key={id || i}
                selected={id === permissionGroup?.id}
                onClick={() => handleSelect(id)}
              >
                <ListItemText
                  primary={name}
                  secondary={description}
                />
              </MenuItem>
            ) : null
          ))}
        </Menu>

        {supports_reports ? (
          <Grid container pt={2}>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Nutzer darf Sendemeldungen...
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Ermöglicht dem Nutzer Lesezugriff auf sämtliche Sendemeldungen / Lieferungen, die für Ihr
                    Sendeunternehmen hochgeladen wurden.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_read_reports" label="lesen"/>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Erlaubt dem Nutzer, neue GEMAGVL4-Dateien hochzuladen, bestehende Lieferungen, die sich im Entwurf
                    befinden, zu löschen, sowie Korrekturen an Ausstrahlungen durchzuführen.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_write_reports" label="bearbeiten"
                            disabled={!watch('perm_read_reports') || !watch('perm_write_music')}/>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Nutzer mit dieser Berechtigung können Lieferungen zur Übermittlung an GEMA/GVL freigeben.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_submit_reports" label="übermitteln" disabled={!watch('perm_write_reports')}/>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}

        {supports_music ? (
          <Grid container pt={2}>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Nutzer darf Musik...
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Alle loginberechtigten Nutzer Ihres Sendeunternehmens dürfen lesend auf Ihre Musikdatenbank
                    zugreifen.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_read_music" label="lesen" disabled/>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Ermöglicht die Bearbeitung / Pflege von Musik-Metadaten.
                    <br/>
                    Bitte beachten Sie, dass diese Berechtigung Voraussetzung für das Hochladen von Sendemeldungen ist,
                    da beim Import aus GEMAGVL4 neue Musikproduktionen in Ihre Musikdatenbank importiert werden können
                    müssen.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_write_music" label="bearbeiten"/>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}

        <Grid container pt={2}>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Nutzer darf Stammdaten Ihres Unternehmens...
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              followCursor
              title={(
                <>
                  Alle loginberechtigten Nutzer Ihres Sendeunternehmens dürfen Ihre Stammdaten einsehen.
                </>
              )}
            >
              <Box>
                <YupField name="perm_read_details" label="lesen" disabled/>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              followCursor
              title={(
                <>
                  Ermöglicht die Bearbeitung Ihrer Stammdaten und sonstiger unternehmensweiter Einstellungen. Hierzu
                  gehören Ihre Adress- und Abrechnungsdaten sowie die Einstellungen für den GEMAGVL4-Import.
                </>
              )}
            >
              <Box>
                <YupField name="perm_write_details" label="bearbeiten"/>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>

        {supports_stations ? (
          <Grid container pt={2}>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Nutzer darf Sender / Programme...
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Alle loginberechtigten Nutzer Ihres Sendeunternehmens dürfen Ihre Sender / Programme sehen.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_read_stations" label="lesen" disabled/>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              <Tooltip
                followCursor
                title={(
                  <>
                    Erlaubt das Anlegen neuer sowie die Bearbeitung existierender Programme Ihres Sendeunternehmens.
                  </>
                )}
              >
                <Box>
                  <YupField name="perm_write_stations" label="bearbeiten"/>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}

        <Grid container pt={2}>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Nutzer darf Personen / Nutzer...
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              followCursor
              title={(
                <>
                  Ermöglicht dem Nutzer, zu sehen, welche anderen Personen zu Ihrem Sendeunternehmen gehören und
                  über Zugriffsrechte verfügen.
                </>
              )}
            >
              <Box>
                <YupField name="perm_read_people" label="lesen" disabled={!!isRequestUser}/>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              followCursor
              title={(
                <>
                  Erlaubt dem Nutzer, Zugriffsberechtigungen für sich und andere Nutzer Ihres Sendeunternehmens beliebig
                  zu verwalten.
                  <br/>
                  Bitte beachten Sie, dass Nutzer, die über diese Berechtigung verfügen, sich selbst beliebige weitere
                  Berechtigungen einräumen können.
                </>
              )}
            >
              <Box>
                <YupField name="perm_write_people_and_rights" label="bearbeiten und Rechte verwalten"
                          disabled={!!isRequestUser || !watch('perm_read_people')}/>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container pt={2}>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Nutzer darf Vertragsdokumente und Nachrichten...
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              followCursor
              title={(
                <>
                  Erlaubt dem Nutzer, auf Vertragsdokumente und Nachrichten zuzugreifen, die durch Sendemeldung.de oder
                  die Verwertungsgesellschaften bereitgestellt wurden.
                </>
              )}
            >
              <Box>
                <YupField name="perm_read_documents" label="lesen"/>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              followCursor
              title={(
                <>
                  Ermöglicht dem Nutzer, neue Dokumente und Nachrichten hochzuladen bzw. zu verfassen und im Auftrag
                  Ihres Sendeunternehmens beispielsweise an die Verwertungsgesellschaften zu übermitteln.
                </>
              )}
            >
              <Box>
                <YupField name="perm_write_documents" label="bearbeiten" disabled={!watch('perm_read_documents')}/>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function PersonFormDialogContent({
  username,
  data,
  emailField
}) {
  const {
    watch,
    setValue
  } = useFormContext();

  const organizationId = watch('organization');
  const {type} = useOrganization({id: organizationId});

  const setMakeLogin = (role) => {
    let permissionGroup = getPermissionGroupById(type, role);
    if (!permissionGroup) {
      permissionGroup = getPermissionGroupById(type, 'reader');
    }

    setValue('contact.set_password', true);

    Object.entries(permissionGroup?.permissions || {})
      .forEach(([key, value]) =>
        setValue(key, value)
      );
  };
  const makeAdmin = watch('contact.set_password');

  const hasProfile = useSelector(hasProfileData);
  const {
    has_credentials: hasCredentials,
    has_just_set_up_credentials: hasJustSetUpCredentials,
  } = useSelector(getProfile);

  const allowAccountCreation = !hasProfile || hasCredentials || hasJustSetUpCredentials;

  const isGl = watch('is_gl');
  const isTechnik = watch('is_technik');
  const isLIRA = watch('is_lira');

  const preferredRole = (
    isGl ? 'admin' : isTechnik ? 'editor' : isLIRA ? 'lira' : 'reader'
  );

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YupField name="contact.salutation"/>
        </Grid>
        <Grid item xs={6}>
          <YupField name="contact.title"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="contact.name"/>
        </Grid>
        {(username || !data?.id || !makeAdmin) ? emailField : null}

        {username ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Login-Berechtigung
                </Typography>
                Dieser Nutzer verfügt über eine Login-Berechtigung.
                <br/><br/>
                <Card mt={2}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Benutzername
                    </Typography>
                    <strong>{watch('new_username') || username}</strong>
                    {data?.is_request_user ? (
                      <>
                        {' '}
                        <Chip
                          size="small"
                          label="Sie sind als dieser Nutzer eingeloggt."
                          color="secondary"
                        />
                      </>
                    ) : null}
                  </CardContent>
                </Card>
                <br/>
                <PersonFormPermissionsCard isRequestUser={data?.is_request_user}/>
              </CardContent>
            </Card>
          </Grid>
        ) : !data?.id ? (
          null
        ) : makeAdmin ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Login-Berechtigung erstellen
                </Typography>
                <Typography gutterBottom>
                  Dieser Nutzer erhält eine Login-Berechtigung.
                </Typography>
                <br/>

                <Grid container spacing={1}>
                  {emailField}
                  <Grid item xs={12}>
                    <YupField name="contact.new_password"/>
                  </Grid>
                  <Grid item xs={12}>
                    <YupField name="contact.passwordConfirmation"/>
                  </Grid>
                </Grid>
                <br/>
                <PersonFormPermissionsCard/>
              </CardContent>
            </Card>
          </Grid>
        ) : allowAccountCreation ? (
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setMakeLogin(preferredRole)}
            >
              Login-Berechtigung hinzufügen
            </Button>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <YupField name="is_gl"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="is_technik"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="is_lira"/>
        </Grid>

        <Grid item xs={12}>
          <YupField name="contact.allow_mails"/>
        </Grid>

      </Grid>
    </DialogContent>
  );
}

export function PersonForm({
  data,
  onClose,
  ...props
}) {
  const entityApi = useEntityApi(CONTACT_ROLE);

  const getContact = useSelector(getContactGetter);
  const {username} = getContact(data?.contact?.id);

  const savePerson = async (validatedData) => {
    const {
      organization,
      id
    } = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organization}/contact_roles/${id}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organization}/contact_roles/`,
        validatedData,
        {
          createEntities: true,
          organization
        },
      );
    }

    // TODO: Generalize save mechanism.
  };

  const emailField = (
    <Grid item xs={12}>
      <YupField name="contact.email"/>
    </Grid>
  );

  return (
    <EntityFormDialog
      id={'edit-person'}
      entityType="contact_roles"
      baseUrl={`/api/sendemeldung/organizations/${data?.organization}/contact_roles/`}
      title={data?.contact?.id ? "Person bearbeiten" : "Person anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={savePerson}
      allowDelete={!data?.is_request_user}
      deleteConfirmation="Person wirklich löschen?"
      deleteCaption="Person löschen"
      schema={ORGANIZATION_CONTACT_ROLE_SCHEMA}
      {...props}
    >
      <PersonFormDialogContent
        data={data}
        emailField={emailField}
        username={username}
      />
    </EntityFormDialog>
  );
}
