import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, {useRef} from "react";

export function ExportDialog({
  id = 'export',
  open,
  onClose,
  exportUrl,
  exportIsFiltered,
  ...props
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const ref = useRef();

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby={id}
        fullScreen={fullScreen}
        container={ref.current}
        style={{whiteSpace: 'normal'}}
        {...props}>
        <DialogTitle id={id}>
          {exportIsFiltered ? "Gefilterte Daten" : "Musikdatenbank"} exportieren...
        </DialogTitle>

        <DialogContent>
          <Typography gutterBottom>
            Hiermit können Sie die Daten aus Ihrer in Sendemeldung.de verwalteten Musikdatenbank exportieren.
          </Typography>

          {exportIsFiltered ? (
            <Alert severity="info" sx={{mt: 1}}>
              <AlertTitle>Filter aktiv</AlertTitle>
              <Typography>
                Es werden nur die Einträge exportiert, die Ihren Filterkriterien entsprechen.
              </Typography>
            </Alert>
          ) : null}

          <Alert severity="warning" sx={{mt: 1}}>
            <Typography>
              <AlertTitle>Beta-Funktion</AlertTitle>
              Bitte beachten Sie, dass diese Funktion noch in Entwicklung ist und sich das Exportformat in den kommenden
              Wochen und Monaten noch ändern kann.
            </Typography>
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button
            color="default"
            onClick={onClose}
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              window.location.assign(exportUrl);
              onClose();
            }}
          >
            Herunterladen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
