import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function PersonFilter({...props}) {
  return (
    <FancyFilterField
      options={personFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Personen suchen..."
      {...props}
    />
  );
}

const personFilterOptions = [
  {
    category: null,
    id: 'role',
    label: 'Rolle',
    choice: 'is_admin',
    chipLabel: ({
      id,
      choice
    }) => ({
      'is_admin': "Administratoren",
      'is_gl': "Geschäftsleitung",
      'is_technik': "Technik",
      'is_lira': "LIRA",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'is_admin': "Admin",
      'is_gl': "GL",
      'is_technik': "Technik",
      'is_lira': "LIRA",
    }[choice]),
    choices: ['is_admin', 'is_gl', 'is_technik', 'is_lira'],
  },
];
