import React from "react";
import {useSelector} from "react-redux";
import {getMusikPersonGetter} from "src/features/entity";
import {Chip, IconButton, Tooltip} from "@mui/material";
import {Edit, Link as LinkIcon} from "@mui/icons-material";
import {MUSIK_PERSON_ROLLE_KNZ} from "src/features/dashboard/dashboard-validation";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

export const URHEBER_LIST = ['K', 'B', 'SB', 'T', 'ST', 'TS'];
export const INTERPRET_LIST = ['INT', 'DIRIG', 'ENS', 'GRUPPE', 'ORCH', 'SON'];
export const VERLEGER_LIST = ['V', 'OV', 'SV'];

function MusikPersonRolle({knz, hideInterpretRole, hideVerlagRole}) {
  if (hideInterpretRole && knz === 'INT') {
    return null;
  }
  if (hideVerlagRole && knz === 'V') {
    return null;
  }

  let description = MUSIK_PERSON_ROLLE_KNZ[knz];
  if (description === undefined) {
    return null;
  }

  return (
    <Chip label={description} size="small" component="span"/>
  );
}

export default function MusikPerson({id, showRole, onEdit, hideEdit, ...props}) {
  const isLinkedData = id?.startsWith('auto/');

  const hasWritePermission = useHasPermissions({perm_write_music: true});
  const getMusikPerson = useSelector(getMusikPersonGetter);

  const {organization, org_music_work, musik_person_rolle_knz, name, vorname} = getMusikPerson(id);

  const roleBadge = showRole ? (
    <MusikPersonRolle knz={musik_person_rolle_knz} {...props}/>
  ) : null;

  return (
    <>
      {isLinkedData ? (
        <>
          <Tooltip title="Person aus zugeordneten Referenzdaten">
            <span>
              <IconButton
                size="small"
                disabled
              >
                <LinkIcon/>
              </IconButton>
            </span>
          </Tooltip>
          {' '}
        </>
      ) : null}
      {vorname} {name}
      {roleBadge ? (
        <>
          {' '}
          {roleBadge}
        </>
      ) : null}
      {(hasWritePermission && onEdit && !hideEdit && !isLinkedData) ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit ? (() => onEdit({id, organization, org_music_work, musik_person_rolle_knz, name, vorname})) : null}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}
    </>
  );
}
