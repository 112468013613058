import React, {useState} from "react";
import {Box, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {DashboardLayout} from "src/components/layout";
import {Add} from "@mui/icons-material";
import {useSelector} from "react-redux";
import Alert from '@mui/material/Alert';
import {getDatabaseRightGetter} from "src/features/entity";
import CircularProgress from "@mui/material/CircularProgress";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import DatabaseRightFilter from "src/components/entities/database/DatabaseRightFilter";
import DatabaseRightRow from "src/components/entities/database/DatabaseRightRow";
import {DatabaseRightForm} from "src/components/entities/database/DatabaseRightForm";

export default function DatabaseRightTable({
  organizationId,
  databaseId
}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: `database_rights/${databaseId}`,
    endpoint: `/api/sendemeldung/organizations/[ORGANIZATION_ID]/owned_databases/${databaseId}/rights/`,
    entityType: 'database_right',
  });

  const [editDatabaseRight, setEditDatabaseRight] = useState(null);

  const getDatabaseRight = useSelector(getDatabaseRightGetter);

  return (
    <DashboardLayout
      titlePrefix="Musikdatenbank-Berechtigungen"
      selectedPage={`database/${databaseId}`}
      drawerContentProps={{expandSettings: true}}
    >
      <PageToolbar
        title="Musikdatenbank-Berechtigungen"
        isAtTop
        searchField={(
          <DatabaseRightFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            Es wurden noch keine Berechtigungen eingerichtet.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Berechtigungen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            Diese Ansicht enthält keine Einträge.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>Zugriffsberechtigtes Sendeunternehmen</TableCell>
                <TableCell align="center">Berechtigung</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <DatabaseRightRow
                  key={id || i}
                  databaseRightId={id}
                  editDatabaseRight={(id) => setEditDatabaseRight(getDatabaseRight(id))}
                  organizationId={organizationId}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editDatabaseRight ? (
        <DatabaseRightForm
          data={editDatabaseRight}
          onClose={() => setEditDatabaseRight(null)}
        />
      ) : null}

      <OnlyIfPermissions perm_write_people_and_rights>
        <Box mt={7} mx={1.5} textAlign="right">
          <Fab
            style={{
              position: 'fixed',
              right: 20,
              bottom: 20,
            }}
            color="primary"
            aria-label="add"
            onClick={() => (
              // TODO: Outsource person bootstrap data to api/schemas.
              setEditDatabaseRight({
                database: databaseId,
              })
            )}>
            <Add/>
          </Fab>
        </Box>
      </OnlyIfPermissions>
    </DashboardLayout>
  );
}
