import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import MusicWorkRow from "src/components/entities/musicwork/MusicWorkRow";
import MusicFilter from "src/components/entities/musicwork/components/MusicFilter";
import {useQuery} from "src/packages/route-utils";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {getTodoTaskGetter} from "src/features/entity";
import {useDatabase, useEntityObserver} from "src/features/entity/entity-hooks";
import {TodoTask} from "src/components/entities/todotask/TodoTask";
import {updateQueryString, useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {useSessionTokenUrl} from "src/api/api-hooks";

function OrderableTableHeaderCell({
  ordering,
  updateOrdering,
  columnName,
  children,
  ...props
}) {
  return (
    <TableCell
      onClick={() => {
        if (ordering[0] === columnName) {
          updateOrdering([`-${columnName}`]);
        } else {
          updateOrdering([columnName]);
        }
      }}
      style={{cursor: 'pointer'}}
      {...props}
    >
      {children}
      {' '}
      {ordering[0] === columnName ? (
        <KeyboardArrowUp color="action" size="small" style={{verticalAlign: 'middle'}}/>
      ) : (ordering[0] === `-${columnName}`) ? (
        <KeyboardArrowDown color="action" size="small" style={{verticalAlign: 'middle'}}/>
      ) : null}
    </TableCell>
  );
}

const updateOrdering = (ordering) => updateQueryString({ordering});

const DEFAULT_ORDERING = ['musik_titel'];

export default function MusicWorkTable(
  {
    listingId: listingIdSuffix,
    databaseId,
    organizationId,
    meta,
    children,
    noEntriesContent,
    loadingContent,
    gemagvlxmlLieferungId,
    forcePagination,
    isAtTop = false,
    title,
  }
) {
  const queryTodoTaskId = useQuery('todoTaskId');
  useEntityObserver({
    type: 'todo_task',
    id: queryTodoTaskId
  });
  const todoTask = useSelector(getTodoTaskGetter)(queryTodoTaskId);
  const todoType = todoTask?.todo_type;

  listingIdSuffix = `${listingIdSuffix}/${databaseId}`;

  const {
    filter,
    updateFilter,
    filterProps,
    paginationProps,
    ordering,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    organizationId,
    listingIdSuffix,
    endpoint: databaseId ? (
      `/api/sendemeldung/organizations/[ORGANIZATION_ID]/databases/${databaseId}/org_music_works/`
    ) : (
      '/api/sendemeldung/organizations/[ORGANIZATION_ID]/org_music_works/'
    ),
    entityType: 'org_music_work',
    defaultOrdering: DEFAULT_ORDERING,
    meta: {
      ...meta,
      todo_type: todoType,
    },
  });

  const queryFilter = useQuery('filter');
  useEffect(() => {
    // FIXME: When reloading the page after removing the filter, the filter is set again.
    if (queryTodoTaskId && !queryFilter) {
      updateFilter(
        null,
        [{
          id: 'quality',
          choice: 'specific_todo_task'
        }],
        {replace: true},
      );
    }
  }, []);

  const [editWork, setEditWork] = useState(null);

  const filteredByTodoTask = queryTodoTaskId && (
    filter.filter(({
      id,
      choice
    }) => id === 'quality' && choice === 'specific_todo_task').length > 0
  );

  const exportUrlParams = new URLSearchParams({
    format: "json",
    ordering: ordering.join(','),
    ...Object.fromEntries(filter?.map(({
      id,
      choice
    }) => [id, choice])),
  });
  const exportUrl = useSessionTokenUrl(`/api/sendemeldung/organizations/${organizationId}/databases/${databaseId}/org_music_works/export_json/?${exportUrlParams.toString()}`);

  const {owner} = useDatabase({id: databaseId});

  return (
    <>
      <PageToolbar
        title={title}
        isAtTop={isAtTop}
        exportUrl={databaseId ? exportUrl : null}
        exportIsFiltered={filter?.length > 0}
        manageRightsUrl={owner === organizationId ? `/dashboard/database/${databaseId}/rights/` : null}
        searchField={(
          <MusicFilter
            {...filterProps}
            allowSearch
            extraOptions={queryTodoTaskId ? (
              [
                {
                  category: "Status",
                  id: 'quality',
                  label: 'Hinweise',
                  choice: 'specific_todo_task',
                  chipLabel: ({
                    id,
                    choice
                  }) => ({
                    'specific_todo_task': "Einträge mit ausgewähltem Hinweis",
                    'has_todo_tasks': "Einträge mit Hinweisen",
                    'no_todo_tasks': "Einträge ohne Hinweise",
                  }[choice]),
                  choiceLabel: ({
                    id,
                    choice
                  }) => ({
                    'specific_todo_task': "ausgewählter",
                    'has_todo_tasks': "liegen vor",
                    'no_todo_tasks': "liegen nicht vor",
                  }[choice]),
                  choices: ['specific_todo_task', 'has_todo_tasks', 'no_todo_tasks'],
                },
              ]
            ) : null}
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={{
          ...paginationProps,
          force: forcePagination
        }}
      >
      </PageToolbar>

      {filteredByTodoTask ? (
        <Box mb={2}>
          <TodoTask
            id={queryTodoTaskId}
            gemagvlxmlLieferungId={gemagvlxmlLieferungId}
            hideMusicWorksButton
          />
        </Box>
      ) : null}

      {renderIds.length === 0 ? (
        noDataExists ? (
          noEntriesContent || children
        ) : isLoading ? (
          loadingContent || children
        ) : (
          children
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <OrderableTableHeaderCell
                  ordering={ordering}
                  updateOrdering={updateOrdering}
                  columnName={'musik_titel'}
                >
                  Titel
                </OrderableTableHeaderCell>
                <TableCell>Urheber</TableCell>
                <TableCell>Interpret</TableCell>
                <TableCell align="center">Identifier</TableCell>
                <TableCell align="center">Musikherkunft</TableCell>
                <OrderableTableHeaderCell
                  ordering={ordering}
                  updateOrdering={updateOrdering}
                  columnName={'updated_at'}
                  align="center"
                >
                  Status
                </OrderableTableHeaderCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <MusicWorkRow
                  key={id || i}
                  id={id}
                  onEdit={setEditWork}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editWork ? (
        <MusicWorkForm
          data={editWork}
          onClose={() => setEditWork(null)}
        />
      ) : null}
    </>
  );
}
