import React from "react";
import {parse as parseISODuration} from "iso8601-duration";
import {formatISODuration} from "date-fns";

const DURATION_RE = /^((\d+) days?, |)(\d+):(\d+):(\d+)$/;

export function parseDuration(duration) {
  if (!duration) {
    return null;
  }

  const match = DURATION_RE.exec(duration);
  if (match) {
    return {
      ...parseDuration('PT0S'),
      days: parseInt(match[2]) || 0,
      hours: parseInt(match[3]),
      minutes: parseInt(match[4]),
      seconds: parseInt(match[5]),
    };
  }

  return parseISODuration(duration);
}

export function normalizeDuration(duration) {
  if (!duration) {
    return null;
  }

  const parsedDuration = parseDuration(duration);

  const seconds = parsedDuration?.seconds;
  if (seconds > 60) {
    parsedDuration.seconds = seconds % 60;
    parsedDuration.minutes = Math.floor(seconds / 60) + parsedDuration?.minutes;
  }

  const minutes = parsedDuration?.minutes;
  if (minutes > 60) {
    parsedDuration.minutes = minutes % 60;
    parsedDuration.hours = Math.floor(minutes / 60) + parsedDuration?.hours;
  }

  return formatISODuration(parsedDuration);
}

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export default function Duration({value}) {
  let parsedDuration;

  try {
    parsedDuration = parseDuration(value) || {};
  } catch (e) {
    return (
      <>
        ungültige Zeitangabe
      </>
    );
  }

  const {
    days,
    hours,
    minutes,
    seconds
  } = parsedDuration;

  return (
    days ? (
      <>{days} Tage, {hours}:{pad(minutes, 2)}:{pad(seconds, 2)}</>
    ) : hours ? (
      <>{pad(hours, 2)}:{pad(minutes, 2)}:{pad(seconds, 2)}</>
    ) : (minutes || seconds) ? (
      <>{minutes}:{pad(seconds, 2)}</>
    ) : null
  );
}
