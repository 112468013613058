import React from "react";
import {useSelector} from "react-redux";
import {getMusikProduktionIdGetter} from "src/features/entity";
import {Chip, IconButton, Tooltip} from "@mui/material";
import {MUSIK_PRODUKTION_ID_TYP_KNZ} from "src/features/dashboard/dashboard-validation";
import {Link as LinkIcon, Edit, Warning} from "@mui/icons-material";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

export function MusikProduktionIdTypView({id_typ_knz}) {
  let description = MUSIK_PRODUKTION_ID_TYP_KNZ[id_typ_knz];
  if (description === undefined) {
    return null;
  }

  return (
    description
  );
}

export function MusikProduktionIdTyp({id}) {
  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);
  const {id_typ_knz} = getMusikProduktionId(id);

  return (
    <MusikProduktionIdTypView id_typ_knz={id_typ_knz}/>
  );
}

export function MusikProduktionIdView({
  onEdit,
  hideEdit,
  warnInvalid,
  id_typ_knz,
  id_wert,
  isLinkedData
}) {
  const typeBadge = id_typ_knz ? (
    <Chip label={<MusikProduktionIdTypView id_typ_knz={id_typ_knz}/>} size="small" component="span"/>
  ) : null;

  return (
    <>
      {isLinkedData ? (
        <>
          <Tooltip title="Identifier aus zugeordneten Referenzdaten">
            <span>
              <IconButton
                size="small"
                disabled
              >
                <LinkIcon/>
              </IconButton>
            </span>
          </Tooltip>
          {' '}
        </>
      ) : null}
      {warnInvalid ? (
        <Tooltip title="Dieser Identifier ist ungültig.">
          <Chip label={id_wert} component="span" color="primary" size="small" icon={<Warning/>}/>
        </Tooltip>
      ) : (
        <>
          {id_wert}
        </>
      )}
      {typeBadge ? (
        <>
          {' '}
          {typeBadge}
        </>
      ) : null}
      {(onEdit && (!hideEdit || warnInvalid)) ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}
    </>
  );
}

export default function MusikProduktionId({
  id,
  showType,
  onEdit,
  hideEdit
}) {
  const hasWritePermission = useHasPermissions({perm_write_music: true});
  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);
  const {
    organization,
    org_music_work,
    id_typ_knz,
    id_wert,
    is_valid
  } = getMusikProduktionId(id);

  return (
    <MusikProduktionIdView
      onEdit={(hasWritePermission && onEdit && id && !id?.startsWith('auto/')) ? (() => onEdit({
        id,
        organization,
        org_music_work,
        id_typ_knz,
        id_wert
      })) : null}
      hideEdit={hideEdit}
      warnInvalid={is_valid === false}
      id_typ_knz={showType ? id_typ_knz : null}
      id_wert={id_wert}
      isLinkedData={id?.startsWith('auto/')}
    />
  );
}
