import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Card, CardContent, CircularProgress, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import {SettingAccordion} from "src/components/layout/components/SettingAccordion";
import {Link} from "gatsby-theme-material-ui";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {SimpleContactButton} from "src/components/entities/document/SimpleContactButton";
import {getOrganizationGetter} from "src/features/entity";

function useFormattedPlanBoxes({
  planTitle,
  planSubTitle,
  planSummary,
  basic,
  station,
  funding_basic,
  funding_station,
  basicFee,
  stationFee,
  fundingBasicFee,
  fundingStationFee,
  totalFee,
  totalFundingFee,
}) {
  return {
    bookedPlan: (
      <>
        {planTitle}
        {planSubTitle ? (
          <>
            <br/>
            <small>{planSubTitle}</small>
          </>
        ) : null}
      </>
    ),
    bookedPlanDetails: (
      <>
        {planSummary ? (
          <Box pb={2}>
            {planSummary}
          </Box>
        ) : null}
        {basic > 0 && basicFee && stationFee ? (
          <Grid container sx={{maxWidth: 600}} spacing={2}>
            <Grid item xs={6}>
              Grundbeitrag:
            </Grid>
            <Grid item xs={6}>
              {basicFee}
            </Grid>

            <Grid item xs={6}>
              Beitrag für gemeldete Programme:
            </Grid>
            <Grid item xs={6}>
              {stationFee}
              <Box component="span" px={1} sx={{fontWeight: 'bold'}}>x</Box>
              {station !== 1 ? (
                <>
                  {station} Programme
                </>
              ) : (
                <>
                  {station} Programm
                </>
              )}
            </Grid>

            <Grid item xs={6} sx={{
              textAlign: 'right',
              fontWeight: 'bold'
            }}>
              entspricht insgesamt
            </Grid>
            <Grid item xs={6}>
              <Box component="span" sx={{fontWeight: 'bold'}}>{totalFee}</Box> (zzgl. MwSt.)
            </Grid>
          </Grid>
        ) : null}
      </>
    ),
    bookedFunding: (
      <>
        {funding_basic > 0 ? (
          "Ihr Unternehmen beteiligt sich an der Finanzierung von Weiterentwicklungen."
        ) : (
          "Ihr Unternehmen beteiligt sich gegenwärtig nicht an der Finanzierung von Weiterentwicklungen."
        )}
      </>
    ),
    bookedFundingDetails: (
      funding_basic > 0 && fundingBasicFee && fundingStationFee ? (
        <>
          <Box pb={2}>
            Ihr Anteil an der Finanzierung von Weiterentwicklungen, die in Abstimmung mit den Verbänden APR und VAUNET
            erfolgen, berechnet sich wie folgt:
          </Box>
          <Grid container sx={{maxWidth: 600}} spacing={2}>
            <Grid item xs={6}>
              Grundbeitrag:
            </Grid>
            <Grid item xs={6}>
              {fundingBasicFee}
            </Grid>

            <Grid item xs={6}>
              Beitrag für gemeldete Programme:
            </Grid>
            <Grid item xs={6}>
              {fundingStationFee}
              <Box component="span" px={1} sx={{fontWeight: 'bold'}}>x</Box>
              {funding_station !== 1 ? (
                <>
                  {funding_station} Programme
                </>
              ) : (
                <>
                  {funding_station} Programm
                </>
              )}
              {funding_station > station ? (
                <>
                  <br/>
                  <small>(In Absprache mit den Verbänden wird immer mindestens 1 Programm berechnet.)</small>
                </>
              ) : null}
            </Grid>

            <Grid item xs={6} sx={{
              textAlign: 'right',
              fontWeight: 'bold'
            }}>
              entspricht insgesamt
            </Grid>
            <Grid item xs={6}>
              <Box component="span" sx={{fontWeight: 'bold'}}>{totalFundingFee}</Box> (zzgl. MwSt.)
            </Grid>
          </Grid>
        </>
      ) : null
    ),
  };
}

export default function BillingPage() {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    id: organizationId,
    plan_data,
  } = useSelector(getOrganizationGetter)(selectedOrganizationId);
  const isLoaded = !!organizationId;

  const hasMessagePermission = useHasPermissions({perm_write_documents: true});

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    bookedPlan,
    bookedPlanDetails,
    bookedFunding,
    bookedFundingDetails,
  } = useFormattedPlanBoxes({...plan_data});

  return (
    <DashboardLayout
      titlePrefix="Abrechnung"
      selectedPage="billing"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h6">
          Abrechnung
        </Typography>
      </Box>

      {isLoaded ? (
        <Box sx={{width: '100%'}}>
          <SettingAccordion
            id="plan"
            expanded={expanded === 'plan'}
            onChange={handleChange('plan')}
            title="Gebuchter Tarif"
            description={bookedPlan}
          >
            {bookedPlanDetails}
          </SettingAccordion>

          <SettingAccordion
            id="funding"
            expanded={expanded === 'funding'}
            onChange={handleChange('funding')}
            title="Finanzierung von Weiterentwicklungen"
            description={bookedFunding}
          >
            {bookedFundingDetails}
          </SettingAccordion>
        </Box>
      ) : (
        <CircularProgress size='1.5rem'/>
      )}

      <Divider sx={{
        mt: 4,
        mb: 4
      }}/>

      <Card sx={{mt: 2}}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Tarifwechsel gewünscht?
          </Typography>
          {hasMessagePermission ? (
            <>
              <Typography gutterBottom>
                Kein Problem! Schreiben Sie uns einfach eine Nachricht über den Kontaktbereich oder senden Sie uns eine
                E-Mail an <Link href="mailto:kontakt@sendemeldung.de" underline="hover">kontakt@sendemeldung.de</Link>.
              </Typography>
              <Typography pt={1}>
                <SimpleContactButton/>
              </Typography>
            </>
          ) : (
            <Typography>
              Kein Problem! Schreiben Sie uns einfach eine E-Mail an <Link href="mailto:kontakt@sendemeldung.de"
                                                                           underline="hover">kontakt@sendemeldung.de</Link>.
            </Typography>
          )}
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}
