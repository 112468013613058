import MuiTextField from "@mui/material/TextField";
import {useState} from "react";
import {formatLabelCode} from "src/components/entities/musicwork/components/LabelCode";

export default function LabelCodeTextField({
  onFocus,
  onBlur,
  ...props
}) {
  const [focus, setFocus] = useState(false);

  if (!focus) {
    props = {
      ...props,
      inputProps: {
        ...props.inputProps,
        value: formatLabelCode(props.inputProps.value)
      }
    };
  }

  return (
    <MuiTextField
      {...props}
      onFocus={(e) => {
        setFocus(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        setFocus(false);
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
}
