import React, {useEffect} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";
import SystemOverloadIndicator from "src/components/dashboard/components/overload-indicator";
import {uploadedFiles} from "src/features/orm/selectors";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";

export default function UploadedFileReportsPage({id}) {
  useRequirePermissions({perm_read_reports: true});

  useEntityObserver({type: 'uploaded_file', id});

  const uploadedFile = useSelector(state => uploadedFiles(state, id));
  const gemagvlxmlReports = uploadedFile?.gemagvlxml_lieferungen || [];

  useEffect(() => {
    if (gemagvlxmlReports?.length === 1) {
      const gemagvlxmlReport = gemagvlxmlReports[0];
      navigate(`/dashboard/reports/${gemagvlxmlReport}/`, {replace: true});
    }
  }, [gemagvlxmlReports]);

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="reports"
    >
      <Box my={2}>
        <Typography variant="h4">
          {uploadedFile?.name}
        </Typography>
      </Box>

      <SystemOverloadIndicator/>

      {gemagvlxmlReports.map((id, i) => (
        <GemaGVLXMLRow
          key={id || i}
          id={id}
        />
      ))}
    </DashboardLayout>
  );
}
