import {useSelector} from "react-redux";
import {getGEMAWorkGetter} from "src/features/entity";
import {TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import Duration from "src/packages/Duration";
import GEMAWerkTitle from "src/components/entities/refdata/GEMAWerkTitle";
import GEMAWerkIdentifier from "src/components/entities/refdata/GEMAWerkIdentifier";
import GEMAWerkPerson from "src/components/entities/refdata/GEMAWerkPerson";

export default function GEMAWerkRow({
  id,
}) {
  // useEntityObserver({
  //   type: 'gema_work',
  //   id
  // });

  const {
    workNumber,
    workVersionNumber,
    genre,
    duration,
    titles,
    identifiers,
    interestedParties,
    otherParties,
  } = useSelector(getGEMAWorkGetter)(id);

  return <>
    <TableRow sx={{'td,th': {verticalAlign: 'top'}}}>
      <TableCell component="th" scope="row">
        <Typography>
          {workNumber}<span style={{color: 'lightgray'}}>-{workVersionNumber}</span>
        </Typography>
      </TableCell>
      <TableCell>
        {titles?.map((el, i) => (
          <Typography pt={i > 0 ? 2 : 0} key={i}>
            <GEMAWerkTitle gemaWorkTitle={el}/>
          </Typography>
        ))}
      </TableCell>
      <TableCell>
        {interestedParties?.map((el, i) => (
          <Typography pt={i > 0 ? 2 : 0} key={i}>
            <GEMAWerkPerson gemaWorkPerson={el} showRole/>
          </Typography>
        ))}
      </TableCell>
      <TableCell>
        {otherParties?.map((el, i) => (
          <Typography pt={i > 0 ? 2 : 0} key={i}>
            <GEMAWerkPerson gemaWorkPerson={el} showRole/>
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center">
        <Typography>
          <Duration value={duration}/>
        </Typography>
      </TableCell>
      <TableCell align="center">
        {identifiers?.map((el, i) => (
          <Typography pt={i > 0 ? 2 : 0} key={i}>
            <GEMAWerkIdentifier gemaWorkIdentifier={el} showType/>
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center">
        <Typography>
          {genre?.labelDE}
        </Typography>
      </TableCell>
    </TableRow>
  </>;
}
