import React from "react";
import MusikTitel from "src/components/entities/musicwork/components/MusikTitel";
import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {getMusikPersonGetter} from "src/features/entity";
import {INTERPRET_LIST} from "src/components/entities/musicwork/components/MusikPerson";
import {useOrgMusicWork} from "src/features/entity/entity-hooks";

export default function MusicWorkSummary({id, createLink, onEdit}) {
  const {
    id: musikId,
    musik_personen,
  } = useOrgMusicWork({id});

  const getMusikPerson = useSelector(getMusikPersonGetter);
  const personen = musik_personen?.map(getMusikPerson);

  const interpreten = personen?.filter(({musik_person_rolle_knz}) => INTERPRET_LIST.includes(musik_person_rolle_knz));

  return (
    <Box>
      <Typography style={{fontWeight: 600}}>
        <MusikTitel
          id={id}
          createLink={createLink}
          onEdit={onEdit}
        >

        </MusikTitel>
      </Typography>
      {interpreten?.length > 0 ? (
        <Typography variant="subtitle2">
          {interpreten?.map(({id, vorname, name}, i) => (
            <span key={id || i}>
              {i > 0 ? ", " : null}
              {vorname} {name}
            </span>
          ))}
        </Typography>
      ) : null}
    </Box>
  );
}
