import {useEntityObserver} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getLabelGetter} from "src/features/entity";
import {TableCell, TableRow} from "@mui/material";
import React from "react";
import LabelCode from "src/components/entities/musicwork/components/LabelCode";
import {Check} from "@mui/icons-material";

export default function LabelRow({
  id,
}) {
  useEntityObserver({
    type: 'label',
    id
  });

  const {
    label_code,
    label,
    short_name,
    company_name,
    plm,
  } = useSelector(getLabelGetter)(id);

  return <>
    <TableRow>
      <TableCell component="th" scope="row">
        <LabelCode value={label_code}/>
      </TableCell>
      <TableCell>
        {label}
      </TableCell>
      <TableCell>
        {short_name}
      </TableCell>
      <TableCell>
        {plm ? (
          <Check/>
        ) : null}
      </TableCell>
      <TableCell>
        {company_name}
      </TableCell>
    </TableRow>
  </>;
}
