import {GEMAGVLXML_AUSSTRAHLUNG_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Alert, Box, DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_AUSSTRAHLUNG} from "src/api/api-schemas";
import {getGEMAGVL4AusstrahlungGetter, getGEMAGVLXMLAusstrahlungGetter} from "src/features/entity";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";
import {differenceInSeconds, formatDuration, formatISO, formatISODuration, parseISO} from "date-fns";
import {parse as parseISODuration} from 'iso8601-duration';
import deLocale from "date-fns/locale/de";
import {getSelectedOrganization} from "src/features/dashboard";
import EntityFormDialog from "src/features/entity/EntityFormDialog";
import {normalizeDuration} from "src/packages/Duration";

function tryFormatISO(dt) {
  try {
    if (dt instanceof Date) {
      return formatISO(dt);
    }
  } catch (e) {
  }
  return dt;
}

function allowedValues(...args) {
  return args.filter((x) => x);
}

function GEMAGVLXMLAusstrahlungForm({id}) {
  const {
    gemagvl4_ausstrahlung,
    musik_dauer: musikDauer,
    can_change_musik_dauer,
  } = useSelector(getGEMAGVLXMLAusstrahlungGetter)(id);
  let {
    datum_uhrzeit_von_candidates = [],
    datum_uhrzeit_bis_candidates = [],
    // sendedauer: gemagvl4Sendedauer,
  } = useSelector(getGEMAGVL4AusstrahlungGetter)(gemagvl4_ausstrahlung);

  useEntityObserver({
    type: 'gemagvlxml_ausstrahlung',
    id
  });
  useEntityObserver({
    type: 'gemagvl4_ausstrahlung',
    id: gemagvl4_ausstrahlung
  });

  const datum_uhrzeit_von_values = allowedValues(...datum_uhrzeit_von_candidates);
  const datum_uhrzeit_bis_values = allowedValues(...datum_uhrzeit_bis_candidates);

  const {watch} = useFormContext();
  const cur_datum_uhrzeit_von = tryFormatISO(watch('datum_uhrzeit_von'));
  const cur_datum_uhrzeit_bis = tryFormatISO(watch('datum_uhrzeit_bis'));

  // gemagvl4Sendedauer = normalizeDuration(gemagvl4Sendedauer);
  let normalizedMusikDauer;
  try {
    normalizedMusikDauer = normalizeDuration(musikDauer);
  } catch (e) {
    console.error(e);
  }

  let computedSendedauer = null;
  if (cur_datum_uhrzeit_von && cur_datum_uhrzeit_bis) {
    const start = parseISO(cur_datum_uhrzeit_von);
    const end = parseISO(cur_datum_uhrzeit_bis);
    if (start <= end) {
      computedSendedauer = normalizeDuration(formatISODuration({seconds: differenceInSeconds(end, start)}));
    }
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YupField name="datum_uhrzeit_von"/>
          {datum_uhrzeit_von_values?.length > 0 && !datum_uhrzeit_von_values.includes(cur_datum_uhrzeit_von) ? (
            <Alert severity="warning">
              Ausstrahlungsbeginn {cur_datum_uhrzeit_von} weicht von GEMAGVL4-Daten ab, denen zufolge die Ausstrahlung um
              {' '}
              {datum_uhrzeit_von_values?.join(' oder ')}
              {' '}
              startete.
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <YupField name="datum_uhrzeit_bis"/>
          {datum_uhrzeit_bis_values?.length > 0 && !datum_uhrzeit_bis_values.includes(cur_datum_uhrzeit_bis) ? (
            <Alert severity="warning">
              Ausstrahlungsende {cur_datum_uhrzeit_bis} weicht von GEMAGVL4-Daten ab, denen zufolge die Ausstrahlung um
              {' '}
              {datum_uhrzeit_bis_values?.join(' oder ')}
              {' '}
              endete.
            </Alert>
          ) : null}
        </Grid>
        {computedSendedauer ? (
          <Grid item xs={12}>
            {computedSendedauer !== normalizedMusikDauer && normalizedMusikDauer ? (
              <>
                <div>
                  Länge des Ausstrahlungszeitraums:
                  {' '}
                  {formatDuration(parseISODuration(computedSendedauer), {locale: deLocale})}
                </div>
                {normalizedMusikDauer ? (
                  <div>
                    Abrechnungsrelevante Sendedauer:
                    {' '}
                    {can_change_musik_dauer ? (
                      <>
                        {formatDuration(parseISODuration(computedSendedauer), {locale: deLocale})}
                      </>
                    ) : (
                      <>
                        {formatDuration(parseISODuration(normalizedMusikDauer), {locale: deLocale})}
                      </>
                    )}
                  </div>
                ) : null}
                <Box mt={2}>
                  <Alert severity="warning">
                    Länge des Ausstrahlungszeitraums weicht von tatsächlicher Sendedauer ab.
                  </Alert>
                </Box>
              </>
            ) : normalizedMusikDauer ? (
              <>
                <div>
                  Sendedauer: {formatDuration(parseISODuration(normalizedMusikDauer), {locale: deLocale})}
                </div>
              </>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </DialogContent>
  );
}

export default function GEMAGVLXMLAusstrahlungFormDialog({
  data,
  ...props
}) {
  const selectedOrganization = useSelector(getSelectedOrganization);
  const entityApi = useEntityApi(GEMAGVLXML_AUSSTRAHLUNG);

  const {id} = data;
  const {can_delete: canDelete} = useSelector(getGEMAGVLXMLAusstrahlungGetter)(id);

  const saveAusstrahlung = async (validatedData) => {
    const {
      organization,
      id
    } = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_ausstrahlungen/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      id={'edit-ausstrahlung'}
      entityType="gemagvlxml_ausstrahlungen"
      baseUrl={`/api/sendemeldung/organizations/${selectedOrganization?.id}/gemagvlxml_ausstrahlungen/`}
      title={id ? "Ausstrahlung bearbeiten" : "Ausstrahlung anlegen"}
      open={!!data}
      data={data}
      submit={saveAusstrahlung}
      allowDelete={canDelete}
      deleteConfirmation="Ausstrahlung wirklich löschen?"
      deleteCaption="Ausstrahlung löschen"
      schema={GEMAGVLXML_AUSSTRAHLUNG_SCHEMA}
      {...props}
    >
      <GEMAGVLXMLAusstrahlungForm id={id}/>
    </EntityFormDialog>
  );
}
