import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter, getMusikProduktionIdGetter} from "src/features/entity";
import {Chip, Skeleton} from "@mui/material";
import React, {useState} from "react";
import {Add} from "@mui/icons-material";
import MusikProduktionId from "src/components/entities/musicwork/components/MusikProduktionId";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";
import {useOrgMusicWork} from "src/features/entity/entity-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function MusikProduktionIds({
  id,
  abbreviated = false,
  ...props
}) {
  const {
    id: musikId,
    organization,
    musik_produktion_ids,
    erschienene_tonaufnahme,
  } = useOrgMusicWork({
    id,
    observe: false
  });
  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);

  const {deklaration_tonaufnahme_knz} = useSelector(getErschieneneTonaufnahmeGetter)(erschienene_tonaufnahme);

  const [editProduktionId, setEditProduktionId] = useState(null);

  if (!musikId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  let hasISRC = false;
  let isMissingIsrcOrEanOrKatnr = (deklaration_tonaufnahme_knz === 'LABLC' || deklaration_tonaufnahme_knz === 'LABEL');
  musik_produktion_ids.map(id => getMusikProduktionId(id))
    .forEach(({
      id_typ_knz,
      is_valid
    }) => {
      if (id_typ_knz === 'ISRC' && is_valid) {
        hasISRC = true;
      }
      if (id_typ_knz === 'ISRC' || id_typ_knz === 'EAN_UPC' || id_typ_knz === 'KATALOG_NR') {
        isMissingIsrcOrEanOrKatnr = false;
      }
    });

  const addProduktionIdButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="Identifier ergänzen"
        onClick={() => setEditProduktionId({
          organization,
          org_music_work: id
        })}
      />
    </OnlyIfPermissions>
  );

  const addISRCButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="ISRC ergänzen"
        onClick={() => setEditProduktionId({
          organization,
          org_music_work: id,
          id_typ_knz: 'ISRC',
        })}
      />
    </OnlyIfPermissions>
  );

  const addEANButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="EAN/UPC ergänzen"
        onClick={() => setEditProduktionId({
          organization,
          org_music_work: id,
          id_typ_knz: 'EAN_UPC',
        })}
      />
    </OnlyIfPermissions>
  );

  const addKatalogNrButton = (
    <OnlyIfPermissions perm_write_music>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="Katalognummer ergänzen"
        onClick={() => setEditProduktionId({
          organization,
          org_music_work: id,
          id_typ_knz: 'KATALOG_NR',
        })}
      />
    </OnlyIfPermissions>
  );

  return (
    <>
      {musik_produktion_ids?.map((id, i) => (
        <p key={id || i}>
          <MusikProduktionId
            id={id}
            showType
            onEdit={setEditProduktionId}
            {...props}
          />
        </p>
      ))}

      {isMissingIsrcOrEanOrKatnr ? (
        abbreviated ? (
          <>
            {addProduktionIdButton}
          </>
        ) : (
          <>
            {addISRCButton} {addEANButton} {addKatalogNrButton}
          </>
        )
      ) : null}

      {editProduktionId ? (
        <div align="left">
          <MusicProductionIDForm
            data={editProduktionId}
            onClose={() => setEditProduktionId(null)}
            autoFocusField={({id_typ_knz, id_wert}) => !id_typ_knz ? 'id_typ_knz' : 'id_wert'}
          />
        </div>
      ) : null}
    </>
  );
}
