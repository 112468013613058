import {MUSIC_PRODUCTION_ID_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useOrgMusicWork, useSelectedOrganization} from "src/features/entity/entity-hooks";
import {MUSIK_PRODUKTION_ID} from "src/api/api-schemas";
import {useFormContext} from "react-hook-form";
import IdentifierSuggestionsField from "src/components/entities/musicwork/components/IdentifierSuggestionsField";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

function MusicIdentifierFormDialogContent({orgMusicWorkId}) {
  const {watch} = useFormContext();

  const id_typ_knz = watch('id_typ_knz');
  let type = undefined;
  switch (id_typ_knz) {
    case "ISRC":
      type = "ISRC";
      break;
    case "ISWC":
      type = "ISWC";
      break;
    case "KATALOG_NR":
      type = "KAT";
      break;
    case "EAN_UPC":
      type = ["EAN", "UPC"];
      break;
  }

  return (
    <DialogContent>
      <Grid container spacing={2} sx={{minWidth: 400}}>
        <Grid item xs={5}>
          <YupField name="id_typ_knz"/>
        </Grid>
        <Grid item xs={7}>
          <YupField
            name="id_wert"
            Component={IdentifierSuggestionsField}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function MusicProductionIDForm({
  data,
  onClose,
  ...props
}) {
  const {org_music_work: orgMusicWorkId} = data;
  const {database} = useOrgMusicWork({id: orgMusicWorkId});
  data = {
    ...data,
    database,
  };

  const {id: organizationId} = useSelectedOrganization();

  const entityApi = useEntityApi(MUSIK_PRODUKTION_ID);

  const save = async (validatedData) => {
    const {id} = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organizationId}/musik_produktion_ids/${id}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organizationId}/musik_produktion_ids/`,
        validatedData,
        {
          createEntities: true,
          organization: organizationId
        },
      );
    }

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      id={'edit-music-production-id'}
      entityType="musik_produktion_id"
      baseUrl={`/api/sendemeldung/organizations/${organizationId}/musik_produktion_ids/`}
      title={data?.id ? "Identifier bearbeiten" : "Identifier anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={save}
      schema={MUSIC_PRODUCTION_ID_SCHEMA}
      allowDelete
      deleteConfirmation="Identifier wirklich löschen?"
      deleteCaption="Identifier löschen"
      fullWidth
      {...props}
    >
      <MusicIdentifierFormDialogContent orgMusicWorkId={data?.org_music_work}/>
    </EntityFormDialog>
  );
}
