import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import Alert from "@mui/material/Alert";
import {AlertTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import GVLProduktFilter from "src/components/entities/refdata/GVLProduktFilter";
import GVLProduktRow from "src/components/entities/refdata/GVLProduktRow";

export default function GVLProduktTable({}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'gvl_products',
    endpoint: '/api/sendemeldung/refdata/gvl_products/',
    entityType: 'gvl_product',
  });

  return (
    <>
      <PageToolbar
        // title="GVL-Datenbank"
        searchField={(
          <GVLProduktFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="contained" severity="info">
            <AlertTitle>Suchbegriff erforderlich</AlertTitle>
            Bitte geben Sie einen Identifier, einen Titel oder einen Personennamen ein, um die Suche zu starten.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Suche läuft...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Suchergebnisse</AlertTitle>
            Ihre Suchanfrage ist entweder zu unspezifisch oder es wurden keine Musikproduktionen in der GVL-Datenbank
            gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="labels table">
            <TableHead>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap'}}>GVL-Produkt-ID</TableCell>
                <TableCell>Titel</TableCell>
                <TableCell>Urheber</TableCell>
                <TableCell>Interpret</TableCell>
                <TableCell align="center">Dauer</TableCell>
                <TableCell align="center">Identifier</TableCell>
                <TableCell align="center">Genre</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <GVLProduktRow
                  key={id || i}
                  id={id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
