import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import {AlertTitle, Box, Card, CardContent, Typography} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {BugReport} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {getKnownBugGetter} from "src/features/entity";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 800,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function KnownBugList({organizationId}) {
  const classes = useStyles();

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingId: 'known_bugs',
    endpoint: '/api/sendemeldung/known_bugs/',
    entityType: 'known_bug',
  });

  const getKnownBug = useSelector(getKnownBugGetter);

  return (
    <>
      <PageToolbar
        title="Bekannte Probleme und Anregungen"
        // isAtTop
        // searchField={(
        //   <NotificationFilter
        //     {...filterProps}
        //     allowSearch
        //   />
        // )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine bekannten Probleme oder Anregungen vorhanden</AlertTitle>
            Es liegen derzeit keine bekannten Probleme oder Anregungen vor.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Probleme und Anregungen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Probleme und Anregungen gefunden</AlertTitle>
            Es wurden keine Probleme und Anregungen gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        <>
          {renderIds?.map(getKnownBug)
            .map(({
              id,
              title,
              description
            }, i) => (
              <Box mb={2} key={id || i}>
                <Alert severity="info" variant="filled" icon={<BugReport/>}>
                  <AlertTitle>{title}</AlertTitle>
                  {description}
                </Alert>
              </Box>
            ))}
        </>
      )}
    </>
  );
}
