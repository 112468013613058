import React from "react";

import {
  SimpleYupFormDialog,
  SimpleYupFormDialogImplementation
} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {useEntityDeleter} from "src/features/entity/entity-hooks";
import {useFormContext} from "react-hook-form";

export function EntityFormDialogImplementation({
  deletingUuids,
  entityType,
  entityIdFieldName,
  onDelete,
  baseUrl,
  ...props
}) {
  const {watch} = useFormContext();
  const entityId = watch(entityIdFieldName);

  if (!entityId) {
    onDelete = undefined;
  }

  if (onDelete) {
    const baseOnDelete = onDelete;
    onDelete = () => baseOnDelete(entityId);
  }

  return (
    <SimpleYupFormDialogImplementation
      isDeleting={entityId ? deletingUuids?.has(entityId) : undefined}
      onDelete={onDelete}
      {...props}
    />
  );
}

export default function EntityFormDialog({
  entityType,
  entityIdFieldName = 'id',
  baseUrl,
  DialogImplementationComponent=EntityFormDialogImplementation,
  ...props
}) {
  const {
    deleteEntity,
    deletingUuids
  } = useEntityDeleter({
    entityType,
    baseUrl
  });

  return (
    <SimpleYupFormDialog
      onDelete={deleteEntity}
      DialogImplementationComponent={DialogImplementationComponent}
      entityType={entityType}
      entityIdFieldName={entityIdFieldName}
      baseUrl={baseUrl}
      deletingUuids={deletingUuids}
      {...props}
    />
  );
}
