import {useSelector} from "react-redux";
import {getGEMAGVL4StationGetter} from "src/features/entity";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {Chip} from "@mui/material";
import React from "react";

export default function GEMAGVL4Station({id}) {
  const {
    gemagvl4_sender,
    gemagvl4_prognr,
  } = useSelector(getGEMAGVL4StationGetter)(id);

  useEntityObserver({type: 'gemagvl4_station', id});

  return (
    <>
      {gemagvl4_sender !== undefined ? (
        <p style={{marginBottom: 0}}>
          {gemagvl4_sender}
          {gemagvl4_prognr !== '' ? ` / ${gemagvl4_prognr}` : null}
          {' '}
          <Chip label="GEMAGVL4" size="small" component="span"/>
        </p>
      ) : (
        null
        // <Chip
        //   icon={<Add/>}
        //   label="Angaben für GEMAGVL4 ergänzen"
        //   variant="outlined"
        //   clickable
        //   color="primary"
        //   onClick={() => editStation(stationId)}
        // />
      )}
    </>
  );
}
