import React from "react";
import {Description, Feedback, FileCopy, Folder, InsertDriveFile} from "@mui/icons-material";

const FILE_TYPE_TO_ICON_COMPONENT = {
  default: InsertDriveFile,
  duplicate: FileCopy,
  container: Folder,
  gemagvl4: Description,
  gemagvl4_json: Description,
  gemagvlxml: Description,
  xmlrueck: Feedback,
};

export default function FileIcon({type, color = "action", fontSize = "large", ...props}) {
  let Component = FILE_TYPE_TO_ICON_COMPONENT[type] || FILE_TYPE_TO_ICON_COMPONENT.default;
  return (
    <Component color={color} fontSize={fontSize} {...props}/>
  );
}
