import React, {useEffect, useMemo, useState} from "react";
import {DashboardLayout} from "src/components/layout";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Button, Card, CardContent, Chip,
  Fab, IconButton, List, ListItem, ListItemAvatar,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem, Skeleton, TablePagination, Toolbar,
  Tooltip
} from "@mui/material";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {
  Add,
  ArrowRight,
  CalendarMonth, CalendarViewDay,
  Check,
  Circle, DateRange,
  ExpandMore, Folder, KeyboardArrowLeft, KeyboardArrowRight,
  Label,
  LabelImportant,
  Reply, ViewCarousel
} from "@mui/icons-material";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import {useSelectedOrganization} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getStationGetter} from "src/features/entity";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";
import {formatDate, parseDate} from "src/packages/date-utils";
import {DrawerMainMenuItem} from "src/components/dashboard/components/drawer-main-menu-view";
import {addDays, addMonths} from "date-fns";
import {navigate} from "gatsby";
import {
  GEMAGVLXMLStats, HeatmapTooltipContent,
  useCategoryColorScaleFunction,
  useProcessedCoverageData
} from "src/components/entities/gemagvlxml/GEMAGVLXMLStats";
import axios from "axios";
import {getSelectedOrganizationId} from "src/features/dashboard";
import StationFilter from "src/components/entities/programme/StationFilter";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import SimpleDuration from "src/components/entities/gemagvlxml/SimpleDuration";
import NaturalHourGranularityDateTimeRange
  from "src/components/entities/gemagvlxml/components/NaturalHourGranularityDateTimeRange";
import Typography from "@mui/material/Typography";

const MONTH_NAMES = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

function reuseParams(path, shouldReuse) {
  const searchParams = new URLSearchParams(window.location.search);

  // if (searchParams.has('page')) {
  //   searchParams.delete('page')
  // }

  const searchString = searchParams.toString();
  if (searchString && shouldReuse) {
    return path + '?' + searchString;
  } else {
    return path;
  }
}

function useReportsTimeRange({year, month}) {
  if (year) {
    year = parseInt(year);
  }
  if (month) {
    month = parseInt(month);
  }

  const {
    min_report_date,
    max_report_date,
  } = useSelectedOrganization();
  const minReportDate = parseDate(min_report_date) || new Date();
  const maxReportDate = parseDate(max_report_date) || new Date();

  if (!year && !month) {
    year = maxReportDate.getFullYear();
    month = maxReportDate.getMonth() + 1;
  }

  const selectedDate = new Date(year, month - 1, 1);

  let maxDate = maxReportDate;
  if (selectedDate > maxDate) {
    maxDate = selectedDate;
  }

  let minDate = minReportDate;
  if (selectedDate < minDate) {
    minDate = selectedDate;
  }

  return {
    selectedMonth: month,
    selectedYear: year,
    minDate,
    maxDate,
    minYear: minDate.getFullYear(),
    minMonth: minDate.getMonth() + 1,
    maxYear: maxDate.getFullYear(),
    maxMonth: maxDate.getMonth() + 1,
  }
}

export function CalendarDrawerMainMenuItems({year, month, expanded, keepSearch}) {
  const {selectedMonth, selectedYear, minDate, maxDate} = useReportsTimeRange({month, year});

  const {
    outstanding_reports_count,
  } = useSelectedOrganization();

  const yearsCount = maxDate.getFullYear() - minDate.getFullYear() + 1;

  return (
    <>
      {expanded && (
        <DrawerMainMenuItem sx={{pl: 4}} href={'/dashboard/reports/'}>
          <ListItemIcon><Folder/></ListItemIcon>
          <ListItemText primary="Alle Lieferungen"/>
          <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/reports/'}>
            {outstanding_reports_count ? (
              <Chip size="small" color="primary" label={outstanding_reports_count} align="right"
                    style={{cursor: 'pointer'}}/>
            ) : null}
          </HrefComponent>
        </DrawerMainMenuItem>
      )}
      {new Array(yearsCount).fill(1)
        .map((_, yearIdx) => maxDate.getFullYear() - yearIdx)
        .map((itemYear, yearIdx) => ([
          <HrefMenuListItem key={`${itemYear}`} button sx={{pl: 4}} href={reuseParams(`/dashboard/calendar/${itemYear}/`, keepSearch)} active={itemYear === selectedYear && !selectedMonth}>
            <ListItemIcon><DateRange/></ListItemIcon>
            <ListItemText primary={itemYear}/>
            {expanded && itemYear === selectedYear && <ExpandMore/>}
          </HrefMenuListItem>,
          (expanded && itemYear === selectedYear) && (yearsCount === 1 ? (
            new Array(maxDate.getMonth() - minDate.getMonth() + 1).fill(1).map((_, monthIdx) => maxDate.getMonth() + 1 - monthIdx)
          ) : yearIdx === 0 ? (
            new Array(maxDate.getMonth() + 1).fill(1).map((_, monthIdx) => maxDate.getMonth() + 1 - monthIdx)
          ) : yearIdx === yearsCount - 1 ? (
            new Array(11 - minDate.getMonth() + 1).fill(1).map((_, monthIdx) => 12 - monthIdx)
          ) : (
            new Array(12).fill(1).map((_, monthIdx) => 12 - monthIdx)
          ))
            .map(itemMonth => ([
              <HrefMenuListItem key={`${itemYear}/${itemMonth}`} button sx={{pl: 8}} href={reuseParams(`/dashboard/calendar/${itemYear}/${itemMonth}/`, keepSearch)} active={itemYear === selectedYear && itemMonth === selectedMonth}>
                <ListItemIcon><ViewCarousel/></ListItemIcon>
                <ListItemText primary={MONTH_NAMES[itemMonth - 1]}/>
              </HrefMenuListItem>,
            ]))
        ]))}
    </>
  );
}

function TimeRangeToolbarTitle({year, month}) {
  const {minYear, minMonth, maxYear, maxMonth, selectedMonth, selectedYear} = useReportsTimeRange({month, year});

  let previousRange, nextRange;
  if (selectedMonth) {
    if (selectedYear < maxYear || (selectedYear === maxYear && selectedMonth < maxMonth)) {
      nextRange = selectedMonth === 12 ? {year: selectedYear + 1, month: 1} : {year: selectedYear, month: selectedMonth + 1};
    }
    if (selectedYear > minYear || (selectedYear === minYear && selectedMonth > minMonth)) {
      previousRange = selectedMonth === 1 ? {year: selectedYear - 1, month: 12} : {year: selectedYear, month: selectedMonth - 1};
    }
  } else {
    if (selectedYear < maxYear) {
      nextRange = {year: selectedYear + 1};
    }
    if (selectedYear > minYear) {
      previousRange = {year: selectedYear - 1};
    }
  }

  return (
    <Box sx={{float: 'left'}}>
      <IconButton
        aria-label="previousRange"
        color="inherit"
        onClick={() => navigate(reuseParams(`/dashboard/calendar/${previousRange?.year}/${previousRange?.month ? previousRange?.month + '/' : ''}`, true), {replace: true})}
        disabled={!previousRange}
        size="small"
        sx={{mr: 1}}
      >
        <KeyboardArrowLeft/>
      </IconButton>
      {selectedMonth ? MONTH_NAMES[selectedMonth-1] : "Jahr"} {selectedYear}
      <IconButton
        aria-label="nextRange"
        color="inherit"
        onClick={() => navigate(reuseParams(`/dashboard/calendar/${nextRange?.year}/${nextRange?.month ? nextRange?.month + '/' : ''}`, true), {replace: true})}
        disabled={!nextRange}
        size="small"
        sx={{ml: 1}}
      >
        <KeyboardArrowRight/>
      </IconButton>
    </Box>
  );
}

function pad2(num) {
    const s = "0" + num;
    return s.substring(s.length - 2, s.length);
}

function useStationStats({stationId, datum_von, datum_bis, reportsFilter, allowHours}) {
  const {id: organizationId} = useSelectedOrganization();

  const [stats, setStats] = useState(null);

  useEffect(async () => {
    if (!organizationId || !stationId) {
      return;
    }
    try {
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/stations/${stationId}/coverage/?datum_von=${datum_von}&datum_bis=${datum_bis}&report_status=${reportsFilter}&allow_hours=${allowHours}`);
      setStats(result.data?.stats);
    } catch (e) {
      console.error(e);
    }
  }, [organizationId, stationId, datum_von, datum_bis, reportsFilter, allowHours, setStats]);

  const coverage = stats?.coverage;
  if (coverage?.by_date) {
    coverage.by_month = Object.entries(coverage.by_date).reduce((byMonth, [date, data]) => {
      const month = date.substring(0, 7);
      const previousMonthData = byMonth?.[month];

      if (previousMonthData) {
        return {
          ...byMonth,
          [month]: {
            ...previousMonthData,
            interval_start: _.min([previousMonthData.interval_start, data.interval_start]),
            interval_end: _.max([previousMonthData.interval_end, data.interval_end]),
            interval_length: previousMonthData.interval_length + data.interval_length,
            is_holiday: previousMonthData.is_holiday && data.is_holiday,
            jingle_musik_dauer: previousMonthData.jingle_musik_dauer + data.jingle_musik_dauer,
            required_jingle_musik_dauer: previousMonthData.required_jingle_musik_dauer + data.required_jingle_musik_dauer,
            typical_jingle_musik_dauer: previousMonthData.typical_jingle_musik_dauer + data.typical_jingle_musik_dauer,
            standard_musik_dauer: previousMonthData.standard_musik_dauer + data.standard_musik_dauer,
            required_standard_musik_dauer: previousMonthData.required_standard_musik_dauer + data.required_standard_musik_dauer,
            typical_standard_musik_dauer: previousMonthData.typical_standard_musik_dauer + data.typical_standard_musik_dauer,
          },
        };
      } else {
        return {
          ...byMonth,
          [month]: data,
        };
      }
    }, {});
  }

  return {stats};
}

function StationStats({stationId, datum_von, datum_bis, reportsFilter, allowHours=false}) {
  const {stats} = useStationStats({stationId, datum_von, datum_bis, reportsFilter, allowHours});

  return (
    <GEMAGVLXMLStats
      datum_von={datum_von}
      datum_bis={datum_bis}
      stats={stats}
      allowHours={allowHours}
      stationsCount={1}
    />
  );
}

function StationRow({stationId, datum_von, datum_bis, reportsFilter, year, month, ...props}) {
  const {
    renderIds: reportIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: `byStation${stationId}`,
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/gemagvlxml_lieferungen/',
    entityType: 'gemagvlxml_lieferung',
    defaultPageSize: 100,
    pageParamName: 'dummyReportsPage',
    pageSizeParamName: 'dummyReportsPageSize',
    orderingParamName: 'dummyReportsOrdering',
    filterParamName: 'dummyReportsFilter',
    meta: {
      year,
      month,
      [`station_${stationId}`]: 'include',
      status: reportsFilter,
    },
  });

  const [expandedReport, setExpandedReport] = React.useState(false);

  return (
    <Box mt={-1} mb={5} {...props}>
      <ProgrammeChip id={stationId} sx={{mb: 1}}/>

      <Accordion
        expanded={false}
        TransitionProps={{unmountOnExit: true}}
      >
        <AccordionSummary
          // expandIcon={<ExpandMore/>}
          aria-controls={`${stationId}-content`}
          id={`${stationId}-header`}
        >
          <Box sx={{width: '100%'}}>
            <StationStats
              stationId={stationId}
              datum_von={formatDate(datum_von)}
              datum_bis={formatDate(datum_bis)}
              reportsFilter={reportsFilter}
              allowHours
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{width: '100%'}}>
          </Box>
        </AccordionDetails>
      </Accordion>

      {reportIds?.map((reportId, i) => (
        <GemaGVLXMLRow
          key={reportId || i}
          id={reportId}
          expanded={expandedReport === reportId}
          expandedUuid={expandedReport}
          expansible
          onChange={() => setExpandedReport(reportId === expandedReport ? false : reportId)}
        />
      ))}
    </Box>
  );
}

function MonthListing({year, month, stations, expanded, handleChange, isLoading, reportsFilter}) {
  const datum_von = new Date(year, month - 1, 1);
  const datum_bis = addDays(addMonths(new Date(year, month - 1, 1), 1), -1);

  return (
    <>
      {stations?.map(({id: stationId, name, gemagvl_sender_prg_id}, i) => {
        return (
          <StationRow
            key={stationId || i}
            mt={-1}
            mb={5}
            stationId={stationId}
            datum_von={datum_von}
            datum_bis={datum_bis}
            year={year}
            month={month}
            reportsFilter={reportsFilter}
          />
        );
      })}
    </>
  );
}

function Th({children, ...props}) {
  return (
    <th {...props}>
      {children}
    </th>
  );
}

function Td({children, ...props}) {
  return (
    <td {...props}>
      {children}
    </td>
  );
}

function YearStationTableCell({year, month, value, getCoverageStatsFromValue, isRelevant, stationId, categoryColorScaleFn, setHighlightedColumn, reportsFilter, isHighlighted, tooltipContentFn}) {
  const coverageStats = getCoverageStatsFromValue(value);
  const color = coverageStats?.category ? categoryColorScaleFn({category: coverageStats?.category, relativeIntervalPosition: coverageStats?.relativeIntervalPosition}) : '#fff';

  return (
    <HrefComponent
      component={Td}
      href={!isRelevant ? undefined : `/dashboard/calendar/${year}/${month}/?filter=station_${stationId}%3Dinclude%26reports%3D${reportsFilter}`}
      align="center"
      style={{
        background: color,
        color: '#fff',
        border: isHighlighted ? `2px solid ${color}` : '2px solid white',
      }}
      onMouseEnter={!isRelevant ? undefined : () => setHighlightedColumn(month)}
      onMouseLeave={!isRelevant ? undefined : () => setHighlightedColumn(null)}
    >
      {isRelevant ? (
        <Tooltip
          title={tooltipContentFn({date: new Date(year, month-1, 1), monthValue: value})}
          followCursor
        >
          <Box>
            {`${Math.round(coverageStats?.value / value?.intervalLength * 100)}%`}
          </Box>
        </Tooltip>
      ) : null}
    </HrefComponent>
  );
}

function YearStationTableRow({year, months, getMonthValue, title, isMonthRelevant, highlightedColumn, ...props}) {
  return (
    <tr>
      <th>{title}</th>
      {months.map(month => ({month, value: getMonthValue(month)})).map(({month, value}) => (
        <YearStationTableCell
          key={month}
          year={year}
          month={month}
          value={value}
          isRelevant={isMonthRelevant(month)}
          isHighlighted={highlightedColumn === month}
          {...props}
        />
      ))}
    </tr>
  );
}

// function YearStationTableRow({year, months, getMonthValue, getCoverageStatsFromValue, title, isMonthRelevant, stationId, categoryColorScaleFn, setHighlightedColumn, reportsFilter, highlightedColumn}) {
//   return (
//     <tr>
//       <th>{title}</th>
//       {months.map(month => ({month, value: getMonthValue(month)})).map(({month, value}) => (
//         value && <HrefComponent
//           component={Td}
//           key={month}
//           align="left"
//           href={!isMonthRelevant(month) ? undefined : `/dashboard/calendar/${year}/${month}/?filter=station_${stationId}%3Dinclude%26reports%3D${reportsFilter}`}
//           style={{
//             background: categoryColorScaleFn({category: getCoverageStatsFromValue(value)?.category, relativeIntervalPosition: getCoverageStatsFromValue(value)?.relativeIntervalPosition}),
//             border: highlightedColumn === month ? '2px solid ' + categoryColorScaleFn({category: getCoverageStatsFromValue(value)?.category, relativeIntervalPosition: getCoverageStatsFromValue(value)?.relativeIntervalPosition}) : '2px solid white',
//           }}
//           onMouseEnter={!isMonthRelevant(month) ? undefined : () => setHighlightedColumn(month)}
//           onMouseLeave={!isMonthRelevant(month) ? undefined : () => setHighlightedColumn(null)}
//         >
//           &nbsp;
//         </HrefComponent>
//       ))}
//     </tr>
//   );
// }

function YearStationTable({stationId, datum_von, datum_bis, year, months, reportsFilter, highlightedMonth, highlightMonth, ...props}) {
  const {stats} = useStationStats({stationId, datum_von, datum_bis, reportsFilter});
  const data = useProcessedCoverageData({coverageDict: stats?.coverage?.by_month});

  const [highlightedColumn, setHighlightedColumn] = React.useState(null);

  highlightedMonth = highlightedMonth || highlightedColumn;

  const getStation = useSelector(getStationGetter);
  const {name, gemagvl_sender_prg_id, report_jingles, report_from, report_until} = getStation(stationId);

  const getMonthValue = month => data?.filter(({day}) => day === `${year}-${pad2(month)}`)?.[0]?.value;

  const categoryColorScaleFn = useCategoryColorScaleFunction();

  const isMonthRelevant = (month) => (
    (!report_from || report_from < `${year}-${pad2(month)}-31`) &&
    (!report_until || report_until > `${year}-${pad2(month)}-01`)
  ) || (
    getMonthValue(month)?.standardCoverageStats?.value > 0 ||
    getMonthValue(month)?.jingleCoverageStats?.value > 0
  );

  const isLoading = !stats;

  const showJingles = report_jingles || data?.filter(({value}) => value?.jingleCoverageStats?.requiredMusikDauer > 0 || value?.jingleCoverageStats?.value > 0)?.length > 0;

  const tooltipContentFn = React.useCallback(({...props}) => {
    return (
      <HeatmapTooltipContent
        showMusic
        showJingles={showJingles}
        {...props}
      />
    );
  }, [stats, showJingles]);

  const allowDetails = !isLoading && stats?.coverage?.interval_start && stats?.coverage?.interval_end;

  return (
    <Box mt={-1} mb={5} {...props}>
      <ProgrammeChip id={stationId} sx={{mb: 1}}/>

      <Accordion
        // expanded={false}
        TransitionProps={{unmountOnExit: true}}
      >
        <AccordionSummary
          expandIcon={allowDetails ? <ExpandMore/> : null}
          aria-controls={`${stationId}-content`}
          id={`${stationId}-header`}
        >
          {isLoading ? (
            <Skeleton
              sx={{width: '100%', height: '100px', my: -2}}
            />
          ) : (
            <Box sx={{width: '100%'}}>
              <table style={{width: '100%', tableLayout: 'fixed'}} cellSpacing={4} cellPadding={2}>
                <thead>
                  <th></th>
                  {months.map(month => (
                    <HrefComponent
                      component={Th}
                      key={month}
                      href={`/dashboard/calendar/${year}/${month}/?filter=reports%3D${reportsFilter}`}
                      style={{
                        cursor: 'pointer',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        background: (highlightedMonth === month) ? '#eee' : undefined,
                        color: isMonthRelevant(month) ? undefined : '#ccc',
                      }}
                      onMouseEnter={() => highlightMonth(month)}
                      onMouseLeave={() => highlightMonth(null)}
                    >
                      {MONTH_NAMES[month-1]}
                    </HrefComponent>
                  ))}
                </thead>
                <tbody>
                  <YearStationTableRow
                    year={year}
                    months={months}
                    getMonthValue={getMonthValue}
                    getCoverageStatsFromValue={value => value?.standardCoverageStats}
                    title="Musik"
                    isMonthRelevant={isMonthRelevant}
                    stationId={stationId}
                    categoryColorScaleFn={categoryColorScaleFn}
                    setHighlightedColumn={setHighlightedColumn}
                    highlightedColumn={highlightedColumn}
                    reportsFilter={reportsFilter}
                    tooltipContentFn={tooltipContentFn}
                  />
                  {showJingles ? (
                    <YearStationTableRow
                      year={year}
                      months={months}
                      getMonthValue={getMonthValue}
                      getCoverageStatsFromValue={value => value?.jingleCoverageStats}
                      title="Jingles"
                      isMonthRelevant={isMonthRelevant}
                      stationId={stationId}
                      categoryColorScaleFn={categoryColorScaleFn}
                      setHighlightedColumn={setHighlightedColumn}
                      highlightedColumn={highlightedColumn}
                      reportsFilter={reportsFilter}
                      tooltipContentFn={tooltipContentFn}
                    />
                  ) : null}
                </tbody>
              </table>
            </Box>
          )}
        </AccordionSummary>
        {allowDetails && (
          <AccordionDetails sx={{ml: 1}}>
            <Typography variant="h6">
              Gesamtsendedauer {name || gemagvl_sender_prg_id}<br/>
              <small>
                für
                {' '}
                <NaturalHourGranularityDateTimeRange
                  startDateTime={stats?.coverage?.interval_start}
                  endDateTime={stats?.coverage?.interval_end}
                />
              </small>
            </Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <CircularProgressWithLabel
                    textColor="inherit"
                    variant="determinate"
                    value={stats?.coverage?.standard_musik_dauer_percentage}
                    showZero
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Musik"
                  secondary={(
                    <SimpleDuration value={stats?.coverage?.standard_musik_dauer}/>
                  )}
                />
              </ListItem>
              {showJingles ? (
                <ListItem>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      textColor="inherit"
                      variant="determinate"
                      value={stats?.coverage?.jingle_musik_dauer_percentage}
                      showZero
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Jingles"
                    secondary={(
                      <SimpleDuration value={stats?.coverage?.jingle_musik_dauer}/>
                    )}
                  />
                </ListItem>
              ) : null}
            </List>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
}

function YearListing({year, stations, expanded, handleChange, isLoading, reportsFilter}) {
  const {minDate, maxDate} = useReportsTimeRange({year});
  let datum_von = new Date(year, 0, 1);
  if (minDate > datum_von) {
    datum_von = minDate;
  }
  let datum_bis = new Date(year, 11, 31);
  if (maxDate < datum_bis) {
    datum_bis = maxDate;
  }

  const months = (datum_von && datum_bis) ? [...Array(datum_bis.getMonth() - datum_von.getMonth() + 1)].map((_, idx) => datum_von.getMonth() + idx + 1) : [];

  const [highlightedMonth, setHighlightedMonth] = useState(null);

  return (
    <>
      {stations?.map(({id: stationId, name, gemagvl_sender_prg_id}, i) => (
        <YearStationTable
          key={stationId || i}
          stationId={stationId}
          year={year}
          months={months}
          datum_von={formatDate(datum_von)}
          datum_bis={formatDate(datum_bis)}
          reportsFilter={reportsFilter}
          highlightedMonth={highlightedMonth}
          highlightMonth={setHighlightedMonth}
        />
      ))}
    </>
  );
}

export default function CalendarPage({year, month}) {
  useRequirePermissions({perm_read_reports: true});

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds: stationRenderIds,
    ordering,
    count,
    pageSize,
    meta: {year: activeYear, month: activeMonth},
    filter,
  } = useOrganizationRelatedListing({
    organizationId: selectedOrganizationId,
    listingIdSuffix: 'calendarStations',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/stations/',
    entityType: 'station',
    meta: {year, month},
    defaultOrdering: ['name', 'gemagvl_sender_prg_id'],
    defaultFilter: [
      {id: 'reports', choice: 'prepared_or_published'},
    ],
  });

  const reportsFilter = filter?.filter(({id}) => id === 'reports')?.[0]?.choice;

  const {
    renderIds: allStationIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'filter_stations',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/stations/',
    entityType: 'station',
    defaultPageSize: 100,
    defaultOrdering: ['name', 'gemagvl_sender_prg_id'],
    pageParamName: 'dummyStationsPage',
    pageSizeParamName: 'dummyStationsPageSize',
    orderingParamName: 'dummyStationsOrdering',
    filterParamName: 'dummyStationsFilter',
  });

  const getStation = useSelector(getStationGetter);
  const allStations = allStationIds?.map(getStation);
  const stations = stationRenderIds?.map(getStation);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  const extraOptions = useMemo(() => {
    const extraOptions = [
      {
        category: "Status",
        id: `reports`,
        label: "Lieferungen",
        choice: 'published_to_vwg',
        chipLabel: ({id, choice}) => ({
          'published_to_vwg': "übermittelte Lieferungen",
          'prepared_or_published': `Entwürfe und übermittelte Lieferungen`,
        }[choice]),
        choiceLabel: ({id, choice}) => ({
          'published_to_vwg': "nur übermittelte",
          'prepared_or_published': "inkl. Entwürfe",
        }[choice]),
        choices: ['published_to_vwg', 'prepared_or_published'],
      },
    ];

    allStations?.forEach(({id, name, gemagvl_sender_prg_id}) => {
      const stationName = name || gemagvl_sender_prg_id;
      extraOptions.push({
        category: "Programme",
        id: `station_${id}`,
        label: stationName,
        choice: 'include',
        chipLabel: ({id, choice}) => ({
          'include': `${stationName}`,
          'exclude': `ohne ${stationName}`,
        }[choice]),
        choiceLabel: ({id, choice}) => ({
          'include': "enthalten",
          'exclude': "nicht enthalten",
        }[choice]),
        choices: ['include', 'exclude'],
      });
    });

    return extraOptions;
  }, [allStations]);

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="calendar"
      drawerContentProps={{
        activeDrawerMainMenuItem: (
          <CalendarDrawerMainMenuItems year={year} month={month} expanded keepSearch/>
        ),
      }}
    >
      <PageToolbar
        title={(
          <TimeRangeToolbarTitle year={year} month={month}/>
        )}
        searchField={(
          <StationFilter
            {...filterProps}
            allowSearch
            extraOptions={extraOptions}
            placeholder="Zeitraum durchsuchen..."
          />
        )}
        hidePagination={!!noDataExists || (isLoading || count <= pageSize)}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      <Box>
        {stationRenderIds.length === 0 ? (
          noDataExists ? (
            <Alert variant="filled" severity="info">
              Für diesen Zeitraum wurden keine relevanten Programme gefunden.

              <p>
                <HrefButton
                  href="/dashboard/import/"
                  variant="contained"
                  color="primary"
                >
                  jetzt Sendemeldungen hochladen
                </HrefButton>
              </p>
            </Alert>
          ) : isLoading ? (
            <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
              Programme werden geladen...
            </Alert>
          ) : (
            <Alert variant="filled" severity="info">
              Diese Ansicht enthält keine Einträge.
            </Alert>
          )
        ) : (activeYear && activeMonth) ? (
          <MonthListing
            year={activeYear}
            month={activeMonth}
            stations={stations}
            expanded={expanded}
            handleChange={handleChange}
            isLoading={isLoading}
            reportsFilter={reportsFilter}
          />
        ) : activeYear ? (
          <YearListing
            year={activeYear}
            stations={stations}
            expanded={expanded}
            handleChange={handleChange}
            isLoading={isLoading}
            reportsFilter={reportsFilter}
          />
        ) : null}

        {!noDataExists && (
          <OnlyIfPermissions perm_write_reports>
            <Box mt={8.5} mx={1.5} textAlign="right">
              <Tooltip title="Neue Sendemeldung hochladen">
                <HrefComponent
                  component={Fab}
                  href="/dashboard/import/"
                  style={{
                    position: 'fixed',
                    right: 20,
                    bottom: 20,
                  }}
                  color="primary"
                  aria-label="add"
                >
                  <Add/>
                </HrefComponent>
              </Tooltip>
            </Box>
          </OnlyIfPermissions>
        )}
      </Box>
    </DashboardLayout>
  );
}
