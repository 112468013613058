import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function GVLProduktFilter({...props}) {
  return (
    <FancyFilterField
      options={gvlProduktFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Musikproduktion recherchieren..."
      {...props}
    />
  );
}

const gvlProduktFilterOptions = [
];
