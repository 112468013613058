import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {REPORT_CANCEL_REQUEST_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid, Typography} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_EXPORT} from "src/api/api-schemas";

function ReportCancelRequestForm({id, gemagvlxml_lieferung}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id: gemagvlxml_lieferung});

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Die Lieferung wurde bereits an GEMA/GVL übertragen, daher ist eine Stornierung nicht garantiert. Wenn Sie fortfahren, werden wir versuchen, eine nachträgliche Stornierung Ihrer Lieferung zu erwirken. Dies kann einige Zeit in Anspruch nehmen.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <YupField name="cancellation_request_reason"/>
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export default function ReportCancelRequestFormDialog({data, ...props}) {
  const entityApi = useEntityApi(GEMAGVLXML_EXPORT);

  const {id, gemagvlxml_lieferung} = data;

  const save = async (validatedData) => {
    const {organization, id} = validatedData;

    let url;
    if (id) {
      url = `/api/sendemeldung/organizations/${organization}/gemagvlxml_exports/${id}/request_cancel/`;
    } else {
      url = `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/request_cancel/`;
    }

    await entityApi.post(
      url,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'cancel-request'}
      title={id ? "Lieferung stornieren..." : "Gesamte Lieferung stornieren..."}
      open={!!data}
      data={data}
      submit={save}
      schema={REPORT_CANCEL_REQUEST_SCHEMA}
      saveCaption={"Stornierung anfordern"}
      cancelCaption={"Abbrechen"}
      {...props}
    >
      <ReportCancelRequestForm id={id} gemagvlxml_lieferung={gemagvlxml_lieferung}/>
    </SimpleYupFormDialog>
  );
}
