import React from "react";
import {DashboardLayout} from "src/components/layout";
import { Box, Button, Chip, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLLieferungGetter, getTodoTaskGetter} from "src/features/entity";
import Alert from '@mui/material/Alert';
import SystemOverloadIndicator from "src/components/dashboard/components/overload-indicator";
import GemaGVLXMLRow, {GemaGVLXMLGenerateButton} from "src/components/entities/gemagvlxml/GemaGVLXMLRow";

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MusicWorkTable from "src/components/entities/musicwork/MusicWorkTable";
import {Album, Assignment, Backup, Check, QueueMusic} from "@mui/icons-material";
import { AlertTitle } from '@mui/material';
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_LIEFERUNG} from "src/api/api-schemas";
import Playlist from "src/components/entities/gemagvlxml/components/Playlist";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import CircularProgress from "@mui/material/CircularProgress";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import {useHasPermissions, useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {ReportsDrawerMainMenuItems} from "src/components/dashboard_pages/reports";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function ScrollableTabsButtonAuto({gemagvlxml_lieferung, subPage}) {
  const hasWritePermission = useHasPermissions({perm_write_reports: true});
  const hasSubmitPermission = useHasPermissions({perm_submit_reports: true});

  const classes = useStyles();
  const value = subPage === 'entries' ? 1 : subPage === 'music' ? 2 : 0;

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);

  const {
    ausstrahlungen_count,
    music_works_count,
    organization,
    status,
    isDeleted,
    is_processing: isProcessing,
    todo_tasks: todoTasks,
    stations: stationIds,
    can_publish: canPublish,
    can_edit: canEdit,
    is_pre_approval_report: isPreApprovalReport,
  } = getGEMAGVLXMLLieferung(gemagvlxml_lieferung);
  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const publish = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/publish/`,
    );
  };

  const isImporting = status === -100;

  const hasTodoTasks = todoTasks?.length > 0 && status !== 200 && status !== 307 && status !== 306;
  const getTodoTask = useSelector(getTodoTaskGetter);
  const hasLoadedAllTodoTasks = todoTasks?.map(getTodoTask).filter(({id}) => !id)?.length === 0;
  const hasCriticalTodoTasks = todoTasks?.map(getTodoTask).filter(
    ({message}) => message?.severity === 'error',
  )?.length > 0;

  useEntityObserver({
    type: 'todo_task',
    ids: todoTasks,
  });

  if (status === undefined) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <HrefComponent
              component={Tab}
              href={`/dashboard/reports/${gemagvlxml_lieferung}/`}
              icon={<Assignment/>}
              label={(
                <div>
                  zu erledigen
                  {' '}
                  {hasTodoTasks ? (
                    <Chip label={`${todoTasks?.length}`} color="primary" size="small"/>
                  ) : (status === 0 || status === 500 || (status === 200 && canPublish)) ? (
                    <Chip label="1" size="small"/>
                  ) : (
                    <Chip label="0" size="small"/>
                  )}
                </div>
              )}
              {...a11yProps(1)}
            />
            {!isImporting || subPage === 'entries' || subPage === 'music' ? (
              <HrefComponent
                component={Tab}
                href={`/dashboard/reports/${gemagvlxml_lieferung}/entries/`}
                icon={<QueueMusic/>}
                label={(
                  <div>
                    Ausstrahlungen
                    {' '}
                    <Chip label={ausstrahlungen_count} size="small"/>
                  </div>
                )}
                {...a11yProps(1)}
              />
            ) : null}
            {!isImporting || subPage === 'music' ? (
              <HrefComponent
                component={Tab}
                href={`/dashboard/reports/${gemagvlxml_lieferung}/music/`}
                icon={<Album/>}
                label={(
                  <div>
                    Musikproduktionen
                    {' '}
                    <Chip label={music_works_count} size="small"/>
                  </div>
                )}
                {...a11yProps(2)}
              />
            ) : null}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {hasTodoTasks && canEdit ? (
            <>
              {(hasCriticalTodoTasks || !hasLoadedAllTodoTasks) ? (
                null
              ) : (status === 0) ? (
                <Alert severity="info" icon={<Check/>}>
                  <AlertTitle>Ihre XML-Meldungen sind vorbereitet.</AlertTitle>
                  <Typography gutterBottom>Wir haben Ihre XML-Meldungen bereits vorbereitet, sodass Sie diese nun an die Verwertungsgesellschaften übermitteln können.</Typography>
                  <Typography>Es liegen jedoch Hinweise vor, die Sie vor der Übermittlung überprüfen sollten.</Typography>
                </Alert>
              ) : null}
              <TodoTasks
                ids={todoTasks}
                stationIds={stationIds}
                gemagvlxmlLieferungId={gemagvlxml_lieferung}
                showCategories
              />
            </>
          ) : (status === 0 || status === 500) ? (
            <Alert severity="info" icon={<Assignment/>}>
              <AlertTitle>Sichten &amp; Generieren</AlertTitle>
              <Typography>Sie können Ihre Sendemeldungen nun sichten und die XML-Meldungen generieren.</Typography>
              <Box style={{float: 'left'}} mt={1}>
                <GemaGVLXMLGenerateButton id={gemagvlxml_lieferung} variant="contained"/>
              </Box>
            </Alert>
          ) : (status === 200 && canPublish) ? (
            <Alert severity="info" icon={<Assignment/>}>
              <AlertTitle>Übermittlung an GEMA und GVL</AlertTitle>
              {isPreApprovalReport ? (
                <Typography>
                  Sie können Ihre Sendemeldungen nun zur Prüfung an GEMA/GVL übermitteln.
                </Typography>
              ) : (
                <Typography>
                  Sie können Ihre Sendemeldungen nun an die Verwertungsgesellschaften übermitteln.
                </Typography>
              )}
              <OnlyIfPermissions perm_publish_reports>
                <Box mt={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => confirm("Lieferung jetzt an GEMA und GVL übermitteln?") && publish({
                      organization,
                      gemagvlxml_lieferung,
                    })}
                    startIcon={<Backup/>}
                  >
                    jetzt übermitteln
                  </Button>
                </Box>
              </OnlyIfPermissions>
            </Alert>
          ) : isImporting ? (
            <Alert severity="info">
              <AlertTitle>Warte auf Import</AlertTitle>
              <Typography>Aufgaben werden angezeigt, sobald der Import abgeschlossen ist.</Typography>
            </Alert>
          ) : isProcessing ? (
            <Alert severity="info">
              <AlertTitle>Warte auf Verarbeitung</AlertTitle>
              <Typography>Aufgaben werden angezeigt, sobald die Verarbeitung abgeschlossen ist.</Typography>
            </Alert>
          ) : !canEdit && status === 306 ? (
            <Alert severity="info">
              <AlertTitle>Keine Bearbeitung möglich</AlertTitle>
              <Typography>Diese Lieferung kann derzeit nicht bearbeitet werden.</Typography>
            </Alert>
          ) : (
            <Alert severity="info">
              <AlertTitle>Keine offenen Aufgaben</AlertTitle>
              <Typography>Für diese Sendemeldung gibt es keine offenen Aufgaben.</Typography>
            </Alert>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} style={{marginTop: -20}}>
          <Playlist
            gemagvlxml_lieferung={gemagvlxml_lieferung}
            listingId={`playlist[${gemagvlxml_lieferung}]`}
            // totalCount={ausstrahlungen_count}
            defaultOrdering='datum_von,datum_uhrzeit_von,lineno'
            loadingContent={(
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                Einträge werden geladen...
              </Alert>
            )}
          >
            <Alert variant="filled" severity="info">
              Diese Ansicht enthält keine Einträge.
            </Alert>
          </Playlist>
        </TabPanel>
        <TabPanel value={value} index={2} style={{marginTop: -20}}>
          <MusicWorkTable
            organizationId={organization}
            listingId={gemagvlxml_lieferung && `org_music_works[${gemagvlxml_lieferung}]`}
            meta={{gemagvlxml_lieferung}}
            // totalCount={music_works_count}
            loadingContent={(
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                Einträge werden geladen...
              </Alert>
            )}
            gemagvlxmlLieferungId={gemagvlxml_lieferung}
            forcePagination
          >
            <Alert variant="filled" severity="info">
              Diese Ansicht enthält keine Einträge.
            </Alert>
          </MusicWorkTable>
        </TabPanel>
      </Paper>
    </div>
  );
}

export default function ReportPlaylistPage({id, '*': subPage, ...props}) {
  useRequirePermissions({perm_read_reports: true});

  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);

  const {
    id: gemaGvlXmlLieferungId,
  } = getGEMAGVLXMLLieferung(id);

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="reports"
      drawerContentProps={{
        activeDrawerMainMenuItem: (
          <ReportsDrawerMainMenuItems/>
        ),
      }}
    >
      <Box my={2}>
        <Typography variant="h6">
          Sendemeldung
        </Typography>
      </Box>

      <SystemOverloadIndicator/>

      <GemaGVLXMLRow
        id={id}
        defaultExpanded={true}
        expansible
        isDetailPage
      />

      <br/>

      <ScrollableTabsButtonAuto
        gemagvlxml_lieferung={gemaGvlXmlLieferungId}
        subPage={subPage}
      />
    </DashboardLayout>
  );
}
