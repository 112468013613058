import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function NotificationFilter({...props}) {
  return (
    <FancyFilterField
      options={notificationFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Benachrichtigungen suchen..."
      {...props}
    />
  );
}

const notificationFilterOptions = [
  {
    category: null,
    id: 'unread',
    label: 'Status',
    choice: 'true',
    chipLabel: ({
      id,
      choice
    }) => ({
      'true': "ungelesen",
      'false': "gelesen",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'true': "ungelesen",
      'false': "gelesen",
    }[choice]),
    choices: ['true', 'false'],
  },
];
