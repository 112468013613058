import React, {useState} from "react";
import _ from "lodash";
import {Box} from '@mui/material';
import {useFormContext} from "react-hook-form";
import axios from "axios";
import {useLazyEffect} from "src/packages/lazy-effect";
import {useDispatch} from "react-redux";
import {normalize} from "normalizr";
import {GEMA_WORK} from "src/api/api-schemas";
import {setEntities} from "src/features/entity";
import GEMAWerkSummary from "src/components/entities/refdata/GEMAWerkSummary";
import GVLProduktSummary2 from "src/components/entities/refdata/GVLProduktSummary2";
import {FormTextFieldSuggestions} from "src/packages/react-hook-form-mui-form-fields";
import {useSelectedOrganization} from "src/features/entity/entity-hooks";

const PersonSuggestionsField = ({
  name: fieldName,
  onFocus,
  ...props
}) => {
  const {
    watch,
    setValue,
  } = useFormContext();

  const id = watch('org_music_work');
  const {id: organizationId} = useSelectedOrganization();

  const musik_person_rolle_knz = watch('musik_person_rolle_knz');

  // const name = watch('name');
  // const vorname = watch('vorname');

  const [options, setOptions] = useState(null);

  const [focussedOnce, setFocussedOnce] = useState(false);

  const dispatch = useDispatch();

  useLazyEffect(async () => {
    if (!musik_person_rolle_knz || !focussedOnce) {
      return;
    }
    try {
      let qs = `musik_person_rolle_knz=${musik_person_rolle_knz}`;

      // if (name === 'vorname') {
      //   qs += name ? `name=${encodeURIComponent(name)}` : `q=${encodeURIComponent(vorname)}`;
      // } else {
      //   qs += vorname ? `vorname=${encodeURIComponent(vorname)}` : `q=${encodeURIComponent(name)}`;
      // }

      setOptions(null);
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/org_music_works/${id}/suggested_people/?${qs}`);
      setOptions(result.data?.results || []);

      const gemaWorks = result.data?.gema_works;
      if (gemaWorks) {
        const normalizedEntities = normalize(gemaWorks, [GEMA_WORK]);
        dispatch(setEntities(normalizedEntities));
      }
    } catch (e) {
      console.error(e);
      setOptions([]);
    }
  }, [id, organizationId, musik_person_rolle_knz, focussedOnce], 300);

  return (
    <FormTextFieldSuggestions
      name={fieldName}
      suggestions={options}
      autocompleteProcessOnChangeData={(data) => {
        if (!data) {
          return null;
        }

        if (fieldName === 'name') {
          setValue('vorname', data['vorname']);
        } else if (fieldName === 'vorname') {
          setValue('name', data['name']);
        }

        return data[fieldName];
      }}
      autocompleteProps={{
        isOptionEqualToValue: (option, value) => option[fieldName] === value,
        getOptionLabel: (option) => (_.isString(option) || _.isInteger(option)) ? `${option}` : `${option[fieldName]}`,
        renderOption: (props, option, {selected}) => (
          <li {...props}>
            <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column">
              <Box>
                {option?.vorname} {option?.name}
              </Box>
              {option?.source?.gema_work_id ? (
                <Box pl={2} py={1} display="flex" justifyContent="center" alignItems="start">
                  <Box pr={1} pt={1} color="gray">
                    Quelle:
                  </Box>
                  <GEMAWerkSummary id={option?.source?.gema_work_id}/>
                </Box>
              ) : null}
              {option?.source?.gvl_produkt_id ? (
                <Box pl={2} py={1} display="flex" justifyContent="center" alignItems="start">
                  <Box pr={1} pt={1} color="gray">
                    Quelle:
                  </Box>
                  <GVLProduktSummary2 id={option?.source?.gvl_produkt_id}/>
                </Box>
              ) : null}
            </Box>
          </li>
        ),
      }}
      onFocus={(e) => {
        setFocussedOnce(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      {...props}
    />
  );
};

export default PersonSuggestionsField;
