import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function StationFilter({extraOptions, ...props}) {
  const options = useMemo(() => {
    if (!extraOptions || extraOptions.length === 0) {
      return [...stationFilterOptions];
    }

    // Merge options, giving precedence to extraOptions when ids overlap.
    const ids = new Set(extraOptions.map(({id}) => id));
    return [
      ...stationFilterOptions.filter(({id}) => !ids.has(id)),
      ...extraOptions,
    ];
  }, [extraOptions, stationFilterOptions]);

  return (
    <FancyFilterField
      options={options}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Programme suchen..."
      {...props}
    />
  );
}

const stationFilterOptions = [
  {
    category: "Status",
    id: 'status',
    label: 'Programm',
    choice: 'active',
    chipLabel: ({
      id,
      choice
    }) => ({
      'active': "aktive Programme",
      'draft': "neue Programme",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'active': "aktiv",
      'draft': "neu",
    }[choice]),
    choices: ['active', 'draft'],
  },
];
