import makeStyles from "@mui/styles/makeStyles";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import Alert from "@mui/material/Alert";
import {AlertTitle, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import LabelFilter from "src/components/entities/refdata/LabelFilter";
import LabelRow from "src/components/entities/refdata/LabelRow";

export default function LabelTable({}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'labels',
    endpoint: '/api/sendemeldung/refdata/labels/',
    entityType: 'label',
  });

  return (
    <>
      <PageToolbar
        //title="Labels"
        searchField={(
          <LabelFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="contained" severity="info">
            <AlertTitle>Keine Labels gefunden</AlertTitle>
            Es wurden noch keine Labels hinterlegt.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Labels werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Labels gefunden</AlertTitle>
            Es wurden keine Labels gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="labels table">
            <TableHead>
              <TableRow>
                <TableCell>Labelcode</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Kurzname</TableCell>
                <TableCell>PLM</TableCell>
                <TableCell>Hersteller</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <LabelRow
                  key={id || i}
                  id={id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
