import React from "react";
import {DashboardLayout} from "src/components/layout";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import DocumentList from "src/components/entities/document/DocumentList";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";

export default function DocumentsPage({id='inbox'}) {
  useRequirePermissions({perm_read_documents: true});

  const organizationId = useSelector(getSelectedOrganizationId);

  return (
    <DashboardLayout
      titlePrefix="Dokumente und Nachrichten"
      selectedPage={`documents/${id}`}
    >
      <DocumentList
        id={id}
        organizationId={organizationId}
        skipDrafts={id !== 'drafts'}
        skipSent={id !== 'sent'}
      />
    </DashboardLayout>
  );
}
