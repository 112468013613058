import {Chip} from "@mui/material";
import React from "react";

export default function GVLProductTitle({
  gvlProductTitle
}) {
  const {
    name,
    zusatz,
    weiterer
  } = gvlProductTitle || {};

  return (
    <>
      {name}
      {zusatz ? (
        <>
          {' '}
          <Chip label={zusatz} size="small" component="span"/>
        </>
      ) : null}
      {weiterer ? (
        <>
          {' '}
          <Chip label="Alternativtitel" size="small" component="span"/>
        </>
      ) : null}
    </>
  );
}
