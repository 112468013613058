import React from "react";
import {DashboardLayout} from "src/components/layout";
import NotificationList from "src/components/entities/notification/NotificationList";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";

export default function NotificationsPage() {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  return (
    <DashboardLayout
      titlePrefix="Benachrichtigungen"
      selectedPage="notifications"
    >
      <NotificationList
        organizationId={selectedOrganizationId}
      />
    </DashboardLayout>
  );
}
