import {Chip} from "@mui/material";
import React from "react";

export const GEMA_TITLE_TYPES = {
  OT: "Originaltitel",
  AT: "Alternativtitel",
  TE: "erste Textzeile",
  FT: "formaler Titel",
  IT: "inkorrekter Titel",
  TT: "übersetzter Titel",
  PT: "Teiltitel",
  RT: "beschränkter Titel",
  ET: "spezieller Titel",
  OL: "Originaltitel mit Sonderzeichen",
  AL: "Alternativtitel mit Sonderzeichen",
  DT: "Längentitel",
  LT: "Bibliotheks-/Produktionstitel",
  NT: "Non-Match Title",
  OV: "Original-Werktitel für Version",
};

export function GEMATitleType({type}) {
  let description = GEMA_TITLE_TYPES[type];
  if (description === undefined) {
    return type;
  }

  return (
    description
  );
}

export default function GEMAWerkTitle({
  gemaWorkTitle
}) {
  const {
    type,
    value
  } = gemaWorkTitle || {};

  if (type !== 'OT' && type !== 'AT') {
    console.warn(`Unsupported GEMAWorkTitle type: ${type}`);
  }

  return (
    <>
      {value}
      {type === 'OT' ? (
        null
      ) : type === 'AT' ? (
        <>
          {' '}
          <Chip label="Alternativtitel" size="small" component="span"/>
        </>
      ) : (
        <>
          {' '}
          <Chip label={<GEMATitleType type={type}/>} size="small" component="span"/>
        </>
      )}
    </>
  );
}
