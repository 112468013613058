import {useSelector} from "react-redux";
import {getGVLProductGetter} from "src/features/entity";
import {Grid, Typography} from "@mui/material";
import Duration from "src/packages/Duration";
import GVLProductTitle from "src/components/entities/musicwork/components/GVLProductTitle";
import GVLProductPerson from "src/components/entities/musicwork/components/GVLProductPerson";
import React from "react";
import {useEntityObserver} from "src/features/entity/entity-hooks";

export default function GVLProduktSummary({id}) {
  useEntityObserver({
    type: 'gvl_product',
    id
  });

  const {
    titel,
    personen,
    interpret,
    identifiers,
    genre,
    dauer,
  } = useSelector(getGVLProductGetter)(id);

  return (
    <>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">GVL-Produkt-ID</Typography>
          <Typography>{id}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Genre</Typography>
          <Typography>
            {genre}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Dauer</Typography>
          <Typography>
            <Duration value={dauer}/>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Titel</Typography>
          {titel?.map((el, i) => (
            <Typography key={i}>
              <GVLProductTitle gvlProductTitle={el}/>
            </Typography>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Interpret</Typography>
          <Typography>
            {interpret}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
