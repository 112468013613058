import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {COLLECTIVE_REPORT_COVERED_MONTHS} from "src/features/dashboard/dashboard-validation";
import {Box, DialogContent, Grid} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_LIEFERUNG} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLLieferungGetter} from "src/features/entity";
import {parseDate} from "src/packages/date-utils";
import {addDays, addMonths} from "date-fns";
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";

function CollectiveReportCoveredMonthsForm({id}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {datum_von, collective_report_covered_months} = getGEMAGVLXMLLieferung(id);

  let options = {
    null: "nicht festgelegt",
    1: "1 Monat",
    2: "2 Monate",
    3: "3 Monate",
    4: "4 Monate",
    5: "5 Monate",
    6: "6 Monate",
    7: "7 Monate",
    8: "8 Monate",
    9: "9 Monate",
    10: "10 Monate",
    11: "11 Monate",
    12: "12 Monate",
  };

  let startDate;

  try {
    startDate = parseDate(datum_von);
  } catch(e) {
    console.error(e);
  }

  if (startDate) {
    Object.keys(options)
      .forEach(months => {
        months = parseInt(months) || 0;

        // Keep null option.
        if (!months) {
          return;
        }

        // Compute end date.
        const endDate = addDays(addMonths(startDate, months), -1);

        // Do not suggest time ranges that cross a year boundary.
        if (endDate.getFullYear() !== startDate.getFullYear() && months !== collective_report_covered_months) {
          delete options[months];
          return;
        }

        // Format option.
        options[months] = (
          <Box>
            {options[months]}<br/>
            <small><NaturalDateRange startDate={startDate} endDate={endDate}/></small>
          </Box>
        );
      });
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField
            name="collective_report_covered_months"
            items={options}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export default function CollectiveReportCoveredMonthsFormDialog({data, ...props}) {
  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const {id} = data;

  const save = async (validatedData) => {
    const {organization, id} = validatedData;

    const url = `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${id}/`;

    await entityApi.patch(
      url,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'set-covered-months'}
      title={"Meldezeitraum festlegen"}
      open={!!data}
      data={data}
      submit={save}
      schema={COLLECTIVE_REPORT_COVERED_MONTHS}
      saveCaption={"Speichern"}
      cancelCaption={"Abbrechen"}
      {...props}
    >
      <CollectiveReportCoveredMonthsForm id={id}/>
    </SimpleYupFormDialog>
  );
}
