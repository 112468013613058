import Dropzone from "./dropzone-view";
import {connect} from "react-redux";
import {uploadFile} from "src/features/dashboard";
import {createUploadsFromFiles} from "src/features/dashboard/file-events";

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFiles: files => createUploadsFromFiles(
      {files},
      (...args) => dispatch(uploadFile(...args)),
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dropzone);
