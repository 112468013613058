import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Link} from "gatsby-theme-material-ui";
import DocumentForm from "src/components/entities/document/DocumentForm";
import {getOrganizationGetter} from "src/features/entity";
import {Message, OndemandVideo} from "@mui/icons-material";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import {Dialog} from "src/components/layout/modal/Dialog";
import {SimpleContactButton} from "src/components/entities/document/SimpleContactButton";

const HELP_ITEMS = [
  {
    title: "Wie übermittle ich eine Beispielmeldung?",
    videoUrl: "/videos/testphase.mp4",
    isApproved: false,
    description: (
      <>
        Bevor Sie Produktivmeldungen an die Verwertungsgesellschaften übermitteln können, soll zunächst eine
        Beispielmeldung bereitgestellt werden. Anhand dieser Meldung prüfen die Verwertungsgesellschaften die
        reibungslose Verarbeitbarkeit. Wie Sie eine solche Meldung übermitteln können, haben wir im Video für Sie
        zusammengefasst.
      </>
    ),
  },
  {
    title: "Wie funktioniert die Übermittlung von Sendemeldungen?",
    videoUrl: "/videos/sendemeldungen.mp4",
    description: (
      <>
        Um Sendemeldungen über Sendemeldung.de an die Verwertungsgesellschaften zu übermitteln, sind typischerweise
        nur wenige Schritte erforderlich. Im Video haben wir den Ablauf für Sie zusammengefasst.
      </>
    ),
  },
  {
    title: "Wie kann ich Referenzdaten verwenden?",
    videoUrl: "/videos/referenzdaten.mp4",
    description: (
      <>
        Zur komfortablen Vervollständigung Ihrer Musik-Metadaten stellt Ihnen Sendemeldung.de Referenzdaten zur
        Verfügung.
        Welche Möglichkeiten Sie haben, diese Referenzdaten zu verwenden, wird im Video erörtert.
      </>
    ),
  },
  {
    title: "Kann ich beliebige Dokumente an die Verwertungsgesellschaften übermitteln?",
    videoUrl: "/videos/dokumentversand.mp4",
    description: (
      <>
        Ergänzend zur Handhabung von Sendemeldungen stellt Sendemeldung.de Werkzeuge bereit, um beliebige Nachrichten
        und Dokumente an die Verwertungsgesellschaften zu übermitteln und von ihnen zu empfangen.
        Wie der Nachrichtenaustausch funktioniert, wird im Video erläutert.
      </>
    ),
  },
  {
    title: "Wie funktioniert die Benutzer- und Zugriffsrechteverwaltung?",
    videoUrl: "/videos/zugriffsrechte.mp4",
    description: (
      <>
        Selbstverständlich können Sie auch weiteren Personen Ihres Unternehmens Zugriff auf Ihr Sendemeldung.de-Konto
        erteilen und festlegen, welche Zugriffsrechte diese Personen ausüben dürfen.
        Wie die Zugriffsrechteverwaltung in Sendemeldung.de funktioniert, wird im Video erklärt.
      </>
    ),
  },
  {
    title: "Ist es möglich, eine Musikdatenbank gemeinsam mit anderen Sendeunternehmen zu verwenden?",
    videoUrl: "/videos/datenbankaustausch.mp4",
    description: (
      <>
        Standardmäßig verfügt jedes Sendeunternehmen über eine eigene Musikdatenbank.
        Diese enthält sämtliche Musikproduktionen, die in GEMAGVL4-Sendemeldungen des jeweiligen Unternehmens erkannt
        wurden, sowie ggf. innerhalb der Plattform durchgeführte, manuelle Ergänzungen und Korrekturen.
        In Fällen, in denen mehrere Sendeunternehmen aufs gleiche Repertoire zugreifen, kann es sinnvoll sein,
        eine gemeinsame Musikdatenbank innerhalb Sendemeldung.de zu pflegen: So müssen Ergänzungen innerhalb der
        Plattform nur jeweils einmal durchgeführt werden und stehen dann auch den anderen Unternehmen, die die gleichen
        Metadaten in ihren GEMAGVL4-Meldungen verwenden, zur Verfügung. Wie Sie dies einrichten können, erfahren Sie
        im Video.
      </>
    ),
  },
];

function HelpItem({
  title,
  description,
  videoUrl,
  notPublished,
}) {
  const [showVideo, setShowVideo] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let videoButton = videoUrl ? (
    <Button
      startIcon={<OndemandVideo/>}
      onClick={() => setShowVideo(true)}
      color="primary"
      variant="outlined"
      disabled={!!notPublished}
    >
      Video anzeigen
    </Button>
  ) : null;

  if (videoButton && notPublished) {
    videoButton = (
      <Tooltip title="Dieses Video wird in wenigen Tagen veröffentlicht.">
        <span>
          {videoButton}
        </span>
      </Tooltip>
    );
  }

  return (
    <Card sx={{mb: 2}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>
          {description}
        </Typography>
        {videoButton ? (
          <Typography sx={{pt: 1}}>
            {videoButton}
          </Typography>
        ) : null}
      </CardContent>

      {showVideo ? (
        <Dialog
          aria-labelledby="help-video"
          open={showVideo}
          onClose={() => setShowVideo(false)}
          fullScreen={fullScreen}
          maxWidth="lg"
        >
          <DialogTitle id="help-video">{title}</DialogTitle>
          <DialogContent>
            <video width="100%" controls autoPlay>
              <source src={videoUrl} type="video/mp4"/>
              Ihr Browser unterstützt keine eingebetteten Videos.
            </video>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowVideo(false)}>Schließen</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Card>
  );
}

export default function HelpPage() {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    is_gemagvl_approved: isApproved
  } = useSelector(getOrganizationGetter)(selectedOrganizationId);

  const hasMessagePermission = useHasPermissions({perm_write_documents: true});

  return (
    <DashboardLayout
      titlePrefix="Hilfe"
      selectedPage="help"
    >
      <Box my={2}>
        <Typography variant="h6">
          Hilfe
        </Typography>
      </Box>

      <Typography gutterBottom sx={{mb: 2}}>
        Nachfolgend finden Sie Erklärungen zu typischen Fragestellungen im Umgang mit Sendemeldung.de.
      </Typography>

      {HELP_ITEMS
        .filter(helpItemProps => (helpItemProps.isApproved === undefined || helpItemProps.isApproved === isApproved))
        .map((helpItemProps, i) => (
          <HelpItem
            key={i}
            {...helpItemProps}
          />
        ))}

      <Divider sx={{
        mt: 4,
        mb: 4
      }}/>

      <Card sx={{mt: 2}}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Haben Sie eine andere Frage?
          </Typography>
          {hasMessagePermission ? (
            <>
              <Typography gutterBottom>
                Kein Problem! Schreiben Sie uns einfach eine Nachricht über den Kontaktbereich oder senden Sie uns eine
                E-Mail an <Link href="mailto:kontakt@sendemeldung.de" underline="hover">kontakt@sendemeldung.de</Link>.
              </Typography>
              <Typography pt={1}>
                <SimpleContactButton/>
              </Typography>
            </>
          ) : (
            <Typography>
              Kein Problem! Schreiben Sie uns einfach eine E-Mail an <Link href="mailto:kontakt@sendemeldung.de"
                                                                           underline="hover">kontakt@sendemeldung.de</Link>.
            </Typography>
          )}
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}
